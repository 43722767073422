import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { convertJST } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  datas;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ id: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    id: number,
    pref_code: string,
    pref_name: string,
    bank_code: string,
    ja_name: string,
    user_name: string,
    user_dept_name: string,
    user_post: string,
    telephone_number: string,
    email: string,
    account_lock: boolean
  ) => {
    if (id === props.checkedInfo.id) {
      props.setCheckedInfo({
        id: -1,
      });
    } else {
      props.setCheckedInfo({
        id,
        pref_code,
        pref_name,
        bank_code,
        ja_name,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        account_lock,
      });
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center' style={{ width: '10%' }}>
                選択
              </StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>
                都道府県
              </StyledTableCell>
              <StyledTableCell style={{ width: '15%' }}>
                金融機関コード
              </StyledTableCell>
              <StyledTableCell style={{ width: '25%' }}>
                JA&thinsp;名
              </StyledTableCell>
              <StyledTableCell style={{ width: '20%' }}>
                JA&thinsp;管理者名
              </StyledTableCell>
              <StyledTableCell>更新日時</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.datas?.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.id}>
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={props.checkedInfo.id === elm.id}
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, paddingLeft: 28 }}
                    onChange={() => {
                      handleCheckbox(
                        elm.id,
                        elm.pref_code,
                        elm.pref_name,
                        elm.bank_code,
                        elm.ja_name,
                        elm.user_name,
                        elm.user_dept_name,
                        elm.user_post,
                        elm.telephone_number,
                        elm.email,
                        elm.account_lock
                      );
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>{elm.pref_name}</StyledTableCell>
                <StyledTableCell>{elm.bank_code}</StyledTableCell>
                <StyledTableCell>{elm.ja_name}</StyledTableCell>
                <StyledTableCell>{elm.user_name}</StyledTableCell>
                <StyledTableCell>{convertJST(elm.updated_at)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.datas.length > PAR_PAGE && (
        <JICSPagination
          length={props.datas.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
