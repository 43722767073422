import React, { ChangeEvent, FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

type Options = {
  key: string | number;
  val: string;
};

type Props = {
  id: string;
  label: string;
  labelId: string;
  className: string;
  value: number | string;
  errorMsg: string;
  isDisabled?: boolean;
  options: Array<Options>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const SelectBoxField2: FC<Props> = (props) => {
  const {
    id,
    label,
    labelId,
    className,
    value,
    errorMsg,
    isDisabled,
    options,
    onChange,
  } = props;

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        
        id={id}
        label={label}
        labelId={labelId}
        value={value}
        error={errorMsg.trim().length > 0}
        disabled={isDisabled}
        onChange={onChange}
        style={{
          textAlign: 'left',
        }}
      >
        {options.map((opt) => (
          <MenuItem key={opt.key} value={opt.key}>{opt.val}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectBoxField2;
