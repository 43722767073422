import React from 'react';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { ChecklistTempContext } from 'views/pages/ChecklistTemp';
import { ChecklistTempForm } from 'views/components/forms/ChecklistTempForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  checkedInfo;
  setCheckedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    email,
    setChecklist,
    setDefaultChecklist,
    setDefaultComment,
    setDisabledChecklist,
    setDisabledComment,
  } = React.useContext(ChecklistTempContext);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);

    setDefaultChecklist(props.checkedInfo.checklist_name);
    setDefaultComment(props.checkedInfo.comment);

    setDisabledChecklist(true);
    setDisabledComment(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setDefaultChecklist('');
    setDefaultComment('');
  };

  const handleDelete = () => {
    axios
      .post('/api/checklist-temp/delete', {
        pref_code,
        bank_code,
        email,
        checklist_code: props.checkedInfo.checklist_code,
      })
      .then((res) => {
        setChecklist(res.data);
        props.setCheckedInfo({ checklist_code: -1 });
        handleClose();
      });
  };

  return (
    <Box component='span'>
      <DialogButtonDelete
        buttonLabel='チェックリスト削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='チェックリスト削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 16 }}
        >
          <ChecklistTempForm />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='一時保存したチェックリストを削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
