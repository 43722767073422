import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Box, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';

import { SYSTEM_NAME, SYSTEM_SUB_NAME, TOKEN_KEY } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import Footer from 'views/components/Footer';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'rgb(115, 115, 115)',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#7BD957',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(3),
    backgroundColor: '#7BD957',
    width: '60px',
    height: '60px',
  },
  form: {
    width: '35%',
    marginTop: theme.spacing(1),
    margin: '0 auto',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    padding: theme.spacing(2, 0, 2),
    backgroundColor: '#7BD957',
    color: 'white',
    fontSize: '18px',
    '&:hover': {
      background: 'rgba(123, 217, 87, 0.9)',
    },
  },
  errMsg: {
    whiteSpace: 'pre-wrap',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();

  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();

  const handleLoginId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginId(() => e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(() => e.target.value);
  };
  function handleSubmit(e) {
    e.preventDefault();
    if (loginError) {
      // return;
    }

    if (loginId == '') {
      setErrorMessage('ログイン ID を入力してください。');
      return;
    } else if (password == '') {
      setErrorMessage('パスワード を入力してください。');
      return;
    }

    axios
      .post(
        '/login',
        {
          loginId: loginId,
          password: password,
        },
        {
          headers: {
            'content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        const resAuth = res.data.isAuthorized;

        if (resAuth === 1 || resAuth === 2) {
          axios.post('/api/reset_login_err_count', { email: loginId });
        }

        if (resAuth === 1) {
          const userData = JSON.stringify(res.data.user);
          sessionStorage.setItem(TOKEN_KEY, res.data.uuid);
          sessionStorage.setItem('user', encodeURIComponent(userData));
          window.location.href = '/home';
        } else if (resAuth === 2) {
          // 兼務ユーザー処理...pageかませる
          // where...email
          console.log(res.data.user);
          const userData = JSON.stringify(res.data.user);
          sessionStorage.setItem(TOKEN_KEY, res.data.uuid);
          sessionStorage.setItem('user', encodeURIComponent(userData));
          window.location.href = '/plural-select';
        } else {
          //認証失敗は401が帰ってきてerrorなのでこっちに来るケースは？
          // window.location.href = '/';
          axios.post('/api/err_count', { email: loginId });
          setLoginId('');
          setPassword('');
          setErrorMessage(res.data.errorMessage);
        }
      })
      .catch(() => {
        // window.location.href = '/';
        console.log('catch');
        // axios.post('/api/err_count', { email: loginId });
        setLoginId('');
        setPassword('');
        setErrorMessage('ログイン ID または パスワード が正しくありません。');
      });
  }

  const onClickLogin = () => {
    setLoginError(false);
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AssignmentTurnedInIcon />
            </Avatar>

            <Typography component='h1' variant='h4'>
              {SYSTEM_NAME}
            </Typography>

            <Typography
              component='h4'
              variant='h5'
              style={{ marginTop: '15px', marginBottom: '10px' }}
            >
              {SYSTEM_SUB_NAME}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit.bind(this)}
            >
              <CssTextField
                id='loginId'
                type='input'
                variant='outlined'
                margin='normal'
                fullWidth
                label='&thinsp;ログイン ID（メールアドレス）&ensp;'
                inputProps={{ style: { fontSize: 18 } }}
                InputLabelProps={{ style: { fontSize: 18 } }}
                autoFocus
                onChange={handleLoginId}
                value={loginId}
              />
              <CssTextField
                id='password'
                type='password'
                variant='outlined'
                margin='normal'
                fullWidth
                label='&thinsp;パスワード&ensp;'
                inputProps={{ style: { fontSize: 18 } }}
                InputLabelProps={{ style: { fontSize: 18 } }}
                onChange={handlePassword}
                value={password}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submit}
                onClick={onClickLogin}
              >
                ログイン
              </Button>
              {location.search == '?timeout' && (
                <Typography color='error'>
                  タイムアウトしました。再ログインしてください。
                </Typography>
              )}
            </form>
            <Typography
              color='error'
              className={classes.errMsg}
              style={{ textAlign: 'center' }}
            >
              {errorMessage.indexOf('ロック') > 0
                ? errorMessage.split('<br />').map((item, index) => {
                    console.log(item);
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })
                : errorMessage}
            </Typography>
            <Footer />
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
