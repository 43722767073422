import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ListItemText } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        background: 'none',
      },
    },
  })
);

const Manual: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItemText>
      <Button
        className={classes.menu}
        onClick={() => history.replace('/manual-download')}
      >
        マニュアル・資料
      </Button>
    </ListItemText>
  );
};

export default Manual;
