import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import SelectBoxField from 'views/molecules/SelectBoxField';
import InputTextField from 'views/molecules/InputTextField';
import InputTextArea from 'views/molecules/InputTextArea';
import InputUploadField from 'views/molecules/InputUploadField';
// import DownloadField from 'views/molecules/DownloadField';
import { NewsInformationMasterContext } from 'views/pages/NewsInformationMaster';

type items = {
  categoryCode: { defaultValue: string; errMsg: string; isDisabled: boolean };
  title: { defaultValue: string; errMsg: string; isDisabled: boolean };
  naiyou: { defaultValue: string; errMsg: string; isDisabled: boolean };
  tmp_name1: { defaultValue: string; errMsg: string; isDisabled: boolean };
  tmp_name2: { defaultValue: string; errMsg: string; isDisabled: boolean };
  tmp_name3: { defaultValue: string; errMsg: string; isDisabled: boolean };
};

type options = {
  category: { key: string; val: string }[];
};

type Props = {
  classes: ClassNameMap;
  items: items;
  options: options;
};

export const InformationMasterForm: React.FC<Props> = (props) => {
  const { classes, items, options } = props;

  const { defaultValues } = React.useContext(NewsInformationMasterContext);

  return (
    <>
      <Grid container spacing={2}>
        <SelectBoxField
          id='cate-code'
          label='カテゴリーを選択'
          className={classes.inputField}
          defaultValue={items.categoryCode.defaultValue}
          errorMsg={items.categoryCode.errMsg}
          isDisabled={items.categoryCode.isDisabled}
          options={options.category}
        />

        <InputTextField
          id='title'
          label='表題'
          className={classes.inputField}
          defaultValue={items.title.defaultValue}
          errorMsg={items.title.errMsg}
          isDisabled={items.title.isDisabled}
        />
        <InputUploadField
          id='tmp-name1'
          label='添付ファイル&nbsp;1'
          className={classes.inputField}
          defaultValue={items.tmp_name1.defaultValue}
          errorMsg={items.tmp_name1.errMsg}
          checklistRef={defaultValues.fileRef1}
          isDisabled={items.tmp_name1.isDisabled}
        />
        <InputUploadField
          id='tmp-name2'
          label='添付ファイル&nbsp;2'
          className={classes.inputField}
          defaultValue={items.tmp_name2.defaultValue}
          errorMsg={items.tmp_name2.errMsg}
          checklistRef={defaultValues.fileRef2}
          isDisabled={items.tmp_name2.isDisabled}
        />
        <InputUploadField
          id='tmp-name3'
          label='添付ファイル&nbsp;3'
          className={classes.inputField}
          defaultValue={items.tmp_name3.defaultValue}
          errorMsg={items.tmp_name3.errMsg}
          checklistRef={defaultValues.fileRef3}
          isDisabled={items.tmp_name3.isDisabled}
        />
        <InputTextArea
          id='naiyou'
          label='情報詳細'
          className={classes.inputField}
          defaultValue={items.naiyou.defaultValue}
          errorMsg={items.naiyou.errMsg}
          isDisabled={items.naiyou.isDisabled}
        />
      </Grid>
    </>
  );
};
