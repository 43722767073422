import React, { ChangeEvent, FC } from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';

type Options = {
  key: string | number;
  val: string;
};

type Props = {
  label: string;
  style: {
    width: string;
    marginTop?: number;
    marginRight?: number;
  };
  value: number | string;
  options: Array<Options>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SelectControl: FC<Props> = (props) => {
  const { label, style, value, options, onChange } = props;

  return (
    <FormControl variant='outlined' style={style}>
      <InputLabel>{label}</InputLabel>
      <Select native label={label} value={value} onChange={onChange}>
        {options &&
          options.map((x, i) => (
            <option value={x.key} key={i}>
              {x.val}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};
