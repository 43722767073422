import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PluralSelect from 'views/pages/PluralSelect';
import Login from 'views/pages/Login';
import Home from 'views/pages/Home';
import NotFound from 'views/components/errorPages/NotFound';
import InternalServer from 'views/components/errorPages/InternalServer';
import InspectionDepartment from 'views/pages/InspectionDepartment';
import ExcelDownload from 'views/pages/ExcelDownload';
import { ChecklistDownload } from 'views/pages/ChecklistDownload';
import { ChecklistConfirm } from 'views/pages/ChecklistConfirm';
import { ChecklistVerification } from 'views/pages/ChecklistVerification';
import ChecklistUpload from 'views/pages/ChecklistUpload';
import { ChecklistTemp } from 'views/pages/ChecklistTemp';
import { ChecklistControl } from 'views/pages/ChecklistControl';

import JaMaster from 'views/pages/JaMaster';
import { InstitutionAdmin } from 'views/pages/InstitutionAdmin';
import { PrefCentralAdmin } from 'views/pages/PrefCentralAdmin';
import JaAdmin from 'views/pages/JaAdmin';
import { User } from 'views/pages/User';
import InspectionCategory from 'views/pages/InspectionCategory';
import InspectionSubcategory from 'views/pages/InspectionSubcategory';
import InformationMaster from 'views/pages/NewsInformationMaster';
import HiddenColumnMaster from 'views/pages/HiddenColumnMaster';
import NewsInformationList from 'views/pages/NewsInformationList';

import { FiscalYearMaster } from 'views/pages/FiscalYearMaster';

import { ManualMaster } from 'views/pages/ManualMaster';
import { ManualDownload } from 'views/pages/ManualDownload';

import { ThemeProvider } from '@material-ui/styles';
import theme from 'types/colofTheme';
import PrivateRoute from 'privateRoute';

import InspectionMonthMaster from 'views/pages/InspectionMonthMaster';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path='/login'>
            <Login />
          </Route>

          <PrivateRoute exact path='/'>
            <Login />
          </PrivateRoute>

          <PrivateRoute exact path='/plural-select'>
            <PluralSelect />
          </PrivateRoute>

          <PrivateRoute exact path='/home'>
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path='/inspection-department'>
            <InspectionDepartment />
          </PrivateRoute>

          <PrivateRoute exact path='/input-excel-download'>
            <ExcelDownload />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-download'>
            <ChecklistDownload />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-confirm'>
            <ChecklistConfirm />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-verification'>
            <ChecklistVerification />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-upload'>
            <ChecklistUpload />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-temp'>
            <ChecklistTemp />
          </PrivateRoute>

          <PrivateRoute exact path='/checklist-control'>
            <ChecklistControl />
          </PrivateRoute>

          <PrivateRoute exact path='/ja-master'>
            <JaMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/institution-admin'>
            <InstitutionAdmin />
          </PrivateRoute>

          <PrivateRoute exact path='/pref-central-admin'>
            <PrefCentralAdmin />
          </PrivateRoute>

          <PrivateRoute exact path='/ja-admin-master'>
            <JaAdmin />
          </PrivateRoute>

          <PrivateRoute exact path='/user-master'>
            <User />
          </PrivateRoute>

          <PrivateRoute exact path='/news-information-list'>
            <NewsInformationList />
          </PrivateRoute>

          <PrivateRoute exact path='/inspection-category'>
            <InspectionCategory />
          </PrivateRoute>

          <PrivateRoute exact path='/inspection-subcategory'>
            <InspectionSubcategory />
          </PrivateRoute>

          <PrivateRoute exact path='/information-master'>
            <InformationMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/hidden-column-master'>
            <HiddenColumnMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/manual-master'>
            <ManualMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/manual-download'>
            <ManualDownload />
          </PrivateRoute>

          <PrivateRoute exact path='/fiscal-year-master'>
            <FiscalYearMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/fiscal-year'>
            <FiscalYearMaster />
          </PrivateRoute>

          <PrivateRoute exact path='/inspection-month-master'>
            <InspectionMonthMaster />
          </PrivateRoute>

          <Route exact path='/internalServer'>
            <InternalServer />
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Router;
