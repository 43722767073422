import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import InputTextField from 'views/molecules/InputTextField';
import InputPasswordField from 'views/molecules/InputPasswordField';

type Props = {
  isDisabled: {
    userName: boolean;
    userDeptName?: boolean;
    userPost: boolean;
    telephoneNumber: boolean;
    email: boolean;
    password: boolean;
  };
  defaultValues?: {
    userName?: string;
    userDeptName?: string;
    userPost?: string;
    telephoneNumber?: string;
    email?: string;
  };
  isCheckboxHidden?: boolean;
  procType: number;
  context;
};

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
});

export const UserInfoSubForm: React.FC<Props> = (props) => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const user_type = user.user_type;

  const classes = useStyles();

  const {
    errorUserName,
    errorUserDeptName,
    errorUserPost,
    errorTelephoneNumber,
    errorEmail,
    errorPassword,
    setErrorPassword,
    setCheckboxToggles,
    isPlural,
  } = React.useContext(props.context);

  const [password, setPassword] = React.useState('');

  const [checkboxToggle, setCheckboxToggle] = React.useState(false);

  React.useEffect(() => {
    setCheckboxToggles(checkboxToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxToggle]);

  const handleClickCheckbox = (e) => {
    setCheckboxToggle(e.target.checked);
    setErrorPassword('');
  };

  return (
    <>
      <InputTextField
        id='user-name'
        label='ユーザ名（必須）'
        className={classes.inputField}
        defaultValue={props.defaultValues?.userName}
        errorMsg={errorUserName}
        isDisabled={props.isDisabled.userName}
      />
      {user_type !== USER_TYPE.JA_ADMIN && (
        <InputTextField
          id='user-dept-name'
          label='部署（必須）'
          className={classes.inputField}
          defaultValue={props.defaultValues?.userDeptName}
          errorMsg={errorUserDeptName}
          isDisabled={props.isDisabled.userDeptName}
        />
      )}
      <InputTextField
        id='user-post'
        label='役職'
        className={classes.inputField}
        defaultValue={props.defaultValues?.userPost}
        errorMsg={errorUserPost}
        isDisabled={props.isDisabled.userPost}
      />
      <InputTextField
        id='telephone-number'
        label='電話番号'
        className={classes.inputField}
        defaultValue={props.defaultValues?.telephoneNumber}
        errorMsg={errorTelephoneNumber}
        isDisabled={props.isDisabled.telephoneNumber}
      />
      <InputTextField
        id='email'
        label='メールアドレス（ログイン ID）（必須）'
        className={classes.inputField}
        defaultValue={props.defaultValues?.email}
        errorMsg={errorEmail}
        isDisabled={props.isDisabled.email}
      />
      {((props.procType === PROC_TYPE.CREATE && !isPlural) ||
        checkboxToggle) && (
        <InputPasswordField
          id='password'
          label='パスワード（必須）'
          className={classes.inputField}
          value={password}
          errorMsg={errorPassword}
          setPassword={setPassword}
        />
      )}
      {props.procType === PROC_TYPE.UPDATE && (
        <FormControlLabel
          id='password-rest'
          control={<Checkbox color='primary' />}
          label='パスワードを再設定する'
          onClick={handleClickCheckbox}
        />
      )}
    </>
  );
};
