import { TableCell, TableRow } from '@material-ui/core';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#7BD957',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
    },
  })
)(TableRow);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    formControl: {
      margin: theme.spacing(0),
      width: '15%',
    },
    downloadable: {
      color: '#0033CC',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  })
);
