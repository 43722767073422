import { createTheme } from '@material-ui/core';

const overrides = {
  MuiTab: {
    // general overrides for your material tab component here
    root: {
      backgroundColor: '#7BD957',
      '&$selected': {
        backgroundColor: 'green',
      },
    },
  },
};

const theme = createTheme({
  overrides,
  props: {},
  palette: {
    // primary: {
    //   main: '#43a047',
    // },
  },
});

export default theme;
