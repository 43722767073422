import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import axios from 'axios';

import UpdateDialog from 'views/components/dialogs/ChecklistControl/UpdateDialog';
import DataTable from 'views/components/tables/ChecklistControl/DataTable';
import { SelectControl } from 'views/molecules/SelectControl';

import { TplPage } from 'views/templates/TplPage';

export const ChecklistControlContext = React.createContext(
  {} as {
    currFiscalYear: number;
    selectedFiscalYear: number;
    pref_code: string;
    bank_code: string;
    fileRef: React.RefObject<HTMLInputElement>;
    data;
    setData;
  }
);

export const ChecklistControl: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { pref_code, bank_code } = user;

  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const fileRef = React.useRef<HTMLInputElement>(null);

  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [currFiscalYear, setCurrFiscalYear] = useState(0);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);

  useEffect(() => {
    Promise.all([
      axios.get('/api/fiscal-year-option-temp', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/checklist-control', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),
    ]).then((res) => {
      const fySmMap = new Map();

      Object.entries(res[2].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const fiscalYearAry = res[0].data.map((x) => {
        const key = `${x.fiscal_year}:${bank_code}`;
        return {
          key: x.fiscal_year,
          val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
        };
      });

      const curr_year = fiscalYearAry[0].key;

      setFiscalYearOption(fiscalYearAry);
      setCurrFiscalYear(curr_year);
      setSelectedFiscalYear(curr_year);

      setData(
        res[1].data.filter((x) => {
          return x.fiscal_year === curr_year;
        })
      );

      setOrgData(res[1].data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFiscalYear = (e) => {
    const fiscalYear = parseInt(e.target.value);
    setSelectedFiscalYear(fiscalYear);

    setData(
      orgData.filter((x) => {
        return x.fiscal_year === fiscalYear;
      })
    );
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            部署別チェックリスト登録
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={{ width: '320px' }}
              value={selectedFiscalYear}
              options={fiscalYearOption}
              onChange={handleChangeFiscalYear}
            />
          </div>

          <ChecklistControlContext.Provider
            value={{
              currFiscalYear,
              selectedFiscalYear,
              pref_code,
              bank_code,
              fileRef,
              data,
              setData,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <UpdateDialog />
            </Box>

            <DataTable />
          </ChecklistControlContext.Provider>
        </main>
      }
    />
  );
};
