import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { PrefCentralAdminContext } from 'views/pages/PrefCentralAdmin';
import { PrefCentralAdminForm } from 'views/components/forms/PrefCentralAdminForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{ checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    setPrefCentral,
    setDatas,
    setOrgDatas,
    setErrorUserName,
    setErrorUserDeptName,
    setErrorUserPost,
    setErrorTelephoneNumber,
    setErrorEmail,
    setErrorPassword,
    setErrorPrefCode,
    setErrorDummySideDept,
    setErrorDummySidePost,
    setErrorDummySideTel,
    setErrorDummySideEmail,
    setErrorDummySidePass,
  } = React.useContext(PrefCentralAdminContext);

  const { pref_code, pref_central_name, email } = props.checkedInfo;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [release_flg, setReleaseFlg] = useState(false);

  const [orgEmail, setOrgEmail] = useState('');

  const handleOpen = () => {
    setIsDialogOpen(true);

    setOrgEmail(email);

    setPrefCentral([
      {
        key: pref_code,
        val: pref_central_name,
      },
    ]);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorUserName('');
    setErrorUserDeptName('');
    setErrorUserPost('');
    setErrorTelephoneNumber('');
    setErrorEmail('');
    setErrorPassword('');
    setErrorPrefCode('');
    setErrorDummySideDept('');
    setErrorDummySidePost('');
    setErrorDummySideTel('');
    setErrorDummySideEmail('');
    setErrorDummySidePass('');
  };

  const handleUpdate = () => {
    const user_name =
      document.querySelector<HTMLInputElement>('#user-name').value;
    const user_dept_name =
      document.querySelector<HTMLInputElement>('#user-dept-name').value;
    const user_post =
      document.querySelector<HTMLInputElement>('#user-post').value;
    const telephone_number =
      document.querySelector<HTMLInputElement>('#telephone-number').value;
    const email = document.querySelector<HTMLInputElement>('#email').value;
    const password =
      document.querySelector<HTMLInputElement>('#password')?.value;
    const account_lock =
      document.querySelector<HTMLInputElement>('#release-lock')?.value;

    axios
      .post('/api/pref-central-admin/update', {
        pref_code,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        orgEmail,
        password,
        user_type: USER_TYPE.PREF_ADMIN,
        release_flg,
      })
      .then((res) => {
        const selected =
          document.querySelector<HTMLSelectElement>('#selected-pref').value;

        if (selected === '00') {
          setDatas(res.data[0]);
        } else {
          setDatas(
            res.data[0].filter((x) => {
              return x.pref_code === selected;
            })
          );
        }

        setOrgDatas(res.data[0]);

        props.checkedInfo.user_name = user_name;
        props.checkedInfo.user_dept_name = user_dept_name;
        props.checkedInfo.user_post = user_post;
        props.checkedInfo.telephone_number = telephone_number;
        props.checkedInfo.email = email;
        props.checkedInfo.account_lock = account_lock;

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.user_name
          ? setErrorUserName(errors.user_name.errorMsg)
          : setErrorUserName('');

        errors.user_dept_name
          ? setErrorUserDeptName(errors.user_dept_name.errorMsg)
          : setErrorUserDeptName('');

        errors.user_post
          ? setErrorUserPost(errors.user_post.errorMsg)
          : setErrorUserPost('');

        errors.telephone_number
          ? setErrorTelephoneNumber(errors.telephone_number.errorMsg)
          : setErrorTelephoneNumber('');

        errors.email ? setErrorEmail(errors.email.errorMsg) : setErrorEmail('');

        errors.password
          ? setErrorPassword(errors.password.errorMsg)
          : setErrorPassword('');

        if (errors.user_name) {
          setErrorPrefCode('　');
        } else {
          setErrorPrefCode('');
        }
        if (errors.user_dept_name) {
          setErrorDummySideDept('　');
        } else {
          setErrorDummySideDept('');
        }
        if (errors.user_post) {
          setErrorDummySidePost('　');
        } else {
          setErrorDummySidePost('');
        }
        if (errors.telephone_number) {
          setErrorDummySideTel('　');
        } else {
          setErrorDummySideTel('');
        }
        if (errors.email) {
          setErrorDummySideEmail('　');
        } else {
          setErrorDummySideEmail('');
        }
        if (errors.password) {
          setErrorDummySidePass('　');
        } else {
          setErrorDummySidePass('');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='管理者更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='都道府県中央会管理者更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <PrefCentralAdminForm
            isDisabled={{
              prefCentral: true,
              userName: false,
              userDeptName: false,
              userPost: false,
              telephoneNumber: false,
              email: false,
              password: false,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userDeptName: props.checkedInfo.user_dept_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
              account_lock: props.checkedInfo.account_lock,
            }}
            procType={PROC_TYPE.UPDATE}
            setReleaseFlg={setReleaseFlg}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='都道府県中央会管理者を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
