import React, { createContext, useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import CreateDialog from 'views/components/dialogs/InspectionCategory/CreateDialog';
import UpdateDialog from 'views/components/dialogs/InspectionCategory/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/InspectionCategory/DeleteDialog';
import DataTable from 'views/components/tables/InspectionCategory/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const InspectionCategoryContext = createContext(
  {} as {
    owner_user_type: number;
    data;
    setData;
    orgData;
    setOrgData;
    currFiscalYear;
    currFiscalYearPage;
    setCurrFiscalYear;
    setCurrFiscalYearPage;
    selCurrFiscalYear;
    setSelCurrFiscalYear;
    fiscalYearOption;
    setFiscalYearOption; //add 2022/5/13
    checkedInfo;
    setCheckedInfo;
    errorCategoryName;
    setErrorCategoryName;
  }
);

const InspectionCategory: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const owner_user_type = user.user_type;

  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [currFiscalYear, setCurrFiscalYear] = useState(0);
  const [currFiscalYearPage, setCurrFiscalYearPage] = useState(0);

  const [selCurrFiscalYear, setSelCurrFiscalYear] = useState(0);

  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [fiscalYearOptionPage, setFiscalYearOptionPage] = useState([]);

  const [checkedInfo, setCheckedInfo] = useState({ id: -1 });
  const [errorCategoryName, setErrorCategoryName] = useState('');

  const setOffset = useSetAtom(offsetAtom);

  const handleChangeFiscalYear = (e) => {
    const fiscalYear = parseInt(e.target.value);

    setCurrFiscalYearPage(fiscalYear);

    setData(
      orgData.filter((x) => {
        return x.fiscal_year === fiscalYear || fiscalYear === 0;
      })
    );

    setCheckedInfo({ id: -1 });

    setOffset(0);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/inspection-category', {
        params: {
          owner_user_type,
        },
      }),

      axios.get('/api/fiscal-year-option-temp', {
        params: {
          owner_user_type,
        },
      }),
    ]).then((res) => {
      const curr_year = parseInt(res[1].data[0].fiscal_year);

      const tmp = res[0].data.filter((x) => {
        return x.fiscal_year == curr_year;
      });

      setData(tmp);
      setOrgData(res[0].data);

      const fiscalYearMap = res[1].data.map((x) => {
        return { key: x.fiscal_year, val: `${x.fiscal_year} 年度` };
      });

      setCurrFiscalYear(curr_year);
      setFiscalYearOption([{ key: curr_year, val: `${curr_year} 年度` }]);
      setCurrFiscalYearPage(fiscalYearMap[0].key);
      setFiscalYearOptionPage(fiscalYearMap);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            チェックリスト区分登録
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl variant='outlined' style={{ width: '250px' }}>
              <InputLabel shrink>検査年度を選択</InputLabel>
              <Select
                native
                value={currFiscalYearPage}
                onChange={handleChangeFiscalYear}
                label='検査年度を選択'
              >
                {fiscalYearOptionPage &&
                  fiscalYearOptionPage.map((x, i) => (
                    <option value={x.key} key={i}>
                      {x.val}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>

          <InspectionCategoryContext.Provider
            value={{
              owner_user_type,
              data,
              setData,
              orgData,
              setOrgData,
              currFiscalYear,
              currFiscalYearPage,
              setCurrFiscalYear,
              setCurrFiscalYearPage,
              selCurrFiscalYear,
              setSelCurrFiscalYear,
              fiscalYearOption,
              setFiscalYearOption, //add 2022/5/13
              checkedInfo,
              setCheckedInfo,
              errorCategoryName,
              setErrorCategoryName,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />

              <UpdateDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
            </Box>

            <DataTable
              datas={data}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </InspectionCategoryContext.Provider>
        </main>
      }
    />
  );
};

export default InspectionCategory;
