import React from 'react';

import { Box, Container } from '@material-ui/core';

import Header from 'views/components/Header';
import Footer from 'views/components/Footer';

type Props = {
  content: JSX.Element;
};

export const TplPage: React.FC<Props> = (props) => {
  return (
    <div style={{ backgroundColor: '#EFEFEF' }}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#EFEFEF',
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header />

          {props.content}

          <Footer />
        </Box>
      </Container>
    </div>
  );
};
