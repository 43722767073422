import React, { createContext, useEffect, useState, useRef } from 'react';

import { Box } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { CHECKLIST_KBN_OPT, USER_TYPE } from 'utils/constants';
import { selectOptionType } from 'types/common';
import CreateDialog from 'views/components/dialogs/InspectionSubcategory/CreateDialog';
import UpdateDialog from 'views/components/dialogs/InspectionSubcategory/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/InspectionSubcategory/DeleteDialog';
import DataTable from 'views/components/tables/InspectionSubcategory/DataTable';
import CheckDialog from 'views/components/dialogs/InspectionSubcategory/ApproveDialog';

import { TplPage } from 'views/templates/TplPage';
import { SelectControl } from 'views/molecules/SelectControl';
import { offsetAtom } from 'jotai/atoms';

export const InspectionSubCategoryContext = createContext(
  {} as {
    defaultValues: {
      category: Array<selectOptionType>;
      subcategoryName: string;
      comment: string;
      checklistRef;
    };
    selectOptions: {
      category: Array<selectOptionType>;
    };
    errorMsgs: {
      subcategoryName: string;
      comment: string;
      checklist: string;
    };
    setErrorMsgs: {
      subcategoryName: React.Dispatch<React.SetStateAction<string>>;
      comment: React.Dispatch<React.SetStateAction<string>>;
      checklist: React.Dispatch<React.SetStateAction<string>>;
    };
    eventHandlers: {
      setCategory: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
      setSubcategoryName: React.Dispatch<React.SetStateAction<string>>;
      setComment: React.Dispatch<React.SetStateAction<string>>;
    };
    user_type;
    owner_user_type: string;
    pref_code: string;
    bank_code: string;
    currFiscalYear;
    selectedFiscalYear;
    setSelectedFiscalYear;
    fiscalYearOption;
    setFiscalYearOption;
    setData;
    setOrgData;
    setCheckedInfo;
    setCheckedIds;
    setCheckedInfos;
    regCategory;
    setRegCategory;
    regOrgCategory;
    setRegOrgCategory;
    setSelectedCategory;
    setSearchOptions;
    approvedInfo;
    setApprovedInfo;
    selectedCategory;
    approvedIds;
    setApprovedIds;
    pivFiscalYear;
  }
);

const InspectionSubcategory: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { user_type, pref_code, bank_code } = user;

  const owner_user_type = user_type;

  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategoryName, setSubcategoryName] = useState('');
  const [comment, setComment] = useState('');
  const [checkedInfo, setCheckedInfo] = useState({
    id: -1,
    owner_user_type: -1,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedInfos, setCheckedInfos] = useState([]);

  const [errorSubcategoryName, setErrorSubcategoryName] = useState('');
  const [errorComment, setErrorComment] = useState('');
  const [errorChecklist, setErrorChecklist] = useState('');

  const [searchOptions, setSearchOptions] = useState(CHECKLIST_KBN_OPT);

  const checklistRef = useRef(null);

  const setOffset = useSetAtom(offsetAtom);

  const [currFiscalYear, setCurrFiscalYear] = useState(0);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);
  const [fiscalYearOption, setFiscalYearOption] = useState([]);

  const [regCategory, setRegCategory] = useState([]);
  const [regOrgCategory, setRegOrgCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const [approvedInfo, setApprovedInfo] = useState([]);

  const [approvedIds, setApprovedIds] = useState(new Map());

  const [pivFiscalYear, setPivFiscalYear] = useState(0);

  const handleChangeFiscalYear = (e) => {
    const fiscalYear = parseInt(e.target.value);

    setSelectedFiscalYear(fiscalYear);

    const tmp = new Map();

    const approvedMap = new Map();

    approvedInfo
      .filter((x) => {
        return x.pref_code === pref_code;
      })
      .map((x) => {
        const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
        approvedMap.set(key, 'dummy');
      });

    switch (parseInt(user_type)) {
      case USER_TYPE.SYSTEM_ADMIN: {
        orgData
          .filter((x) => {
            return x.fiscal_year === fiscalYear && !x.pref_code && !x.bank_code;
          })
          .map((x) => {
            const key = `${x.category_code}:${x.subcategory_code}`;
            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        break;
      }

      case USER_TYPE.PREF_ADMIN: {
        orgData
          .filter((x) => {
            return (
              x.fiscal_year === fiscalYear &&
              (!x.pref_code || x.pref_code === pref_code) &&
              !x.bank_code
            );
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
            if (approvedMap.get(key)) {
              x['check_status'] = 1;
            } else {
              x['check_status'] = 0;
            }

            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        break;
      }

      case USER_TYPE.JA_ADMIN: {
        orgData
          .filter((x) => {
            return (
              x.fiscal_year === fiscalYear &&
              (!x.pref_code || x.pref_code === pref_code) &&
              (!x.bank_code || x.bank_code === bank_code)
            );
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

            // 1または3の場合、「承認済」が必要
            if (approvedMap.get(key)) {
              tmp.set(key, x);
              // 4の場合、該当prefとbankで出力する
            } else if (
              x.owner_user_type == USER_TYPE.JA_ADMIN &&
              pref_code == x.pref_code &&
              bank_code == x.bank_code
            ) {
              tmp.set(key, x);
            }
          });

        setData(Array.from(tmp.values()));

        break;
      }
    }

    setSearchOptions([
      ...CHECKLIST_KBN_OPT,
      ...Array.from(tmp.values())
        .map((x) => {
          return {
            key: x.category_code,
            val:
              x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                ? x.category_name.substring(2)
                : x.category_name,
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        ),
    ]);

    setCheckedInfo({ id: -1, owner_user_type: -1 });
    setCheckedIds([]);
    setCheckedInfos([]);

    setSelectedCategory(0);

    setOffset(0);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/inspection-category', {
        params: {
          owner_user_type,
        },
      }),

      axios.get('/api/inspection-subcategory', {
        params: {
          user_type,
        },
      }),

      axios.get('/api/fiscal-year-option', {
        params: {
          pref_code,
          bank_code,
          user_type,
        },
      }),

      axios.get('/api/approved_checklist'),

      axios.get('/api/current-fiscal-year', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),
    ]).then((res) => {
      const curr_year = parseInt(res[2].data[0].fiscal_year);

      {
        setRegCategory(res[0].data);
        setRegOrgCategory(res[0].data);
      }

      {
        const wk = res[1].data.map((x) => {
          return {
            key: x.category_code,
            val:
              x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                ? x.category_name.substring(2)
                : x.category_name,
          };
        });

        setCategoryOptions([...categoryOptions, ...wk]);
        setCategory([...category, ...wk]);
      }

      {
        const fySmMap = new Map();

        Object.entries(res[5].data).forEach((x) => {
          fySmMap.set(x[0], x[1]);
        });

        const fiscalYearMap = res[2].data.map((x) => {
          const key = `${x.fiscal_year}:${bank_code}`;
          return {
            key: x.fiscal_year,
            val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
          };
        });

        setCurrFiscalYear(curr_year);
        setSelectedFiscalYear(curr_year);

        setFiscalYearOption(fiscalYearMap);
      }

      {
        const tmpMap = new Map();
        const approvedMap = new Map();

        res[3].data
          .filter((x) => {
            return x.pref_code === pref_code;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
            approvedMap.set(key, 'dummy');
          });

        res[1].data
          .filter((x) => {
            return x.fiscal_year === curr_year;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

            switch (owner_user_type) {
              case USER_TYPE.SYSTEM_ADMIN:
                if (!x.pref_code && !x.bank_code) {
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.PREF_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code)
                ) {
                  if (approvedMap.get(key)) {
                    x['check_status'] = 1;
                  } else {
                    x['check_status'] = 0;
                  }
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.JA_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && x.bank_code === bank_code)
                ) {
                  if (
                    x.owner_user_type === USER_TYPE.JA_ADMIN ||
                    approvedMap.get(key)
                  ) {
                    tmpMap.set(key, x);
                  }
                }
                break;
            }
          });

        const tmpArray = Array.from(tmpMap.values());
        setData(tmpArray);
        setOrgData(res[1].data);

        setApprovedInfo(res[3].data);

        const wk2 = tmpArray
          .map((x) => {
            return {
              key: x.category_code,
              val:
                x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                  ? x.category_name.substring(2)
                  : x.category_name,
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key == elm.key) == idx
          );

        setSearchOptions([...searchOptions, ...wk2]);
      }

      setPivFiscalYear(res[4].data.curr_fiscal_year);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCategory = (e) => {
    const category_code = parseInt(e.target.value);

    setSelectedCategory(category_code);

    const tmpMap = new Map();

    const approvedMap = new Map();

    approvedInfo
      .filter((x) => {
        return x.pref_code === pref_code;
      })
      .map((x) => {
        const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
        approvedMap.set(key, 'dummy');
      });

    orgData
      .filter((x) => {
        return (
          x.fiscal_year === selectedFiscalYear &&
          (!x.pref_code || x.pref_code === pref_code) &&
          (!x.bank_code || x.bank_code === bank_code)
        );
      })
      .map((x) => {
        const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

        if (user_type === USER_TYPE.JA_ADMIN) {
          // 1または3の場合、「承認済」が必要
          if (approvedMap.get(key)) {
            tmpMap.set(key, x);
            // 4の場合、該当prefとbankで出力する
          } else if (
            x.owner_user_type == USER_TYPE.JA_ADMIN &&
            pref_code == x.pref_code &&
            bank_code == x.bank_code
          ) {
            tmpMap.set(key, x);
          }
        } else {
          tmpMap.set(key, x);
        }
      });

    const tmpArray = Array.from(tmpMap.values());
    if (category_code === 0) {
      setData(tmpArray);
    } else {
      setData(
        tmpArray.filter((x) => {
          return x.category_code === category_code;
        })
      );
    }

    setCheckedInfo({ id: -1, owner_user_type: -1 });
    setCheckedIds([]);
    setCheckedInfos([]);
    setOffset(0);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {user_type === USER_TYPE.SYSTEM_ADMIN && 'チェックリスト登録'}
            {user_type === USER_TYPE.PREF_ADMIN &&
              'チェックリスト変更・登録・承認'}
            {user_type === USER_TYPE.JA_ADMIN && 'チェックリスト変更・登録'}
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={
                bank_code
                  ? { width: '320px', marginRight: 8 }
                  : { width: '250px', marginRight: 8 }
              }
              value={selectedFiscalYear}
              options={fiscalYearOption}
              onChange={handleChangeFiscalYear}
            />
            <SelectControl
              label='チェックリスト区分を選択'
              style={
                bank_code
                  ? { width: '320px', marginRight: 8 }
                  : { width: '250px', marginRight: 8 }
              }
              value={selectedCategory}
              options={searchOptions}
              onChange={handleChangeCategory}
            />
          </div>

          <InspectionSubCategoryContext.Provider
            value={{
              defaultValues: {
                category: categoryOptions,
                subcategoryName: subcategoryName,
                comment: comment,
                checklistRef: checklistRef,
              },
              selectOptions: {
                category: category,
              },
              errorMsgs: {
                subcategoryName: errorSubcategoryName,
                comment: errorComment,
                checklist: errorChecklist,
              },
              setErrorMsgs: {
                subcategoryName: setErrorSubcategoryName,
                comment: setErrorComment,
                checklist: setErrorChecklist,
              },
              eventHandlers: {
                setCategory: setCategory,
                setSubcategoryName: setSubcategoryName,
                setComment: setComment,
              },
              user_type,
              owner_user_type,
              pref_code,
              bank_code,
              currFiscalYear,
              selectedFiscalYear,
              setSelectedFiscalYear,
              fiscalYearOption,
              setFiscalYearOption,
              setData,
              setOrgData,
              setCheckedInfo,
              setCheckedIds,
              setCheckedInfos,
              regCategory,
              setRegCategory,
              regOrgCategory,
              setRegOrgCategory,
              setSelectedCategory,
              setSearchOptions,
              approvedInfo,
              setApprovedInfo,
              selectedCategory,
              approvedIds,
              setApprovedIds,
              pivFiscalYear,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              {user_type === USER_TYPE.SYSTEM_ADMIN && (
                <>
                  <CreateDialog />

                  <UpdateDialog
                    checkedInfo={checkedInfo}
                    setCheckedIds={setCheckedIds}
                    isDisabled={checkedInfo.id === -1}
                  />
                </>
              )}

              {user_type !== USER_TYPE.SYSTEM_ADMIN && (
                <>
                  <UpdateDialog
                    checkedInfo={checkedInfo}
                    setCheckedIds={setCheckedIds}
                    isDisabled={checkedInfo.id === -1}
                  />

                  <CreateDialog />
                </>
              )}

              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={
                  checkedInfo.id === -1 ||
                  checkedInfo.owner_user_type !== user_type
                }
              />

              {owner_user_type === USER_TYPE.PREF_ADMIN && (
                <CheckDialog
                  checkedIds={checkedIds}
                  checkedInfos={checkedInfos}
                  isDisabled={1 > checkedIds.length}
                />
              )}
            </Box>

            <DataTable
              userType={owner_user_type}
              checklist={data}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              checkedInfos={checkedInfos}
              setCheckedInfos={setCheckedInfos}
            />
          </InspectionSubCategoryContext.Provider>
        </main>
      }
    />
  );
};

export default InspectionSubcategory;
