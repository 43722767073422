import React from 'react';
import { Box, Dialog, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistUploadContext } from 'views/pages/ChecklistUpload';
import { DialogButtonUpload } from 'views/atoms/buttons/dialogs/Upload';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CommentDialog: React.FC<{ isDisabled }> = () => {
  const classes = useStyles();

  const { fiscal_year, pref_code, bank_code, checkedInfo, mapReturnedComment } =
    React.useContext(ChecklistUploadContext);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box component='span' style={{ marginLeft: 8 }}>
      <DialogButtonUpload
        buttonLabel='コメント確認'
        onClickActionHandler={handleOpen}
        isDisabled={
          checkedInfo.key === -1 ||
          !mapReturnedComment.has(
            [
              fiscal_year,
              pref_code,
              bank_code,
              checkedInfo.inspectionMonthKey,
              checkedInfo.category_code,
              checkedInfo.subcategory_code,
            ].join(':')
          )
        }
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='コメント確認'
          onClickActionHandler={handleClose}
        />
        <Divider />

        <div style={{ margin: 32 }}>
          {mapReturnedComment
            .get(
              [
                fiscal_year,
                pref_code,
                bank_code,
                checkedInfo.inspectionMonthKey,
                checkedInfo.category_code,
                checkedInfo.subcategory_code,
              ].join(':')
            )
            ?.map((x, i) => {
              return (
                <div key={i} style={{ fontSize: 16, marginBottom: 16 }}>
                  <div>差戻者：{x.split('#')[0]}</div>
                  <div>差戻日時：{x.split('#')[1]}</div>
                  <div>差戻理由：{x.split('#')[2]}</div>
                  <hr />
                </div>
              );
              <hr />;
            })}
        </div>
      </Dialog>
    </Box>
  );
};

export default CommentDialog;
