import React from 'react';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { ChecklistTempContext } from 'views/pages/ChecklistTemp';
import { ChecklistTempForm } from 'views/components/forms/ChecklistTempForm';
import { DialogButtonUpload } from 'views/atoms/buttons/dialogs/Upload';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC<{ setCheckedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    email,
    checklistRef,
    setChecklist,
    setErrorChecklist,
    setErrorComment,
    setDisabledChecklist,
    setDisabledComment,
  } = React.useContext(ChecklistTempContext);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);

    setDisabledChecklist(false);
    setDisabledComment(false);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorChecklist('');
    setErrorComment('');
  };

  const handleCreate = () => {
    const formData = new FormData();

    const comment = document.querySelector<HTMLInputElement>('#comment').value;

    formData.append('pref_code', pref_code);
    formData.append('bank_code', bank_code);
    formData.append('email', email);
    formData.append('checklist_name', checklistRef.current.files[0]?.name);
    formData.append('checklist', checklistRef.current.files[0]);
    formData.append('comment', comment);

    axios
      .post('/api/checklist-temp/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setChecklist(res.data);
        props.setCheckedInfo({ checklist_code: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.checklist
          ? setErrorChecklist(errors.checklist.errorMsg)
          : setErrorChecklist('');

        errors.comment
          ? setErrorComment(errors.comment.errorMsg)
          : setErrorComment('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpload
        buttonLabel='チェックリスト一時保存'
        onClickActionHandler={handleOpen}
        isDisabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='チェックリスト一時保存'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 16 }}
        >
          <ChecklistTempForm />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='チェックリストを一時保存する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
