import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { PROC_TYPE } from 'utils/constants';
import InputTextField from 'views/molecules/InputTextField';
import SelectBoxField from 'views/molecules/SelectBoxField';
import { JaMasterContext } from 'views/pages/JaMaster';

type items = {
  prefCode: { defaultValue: string; errMsg: string; isDisabled: boolean };
  bankCode: { defaultValue: string; errMsg: string; isDisabled: boolean };
  jaName: { defaultValue: string; errMsg: string; isDisabled: boolean };
  jaNickname: { defaultValue: string; errMsg: string; isDisabled: boolean };
  address: { defaultValue: string; errMsg: string; isDisabled: boolean };
  fiscalYear: { defaultValue: number; errMsg: string; isDisabled: boolean };
  startMonth: { defaultValue: number; errMsg: string; isDisabled: boolean };
  contactPersonnelName: {
    defaultValue: string;
    errMsg: string;
    isDisabled: boolean;
  };
  contactPersonnelDeptName: {
    defaultValue: string;
    errMsg: string;
    isDisabled: boolean;
  };
  contactPersonnelRole: {
    defaultValue: string;
    errMsg: string;
    isDisabled: boolean;
  };
  contactPersonnelPhoneNumber: {
    defaultValue: string;
    errMsg: string;
    isDisabled: boolean;
  };
  contactPersonnelEmail: {
    defaultValue: string;
    errMsg: string;
    isDisabled: boolean;
  };
  remarks: { defaultValue: string; errMsg: string; isDisabled: boolean };
  is_public: { defaultValue: boolean; isDisabled: boolean };
};

type options = {
  prefs: { key: string; val: string }[];
  startMonths: { key: number; val: string }[];
};

type Props = {
  classes: ClassNameMap;
  items: items;
  options: options;
  proc_type?: number;
  errorDelete?: string;
};

export const JaMasterForm: React.FC<Props> = (props) => {
  const { classes, items, options, proc_type, errorDelete } = props;

  const { selectedFiscalYear, selectedStartMonth, setSelectedStartMonth } =
    React.useContext(JaMasterContext);

  const handleChangeStartMonth = (e) => {
    setSelectedStartMonth(e.currentTarget.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectBoxField
            id='pref-code'
            label='都道府県を選択'
            className={classes.inputField}
            defaultValue={items.prefCode.defaultValue}
            errorMsg={items.prefCode.errMsg}
            isDisabled={items.prefCode.isDisabled}
            options={options.prefs}
          />
          <InputTextField
            id='bank-code'
            label='金融機関コード（上4桁 必須）'
            className={classes.inputField}
            defaultValue={items.bankCode.defaultValue}
            errorMsg={items.bankCode.errMsg}
            isDisabled={items.bankCode.isDisabled}
          />
          <InputTextField
            id='ja-name'
            label='JA&thinsp;名称（必須）'
            className={classes.inputField}
            defaultValue={items.jaName.defaultValue}
            errorMsg={items.jaName.errMsg}
            isDisabled={items.jaName.isDisabled}
          />
          <InputTextField
            id='ja-nickname'
            label='JA&thinsp;愛称（必須）'
            className={classes.inputField}
            defaultValue={items.jaNickname.defaultValue}
            errorMsg={items.jaNickname.errMsg}
            isDisabled={items.jaNickname.isDisabled}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectBoxField
                id='fiscal-year'
                label='開始検査年度を選択'
                className={classes.inputField}
                defaultValue={selectedFiscalYear}
                errorMsg={items.fiscalYear.errMsg}
                options={[
                  {
                    key: selectedFiscalYear,
                    val: `${selectedFiscalYear} 年度`,
                  },
                  {
                    key: selectedFiscalYear + 1,
                    val: `${selectedFiscalYear + 1} 年度`,
                  },
                ]}
                isDisabled={items.fiscalYear.isDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectBoxField
                id='start-month'
                label='検査年度開始月を選択'
                className={classes.inputField}
                defaultValue={
                  selectedStartMonth || items.startMonth.defaultValue
                }
                errorMsg={items.startMonth.errMsg}
                options={options.startMonths}
                isDisabled={items.startMonth.isDisabled}
                onChange={handleChangeStartMonth}
              />
            </Grid>
          </Grid>
          <InputTextField
            id='address'
            label='住所'
            className={classes.inputField}
            defaultValue={items.address.defaultValue}
            errorMsg={items.address.errMsg}
            isDisabled={items.address.isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputTextField
            id='contact-name'
            label='窓口担当者名'
            className={classes.inputField}
            defaultValue={items.contactPersonnelName.defaultValue}
            errorMsg={items.contactPersonnelName.errMsg}
            isDisabled={items.contactPersonnelName.isDisabled}
          />
          <InputTextField
            id='contact-dept-name'
            label='窓口担当者部署'
            className={classes.inputField}
            defaultValue={items.contactPersonnelDeptName.defaultValue}
            errorMsg={items.contactPersonnelDeptName.errMsg}
            isDisabled={items.contactPersonnelDeptName.isDisabled}
          />
          <InputTextField
            id='contact-role'
            label='窓口担当者役職'
            className={classes.inputField}
            defaultValue={items.contactPersonnelRole.defaultValue}
            errorMsg={items.contactPersonnelRole.errMsg}
            isDisabled={items.contactPersonnelRole.isDisabled}
          />
          <InputTextField
            id='contact-phone-number'
            label='窓口担当者電話番号'
            className={classes.inputField}
            defaultValue={items.contactPersonnelPhoneNumber.defaultValue}
            errorMsg={items.contactPersonnelPhoneNumber.errMsg}
            isDisabled={items.contactPersonnelPhoneNumber.isDisabled}
          />
          <InputTextField
            id='contact-email'
            label='窓口担当者メールアドレス'
            className={classes.inputField}
            defaultValue={items.contactPersonnelEmail.defaultValue}
            errorMsg={items.contactPersonnelEmail.errMsg}
            isDisabled={items.contactPersonnelEmail.isDisabled}
          />
          <InputTextField
            id='remarks'
            label='備考'
            className={classes.inputField}
            defaultValue={items.remarks.defaultValue}
            errorMsg={items.remarks.errMsg}
            isDisabled={items.remarks.isDisabled}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id='is-public'
                color='primary'
                disabled={items.is_public.isDisabled}
                defaultChecked={items.is_public.defaultValue}
              />
            }
            label='都道府県中央会に公開を許可する'
          />
        </Grid>
        {proc_type === PROC_TYPE.CREATE && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  id='is-dept-lv2-invalid'
                  color='primary'
                  disabled={items.is_public.isDisabled}
                  defaultChecked={items.is_public.defaultValue}
                />
              }
              label='階層レベルⅡの部署が存在しない'
            />
          </Grid>
        )}
      </Grid>

      {errorDelete?.length > 0 && (
        <p style={{ textAlign: 'center', color: 'red' }}>{errorDelete}</p>
      )}
    </>
  );
};
