import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getHSEById } from 'utils/functions';
import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import { InspectionDepartmentForm } from 'views/components/forms/InspectionDepartmentForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    setErrorMsgs,
    setData,
    setOrgData,
    setCheckedInfo,
    currFiscalYear,
  } = useContext(InspectionDepartmentContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorMsgs.inspectionDepartmentName('');
  };

  const handleDelete = () => {
    const selectedDeptLv = parseInt(getHSEById('#dept-lv').value);

    axios
      .post('/api/inspection-department/delete', {
        fiscal_year: currFiscalYear,
        pref_code,
        bank_code,
        department_level_code: selectedDeptLv,
        dept_code_lv1: props.checkedInfo.dept_code_lv1,
        dept_code_lv2: props.checkedInfo.dept_code_lv2,
        dept_code_lv3: props.checkedInfo.dept_code_lv3,
        dept_code_lv4: props.checkedInfo.dept_code_lv4,
        dept_code_lv5: props.checkedInfo.dept_code_lv5,
      })
      .then((res) => {
        setData(
          res.data.filter((x) => {
            return (
              x.fiscal_year === currFiscalYear &&
              x.department_level_code === selectedDeptLv
            );
          })
        );
        setOrgData(res.data);
        setCheckedInfo({ id: -1, name: null });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        setErrorMsgs.inspectionDepartmentName(errors.delete.errorMsg);
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='部署削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='部署削除'
          onClickActionHandler={handleClose}
        />
        <Divider />
        <DialogContent style={{ height: '100%' }}>
          <InspectionDepartmentForm
            isDisabled={{
              deptLv2: true,
              deptLv3: true,
              deptLv4: true,
              deptName: true,
            }}
            defaultValue={{
              deptLv1: props.checkedInfo.dept_name_lv1,
              deptLv2: props.checkedInfo.dept_name_lv2,
              deptLv3: props.checkedInfo.dept_name_lv3,
              deptLv4: props.checkedInfo.dept_name_lv4,
              deptLv5: props.checkedInfo.dept_name_lv5,
            }}
            targetDeptLv2={[
              {
                key: props.checkedInfo.dept_code_lv2,
                val: props.checkedInfo.dept_name_lv2,
              },
            ]}
            targetDeptLv3={[
              {
                key: props.checkedInfo.dept_code_lv3,
                val: props.checkedInfo.dept_name_lv3,
              },
            ]}
            targetDeptLv4={[
              {
                key: props.checkedInfo.dept_code_lv4,
                val: props.checkedInfo.dept_name_lv4,
              },
            ]}
          />
        </DialogContent>
        <Divider />

        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 18,
            textAlign: 'center',
          }}
        >
          ※&thinsp;年度中に部署の削除を行うと、これまでの検査結果が適正に
        </span>
        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 3,
          }}
        >
          &thinsp;&thinsp;&emsp;&emsp;&emsp;&emsp;&emsp;表示できなくなります。
        </span>
        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 3,
            marginBottom: -10,
          }}
        >
          &thinsp;&thinsp;&emsp;&emsp;&emsp;&emsp;&emsp;部署の削除は年度管理マスタで年度更新後に行ってください。
        </span>

        <DialogActionsDelete
          buttonLabel='部署を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
