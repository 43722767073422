import React from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';

import { convertJST, downloadExcel } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  datas;
  datasWk;
  setDatasWk;
  checkedInfo;
  setCheckedInfo;
  value;
  setValue;
  category;
}> = (props) => {
  const classes = useStyles();

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ id: -1 });
    setOffset(offset);
  };

  const handleChange = (e, cate) => {
    let wk = [];
    if (cate == 0) {
      props.setDatasWk(props.datas);
    } else {
      wk = props.datas.filter((x) => {
        return x.category_code === cate;
      });
      props.setDatasWk(wk);
    }
    props.setValue(cate);
    props.setCheckedInfo({ id: -1 });

    setOffset(0);
  };

  const handleCheckbox = (
    id: number,
    category_code: number,
    category_name: string,
    title: string,
    naiyou: string,
    tmp_name1: string,
    tmp_name2: string,
    tmp_name3: string
  ) => {
    if (id === props.checkedInfo.id) {
      props.setCheckedInfo({
        id: -1,
      });
    } else {
      props.setCheckedInfo({
        id,
        category_code,
        category_name,
        title,
        naiyou,
        tmp_name1,
        tmp_name2,
        tmp_name3,
      });
    }
  };

  const handleDownload = async (id: number, tmpFileNo: number) => {
    const res = await axios.post(
      '/api/news_information/download/nw',
      { id, tmpFileNo },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Tabs
          value={props.value}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgba(0,0,0,0)',
              height: '10px',
            },
          }}
          onChange={handleChange}
          variant='fullWidth'
          centered
          style={{
            color: 'white',
            width: '100%',
            textAlign: 'center',
          }}
          // TabScrollButtonProps={{
          //   style: {
          //     color: 'black',
          //   },
          // }}
          // variant='scrollable'
          // scrollButtons='auto'
          // aria-label='scrollable auto tabs example'
        >
          {props.category.map((label) => (
            <Tab value={label.key} key={label.key} label={label.val}></Tab>
          ))}
        </Tabs>

        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableBody>
            {props.datasWk.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.info_id}>
                <StyledTableCell align='center' style={{ width: '10%' }}>
                  <input
                    type='checkbox'
                    checked={props.checkedInfo.id === elm.info_id}
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, paddingLeft: 28 }}
                    onChange={() => {
                      handleCheckbox(
                        elm.info_id,
                        elm.category_code,
                        elm.news_category.category_name,
                        elm.title,
                        elm.naiyou,
                        elm.tmp_name1,
                        elm.tmp_name2,
                        elm.tmp_name3
                      );
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>
                  {convertJST(elm.updatedAt).substring(0, 10)}
                </StyledTableCell>
                {/* <StyledTableCell>
                {elm.news_category.category_name}
              </StyledTableCell> */}
                <StyledTableCell style={{ width: '35%' }}>
                  {elm.title}
                </StyledTableCell>
                <StyledTableCell style={{ width: '30%' }}>
                  {elm.tmp_name1 && (
                    <div
                      className={classes.downloadable}
                      onClick={() => {
                        handleDownload(elm.info_id, 1);
                      }}
                    >
                      {elm.tmp_name1}
                    </div>
                  )}
                  {elm.tmp_name2 && (
                    <div
                      className={classes.downloadable}
                      onClick={() => {
                        handleDownload(elm.info_id, 2);
                      }}
                    >
                      {elm.tmp_name2}
                    </div>
                  )}
                  {elm.tmp_name3 && (
                    <div
                      className={classes.downloadable}
                      onClick={() => {
                        handleDownload(elm.info_id, 3);
                      }}
                    >
                      {elm.tmp_name3}
                    </div>
                  )}
                  {!elm.tmp_name1 && !elm.tmp_name2 && !elm.tmp_name3 && (
                    <div>&thinsp;-</div>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.datasWk.length > PAR_PAGE && (
        <JICSPagination
          length={props.datasWk.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
