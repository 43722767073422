import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE } from 'utils/constants';
import { InspectionCategoryContext } from 'views/pages/InspectionCategory';
import SelectBoxField from 'views/molecules/SelectBoxField';
import InputTextField from 'views/molecules/InputTextField';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  selectBox: {
    marginTop: 12,
    width: '100%',
  },
});

type Props = {
  defaultValue?: string;
  procType: number;
};

export const InspectionCategoryForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    selCurrFiscalYear,
    setSelCurrFiscalYear,
    fiscalYearOption,
    errorCategoryName,
  } = useContext(InspectionCategoryContext);

  const { defaultValue = '', procType } = props;

  const handleChangeFiscalYear = (e) => {
    setSelCurrFiscalYear(parseInt(e.target.value));
  };

  return (
    <>
      <SelectBoxField
        id='fiscal-year'
        label='検査年度'
        className={classes.selectBox}
        defaultValue={selCurrFiscalYear}
        options={fiscalYearOption}
        onChange={handleChangeFiscalYear}
        isDisabled={true /*PROC_TYPE.CREATE !== procType*/}
      />
      <InputTextField
        id='category-name'
        label='チェックリスト区分'
        className={classes.inputField}
        defaultValue={defaultValue}
        errorMsg={errorCategoryName}
        isDisabled={PROC_TYPE.DELETE === procType}
      />
    </>
  );
};
