import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { InstitutionAdminContext } from 'views/pages/InstitutionAdmin';
import { InstitutionAdminForm } from 'views/components/forms/InstitutionAdminForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{ checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    setInstitution,
    setRegisteredInstitution,
    setDatas,
    setOrgDatas,
    setCheckedInfo,
  } = React.useContext(InstitutionAdminContext);

  const { institution_code, institution_name, email } = props.checkedInfo;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);

    setInstitution([
      {
        key: institution_code,
        val: institution_name,
      },
    ]);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    const institution_code =
      document.querySelector<HTMLSelectElement>('#institution-code').value;

    axios
      .post('/api/institution-admin/delete', {
        institution_code,
        email,
        user_type: USER_TYPE.ALL_PREF_ADMIN,
      })
      .then((res) => {
        const selected = parseInt(
          document.querySelector<HTMLSelectElement>('#selected-institution')
            .value
        );

        const tmp = res.data[0].filter((x) => {
          return x.institution_code === selected;
        });

        if (selected === 0 || tmp.length === 0) {
          setRegisteredInstitution([
            ...[{ key: '0', val: 'すべて' }],
            ...res.data[0]
              .map((x) => {
                return {
                  key: x.institution_code,
                  val: x.institution_name,
                };
              })
              .filter(
                (elm, idx, self) =>
                  self.findIndex(
                    (x) => x.key === elm.key && x.val === elm.val
                  ) === idx
              ),
          ]);

          document.querySelector<HTMLSelectElement>(
            '#selected-institution'
          ).selectedIndex = 0;

          setDatas(res.data[0]);
        } else {
          setDatas(tmp);
        }

        setOrgDatas(res.data[0]);

        setCheckedInfo({ id: -1 });
        handleClose();
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='管理者削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='全国機関管理者削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <InstitutionAdminForm
            isDisabled={{
              prefCentral: true,
              userName: true,
              userDeptName: true,
              userPost: true,
              telephoneNumber: true,
              email: true,
              password: false,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userDeptName: props.checkedInfo.user_dept_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
            }}
            procType={PROC_TYPE.DELETE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='全国機関管理者を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
