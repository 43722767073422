import React from 'react';
import axios from 'axios';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { ChecklistControlContext } from 'views/pages/ChecklistControl';
import { convertJST, downloadExcel } from 'utils/functions';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC = () => {
  const classes = useStyles();

  const { selectedFiscalYear, pref_code, bank_code, data } = React.useContext(
    ChecklistControlContext
  );

  const handleDownload = async () => {
    const res = await axios.post(
      '/api/checklist-control/download',
      { fiscal_year: selectedFiscalYear, pref_code, bank_code },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <TableContainer component={Paper} style={{ width: '60%', margin: 'auto' }}>
      <Table
        className={classes.table}
        aria-label='customized table'
        size='small'
      >
        <TableHead style={{ height: 50 }}>
          <TableRow>
            <StyledTableCell style={{ paddingLeft: 50 }}>
              チェックリスト
            </StyledTableCell>
            <StyledTableCell align='center'>更新日</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <StyledTableRow>
            <StyledTableCell style={{ paddingLeft: 50, width: '50%' }}>
              <span
                className={classes.downloadable}
                onClick={() => {
                  handleDownload();
                }}
              >
                {`部署別チェックリスト.xlsx`}
              </span>
            </StyledTableCell>
            <StyledTableCell align='center'>
              {data[0]?.updated_at ? convertJST(data[0]?.updated_at) : '-'}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
