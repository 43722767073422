import { Box } from '@material-ui/core';
import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

import { useSetAtom } from 'jotai';

import UpdateDialog from 'views/components/dialogs/HiddenColumnMaster/UpdateDialog';
import DataTable from 'views/components/tables/HiddenColumnMaster/DataTable';
import { SelectControl } from 'views/molecules/SelectControl';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const HiddenColumnMasterContext = createContext(
  {} as {
    owner_user_type;
    pref_code;
    bank_code: string;
    fileRef: React.RefObject<HTMLInputElement>;
    datas;
    checkedInfo;
    categoryOption;
    setDatas;
    setOrgDatas;
    setCheckedInfo;
    setCategoryOption;
    currFiscalYear;
    fiscalYearOption;
    selectedFiscalYearOption;
    pivFiscalYear;
  }
);

const HiddenColumnMaster: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { user_type, bank_code, pref_code } = user;
  const fileRef = React.useRef<HTMLInputElement>(null);

  const [datas, setDatas] = useState([]);
  const [orgDatas, setOrgDatas] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({ category_code: -1 });
  const [categoryOption, setCategoryOption] = useState([{ key: 0, val: null }]);

  const [currFiscalYear, setCurrFiscalYear] = useState(0);

  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [selectedFiscalYearOption, setSelectedFiscalYearOption] = useState(0);

  const setOffset = useSetAtom(offsetAtom);

  const [pivFiscalYear, setPivFiscalYear] = useState(0);

  const handleChangeFiscalYear = (e) => {
    const fiscalYear = parseInt(e.target.value);

    setSelectedFiscalYearOption(fiscalYear);

    setDatas(
      orgDatas.filter((x) => {
        return x.fiscal_year === fiscalYear || fiscalYear === 0;
      })
    );

    setCheckedInfo({ category_code: -1 });

    setOffset(0);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/fiscal-year-option', {
        params: {
          pref_code,
          bank_code,
          user_type,
        },
      }),

      axios.get('/api/hidden-column', {
        params: {
          owner_user_type: user_type,
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/current-fiscal-year', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),
    ]).then((res) => {
      const fySmMap = new Map();

      Object.entries(res[3].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const fiscalYearMap = res[0].data.map((x) => {
        const key = `${x.fiscal_year}:${bank_code}`;
        return {
          key: x.fiscal_year,
          val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
        };
      });

      const currYear = fiscalYearMap[0].key;

      setCurrFiscalYear(currYear);
      setFiscalYearOption(fiscalYearMap);
      setSelectedFiscalYearOption(currYear);

      const all_datas = res[1].data;

      const selected_datas = all_datas.filter((x) => {
        return x.fiscal_year === currYear;
      });

      setDatas(selected_datas);
      setOrgDatas(all_datas);

      setPivFiscalYear(res[2].data.curr_fiscal_year);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            非表示列設定
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={bank_code ? { width: '320px' } : { width: '250px' }}
              value={selectedFiscalYearOption}
              options={fiscalYearOption}
              onChange={handleChangeFiscalYear}
            />
          </div>

          <HiddenColumnMasterContext.Provider
            value={{
              owner_user_type: user_type,
              pref_code,
              bank_code,
              fileRef,
              datas,
              checkedInfo,
              categoryOption,
              setDatas,
              setOrgDatas,
              setCheckedInfo,
              setCategoryOption,
              currFiscalYear,
              fiscalYearOption,
              selectedFiscalYearOption,
              pivFiscalYear,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <UpdateDialog />
            </Box>

            <DataTable />
          </HiddenColumnMasterContext.Provider>
        </main>
      }
    />
  );
};

export default HiddenColumnMaster;
