import React, { ChangeEvent, FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';

type Options = {
  key: string | number;
  val: string;
};

type Props = {
  id: string;
  label: string;
  className: string;
  defaultValue: number | string;
  errorMsg?: string;
  isDisabled?: boolean;
  options: Array<Options>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const SelectBoxField: FC<Props> = (props) => {
  const {
    id,
    label,
    className,
    defaultValue,
    errorMsg = '',
    isDisabled = false,
    options,
    onChange,
  } = props;

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <InputLabel>{label}</InputLabel>
      <Select
        native
        id={id}
        label={label}
        defaultValue={defaultValue}
        error={errorMsg.trim().length > 0}
        disabled={isDisabled}
        onChange={onChange}
      >
        {options
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          )
          .map((opt) => (
            <option key={opt.key} value={opt.key}>
              {opt.val}
            </option>
          ))}
      </Select>
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectBoxField;
