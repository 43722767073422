import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import reactStringReplace from 'react-string-replace';
import { downloadExcel } from 'utils/functions';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const ViewDialog: React.FC<{
  info_id;
  category_code;
  title;
  tmp_name1;
  tmp_name2;
  tmp_name3;
  naiyou;
  category;
}> = (props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [detailText, setDetailText] = useState('');

  useEffect(() => {
    const reptxtx = replaceStr(
      props.naiyou,
      props.tmp_name1,
      props.tmp_name2,
      props.tmp_name3,
      props.info_id
    );
    setDetailText(reptxtx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const replaceStr = (naiyou, tmp_name1, tmp_name2, tmp_name3, info_id) => {
    let reptxt = naiyou;
    reptxt = reactStringReplace(reptxt, '<file1>', () => {
      return (
        <a
          href='javascript:void(0);'
          onClick={() => handleDownload(info_id, 1)}
        >
          {tmp_name1}
        </a>
      );
    });
    reptxt = reactStringReplace(reptxt, '<file2>', () => {
      return (
        <a
          href='javascript:void(0);'
          onClick={() => handleDownload(info_id, 2)}
        >
          {tmp_name2}
        </a>
      );
    });
    reptxt = reactStringReplace(reptxt, '<file3>', () => {
      return (
        <a
          href='javascript:void(0);'
          onClick={() => handleDownload(info_id, 3)}
        >
          {tmp_name3}
        </a>
      );
    });
    reptxt = reactStringReplace(reptxt, /(https?:\/\/\S+)/g, (match, i) => {
      const htmlmatch = { match };
      let htmltxt = htmlmatch.match.replace('<link>', '');
      htmltxt = htmltxt.replace(/\r?\n/g, '');
      return (
        <a key={100 + i} target='_blank' rel='noreferrer' href={htmltxt}>
          {htmltxt}
        </a>
      );
    });
    return reptxt;
  };

  const handleDownload = async (id: number, tmpFileNo: number) => {
    const res = await axios.post(
      '/api/news_information/download/nw',
      { id, tmpFileNo },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <span onClick={handleOpen}>{props.title}</span>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle
          id='form-dialog-title'
          style={{
            textAlign: 'center',
            backgroundColor: '#7BD957',
            color: 'white',
            padding: 20,
          }}
        >
          <Typography variant='h5'>
            <CloseIcon
              style={{
                visibility: 'hidden',
                float: 'left',
                marginTop: '4px',
              }}
            />
            {props.title}
            <CloseIcon
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                float: 'right',
                marginTop: '4px',
              }}
            />
          </Typography>
        </DialogTitle>

        <Divider />

        <DialogContent
          style={{
            height: '100%',
            marginTop: -8,
            // marginBottom: 6,
            // border: 'solid 8px',
            // borderColor: '#7BD957',
          }}
        >
          <Grid container>
            <div
              style={{
                padding: '10px',
                width: '100%',
                whiteSpace: 'pre-wrap',
                fontSize: '15px',
                lineHeight: '30px',
                overflowWrap: 'break-word',
                height: '400px',
              }}
            >
              {detailText}
            </div>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ViewDialog;
