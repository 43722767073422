import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InspectionMonthMasterContext } from 'views/pages/InspectionMonthMaster';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    selectedFiscalYear,
    pref_code,
    bank_code,
    inspectionMonth,
    setInspectionMonth,
    startMonth,
  } = useContext(InspectionMonthMasterContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    console.log(inspectionMonth.get(2023));
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleCreate = () => {
    const checkedMap = new Map();

    for (let i = 1; i <= 12; i++) {
      let checked = '';
      for (let j = 1; j <= 5; j++) {
        checked += document.querySelector<HTMLInputElement>(`#chk-${i}-${j}`)
          .checked
          ? '1'
          : '0';
      }
      checkedMap.set(i, checked);
    }

    axios
      .post('/api/inspection-month/create', {
        fiscal_year: selectedFiscalYear,
        pref_code,
        bank_code,
        month_01: checkedMap.get(1),
        month_02: checkedMap.get(2),
        month_03: checkedMap.get(3),
        month_04: checkedMap.get(4),
        month_05: checkedMap.get(5),
        month_06: checkedMap.get(6),
        month_07: checkedMap.get(7),
        month_08: checkedMap.get(8),
        month_09: checkedMap.get(9),
        month_10: checkedMap.get(10),
        month_11: checkedMap.get(11),
        month_12: checkedMap.get(12),
      })
      .then((res) => {
        const tmpMapInspectionMonth = new Map();

        res.data.forEach((x) => {
          const tmp = [];
          console.log(x.fiscal_year);

          for (let i = 1; i <= 12; i++) {
            tmp.push(
              x['month_' + `0${i}`.slice(-2)].split('').map((x) => {
                return x === '1' ? true : false;
              })
            );
          }

          tmpMapInspectionMonth.set(x.fiscal_year, tmp);
        });

        setInspectionMonth(new Map(tmpMapInspectionMonth));

        handleClose();
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='検査対象月を設定する'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='検査対象月設定'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
            const style = {
              transform: 'scale(0.7)',
              marginBottom: 4,
              paddingLeft: 8,
              paddingTop: 0,
              paddingBottom: 0,
            };

            const tmpMonth = startMonth + x - 1;
            const month = tmpMonth > 12 ? tmpMonth - 12 : tmpMonth;

            return (
              <>
                <div
                  style={{
                    fontSize: 16,
                    paddingLeft: 16,
                    margin: 8,
                  }}
                  key={x}
                >
                  {month > 9 ? (
                    <>&nbsp;&nbsp;{month}月&nbsp;&nbsp;&nbsp;&nbsp;</>
                  ) : (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;{month}
                      月&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  <Checkbox
                    id={`chk-${x}-1`}
                    style={style}
                    color='primary'
                    defaultChecked={
                      inspectionMonth.get(selectedFiscalYear) &&
                      inspectionMonth.get(selectedFiscalYear)[x - 1][0]
                    }
                  />
                  年
                  <Checkbox
                    id={`chk-${x}-2`}
                    style={style}
                    color='primary'
                    defaultChecked={
                      inspectionMonth.get(selectedFiscalYear) &&
                      inspectionMonth.get(selectedFiscalYear)[x - 1][1]
                    }
                  />
                  半期
                  <Checkbox
                    id={`chk-${x}-3`}
                    style={style}
                    color='primary'
                    defaultChecked={
                      inspectionMonth.get(selectedFiscalYear) &&
                      inspectionMonth.get(selectedFiscalYear)[x - 1][2]
                    }
                  />
                  四半期
                  <Checkbox
                    id={`chk-${x}-4`}
                    style={style}
                    color='primary'
                    defaultChecked={
                      inspectionMonth.get(selectedFiscalYear) &&
                      inspectionMonth.get(selectedFiscalYear)[x - 1][3]
                    }
                  />
                  隔月
                  <Checkbox
                    id={`chk-${x}-5`}
                    style={style}
                    color='primary'
                    defaultChecked={
                      inspectionMonth.get(selectedFiscalYear) &&
                      inspectionMonth.get(selectedFiscalYear)[x - 1][4]
                    }
                  />
                  月
                </div>
                {x !== 12 ? <Divider /> : <></>}
              </>
            );
          })}
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='検査対象月を設定する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
