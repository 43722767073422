import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from 'views/pages/User';
import SelectBoxField from 'views/molecules/SelectBoxField';
import { UserInfoSubForm } from './subforms/UserInfoSubForm';
import DummyTextField from 'views/molecules/DummyTextField';
import { PROC_TYPE } from 'utils/constants';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
});

type Props = {
  isDisabled: {
    dept_lv1: boolean;
    dept_lv2: boolean;
    dept_lv3: boolean;
    dept_lv4: boolean;
    dept_lv5: boolean;
    userName: boolean;
    userPost: boolean;
    telephoneNumber: boolean;
    email: boolean;
    password: boolean;
  };
  defaultValues?: {
    userName?: string;
    userPost?: string;
    telephoneNumber?: string;
    email?: string;
    account_lock?: boolean;
  };
  isCheckboxHidden?: boolean;
  procType: number;
  setReleaseFlg?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    deptDatas,
    deptLv1,
    deptLv2,
    deptLv3,
    deptLv4,
    deptLv5,
    selectedDeptLv,
    selectedDeptLvOption,
    setDeptLv3,
    setDeptLv4,
    setDeptLv5,
    errorDeptLvs,
    errorDeptLv1,
    errorDeptLv2,
    errorDeptLv3,
    errorDeptLv4,
    errorDeptLv5,
    is_dept_lv2_invalid,
    checkboxToggles,
    errorDummySideTel,
    errorDummySideEmail,
    errorDummySidePass,
    setErrorDummySidePass,
    setErrorDeptLv5,
  } = React.useContext(UserContext);

  React.useEffect(() => {
    setErrorDummySidePass('');
    setErrorDeptLv5('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxToggles]);

  const handleChangeLv2 = (e) => {
    const selectedDeptCodeLv = parseInt(e.target.value);

    switch (selectedDeptLv) {
      case 3: {
        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 3 &&
                x.dept_code_lv2 === selectedDeptCodeLv
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );
        break;
      }

      case 4: {
        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 4 &&
                x.dept_code_lv2 === selectedDeptCodeLv
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        const target = deptDatas
          .filter((x) => {
            return (
              x.department_level_code === 4 &&
              x.dept_code_lv2 === selectedDeptCodeLv
            );
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];

        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 4 && x.dept_code_lv3 === target
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );

        break;
      }
      case 5: {
        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 &&
                x.dept_code_lv2 === selectedDeptCodeLv
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        const target = deptDatas
          .filter((x) => {
            return (
              x.department_level_code === 5 &&
              x.dept_code_lv2 === selectedDeptCodeLv
            );
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];

        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 && x.dept_code_lv3 === target
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );

        const target2 = deptDatas
          .filter((x) => {
            return (
              x.department_level_code === 5 &&
              x.dept_code_lv3 === selectedDeptCodeLv
            );
          })
          .map((x) => {
            return x.dept_code_lv4;
          })[0];

        setDeptLv5(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 && x.dept_code_lv4 === target2
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv5,
                val: x.dept_name_lv5,
              };
            })
        );
        break;
      }
    }
  };

  const handleChangeLv3 = (e) => {
    const selectedDeptCodeLv = parseInt(e.target.value);

    switch (selectedDeptLv) {
      case 4: {
        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 4 &&
                x.dept_code_lv3 === selectedDeptCodeLv
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );
        break;
      }

      case 5: {
        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 &&
                x.dept_code_lv3 === selectedDeptCodeLv
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );

        const target2 = deptDatas
          .filter((x) => {
            return (
              x.department_level_code === 5 &&
              x.dept_code_lv3 === selectedDeptCodeLv
            );
          })
          .map((x) => {
            return x.dept_code_lv4;
          })[0];

        setDeptLv5(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 && x.dept_code_lv4 === target2
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv5,
                val: x.dept_name_lv5,
              };
            })
        );
        break;
      }
    }
  };

  const handleChangeLv4 = (e) => {
    const target = parseInt(e.target.value);

    setDeptLv5(
      deptDatas
        .filter((x) => {
          return x.department_level_code === 5 && x.dept_code_lv4 === target;
        })
        .map((x) => {
          return {
            key: x.dept_code_lv5,
            val: x.dept_name_lv5,
          };
        })
    );
  };

  const handleClickCheckbox = (e) => {
    props.setReleaseFlg(e.target.checked);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectBoxField
            id='selected-Lv'
            label='階層レベルを選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorDeptLvs}
            options={selectedDeptLvOption}
            isDisabled={true}
          />
          {selectedDeptLv === 1 && (
              <SelectBoxField
                id='dept-lv1'
                label='階層レベルⅠの部署を選択'
                className={classes.inputField}
                defaultValue={''}
                errorMsg={errorDeptLv1}
                options={deptLv1}
                isDisabled={props.isDisabled.dept_lv1}
              />
          )}
          {selectedDeptLv >= 2 && !is_dept_lv2_invalid && (
              <SelectBoxField
                id='dept-lv2'
                label='階層レベルⅡの部署を選択'
                className={classes.inputField}
                defaultValue={''}
                errorMsg={errorDeptLv2}
                options={deptLv2}
                onChange={handleChangeLv2}
                isDisabled={props.isDisabled.dept_lv2}
              />
          )}
          {selectedDeptLv >= 3 && (
              <SelectBoxField
                id='dept-lv3'
                label='階層レベルⅢの部署を選択'
                className={classes.inputField}
                defaultValue={''}
                errorMsg={errorDeptLv3}
                options={deptLv3}
                onChange={handleChangeLv3}
                isDisabled={props.isDisabled.dept_lv3}
              />
          )}
          {selectedDeptLv >= 4 && (
              <SelectBoxField
                id='dept-lv4'
                label='階層レベルⅣの部署を選択'
                className={classes.inputField}
                defaultValue={''}
                errorMsg={errorDeptLv4}
                options={deptLv4}
                onChange={handleChangeLv4}
                isDisabled={props.isDisabled.dept_lv4}
              />
          )}
          {selectedDeptLv >= 5 && (
            <SelectBoxField
              id='dept-lv5'
              label='階層レベルⅤの部署を選択'
              className={classes.inputField}
              defaultValue={''}
              errorMsg={errorDeptLv5}
              options={deptLv5}
              isDisabled={props.isDisabled.dept_lv5}
            />
          )}
          {props.procType === PROC_TYPE.UPDATE &&
            props.defaultValues.account_lock && (
              <>
                {selectedDeptLv <= 2 && (
                  <DummyTextField
                    errorMsg={errorDummySideTel}
                    className={classes.inputField}
                  />
                )}
                {selectedDeptLv <= 3 && (
                  <DummyTextField
                    errorMsg={errorDummySideEmail}
                    className={classes.inputField}
                  />
                )}
                {checkboxToggles && selectedDeptLv <= 4 && (
                  <DummyTextField
                    errorMsg={errorDummySidePass}
                    className={classes.inputField}
                  />
                )}
                <FormControlLabel
                  id='release-lock'
                  control={<Checkbox color='primary' />}
                  label='アカウントロックを解除する'
                  onClick={handleClickCheckbox}
                />
              </>
            )}
        </Grid>
        <Grid item xs={6}>
          <UserInfoSubForm
            defaultValues={props.defaultValues}
            isDisabled={props.isDisabled}
            procType={props.procType}
            context={UserContext}
          />
        </Grid>
      </Grid>
    </>
  );
};
