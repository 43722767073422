import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CHECKLIST_KBN_OPT, PROC_TYPE, USER_TYPE } from 'utils/constants';
import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { InspectionSubCategoryForm } from 'views/components/forms/InspectionSubCategoryForm';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

import { useSetAtom } from 'jotai';
import {
  errorFileNameAtom,
  errorChecklistNameAtom,
  errorFileUuidAtom,
} from 'jotai/atoms';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    user_type,
    owner_user_type,
    pref_code,
    bank_code,
    defaultValues,
    setErrorMsgs,
    eventHandlers,
    selectedFiscalYear,
    setSelectedFiscalYear,
    setData,
    setOrgData,
    setCheckedInfo,
    regOrgCategory,
    setRegCategory,
    setSelectedCategory,
    setSearchOptions,
    approvedInfo,
    pivFiscalYear,
  } = React.useContext(InspectionSubCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const setErrorFileName = useSetAtom(errorFileNameAtom);
  const setErrorChecklistName = useSetAtom(errorChecklistNameAtom);
  const setErrorFileUuid = useSetAtom(errorFileUuidAtom);

  const handleOpen = () => {
    eventHandlers.setCategory(defaultValues.category);

    setIsDialogOpen(true);

    setRegCategory(
      regOrgCategory
        .filter((x) => {
          return x.fiscal_year === selectedFiscalYear;
        })
        .map((x) => {
          return {
            key: x.category_code,
            val:
              x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                ? x.category_name.substring(2)
                : x.category_name,
          };
        })
    );
  };

  const handleClose = () => {
    setErrorMsgs.subcategoryName('');
    setErrorMsgs.comment('');
    setErrorMsgs.checklist('');

    setErrorFileName('');
    setErrorChecklistName('');
    setErrorFileUuid('');

    setIsDialogOpen(false);
  };

  const handleCreate = () => {
    const formData = new FormData();

    formData.append('fiscal_year', selectedFiscalYear);
    formData.append('owner_user_type', owner_user_type);
    formData.append('pref_code', pref_code);
    formData.append('bank_code', bank_code);
    const categoryCode = document.querySelector<HTMLSelectElement>(
      '#inspection-category'
    );
    formData.append('category_code', categoryCode.value);
    formData.append(
      'category_name',
      categoryCode.options[categoryCode.selectedIndex].text
    );
    formData.append(
      'subcategory_name',
      document.querySelector<HTMLInputElement>('#inspection-subcategory-name')
        .value
    );
    formData.append(
      'comment',
      document.querySelector<HTMLInputElement>('#comment').value
    );
    formData.append('checklist', defaultValues.checklistRef.current.files[0]);

    axios
      .post('/api/inspection-subcategory/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const tmpMap = new Map();

        const approvedMap = new Map();

        approvedInfo
          .filter((x) => {
            return x.pref_code === pref_code;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
            approvedMap.set(key, 'dummy');
          });

        res.data
          .filter((x) => {
            return x.fiscal_year == selectedFiscalYear;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

            switch (parseInt(owner_user_type)) {
              case USER_TYPE.SYSTEM_ADMIN:
                if (!x.pref_code && !x.bank_code) {
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.PREF_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code)
                ) {
                  if (approvedMap.get(key)) {
                    x['check_status'] = 1;
                  } else {
                    x['check_status'] = 0;
                  }
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.JA_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && x.bank_code === bank_code)
                ) {
                  if (
                    x.owner_user_type === USER_TYPE.JA_ADMIN ||
                    approvedMap.get(key)
                  ) {
                    tmpMap.set(key, x);
                  }
                }
                break;
            }
          });

        const tmpArray = Array.from(tmpMap.values());
        setData(tmpArray);
        setOrgData(res.data);

        const opt = tmpArray
          .map((x) => {
            return {
              key: x.category_code,
              val:
                x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                  ? x.category_name.substring(2)
                  : x.category_name,
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          );

        setSearchOptions([...CHECKLIST_KBN_OPT, ...opt]);

        setSelectedFiscalYear(selectedFiscalYear);
        setSelectedCategory(0);

        setCheckedInfo({ id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.subcategory_name
          ? setErrorMsgs.subcategoryName(errors.subcategory_name.errorMsg)
          : setErrorMsgs.subcategoryName('');

        errors.comment
          ? setErrorMsgs.comment(errors.comment.errorMsg)
          : setErrorMsgs.comment('');

        errors.checklist
          ? setErrorMsgs.checklist(errors.checklist.errorMsg)
          : setErrorMsgs.checklist('');

        if (errors.file) {
          setErrorFileName('※ 以下のリンクからエラー内容をご確認ください。');

          setErrorChecklistName(
            `【エラー】${
              categoryCode.options[categoryCode.selectedIndex].text
            }_${
              document.querySelector<HTMLInputElement>(
                '#inspection-subcategory-name'
              ).value
            }.xlsx`
          );

          setErrorFileUuid(err.response.data.uuid);
        } else {
          setErrorFileName('');
          setErrorChecklistName('');
          setErrorFileUuid('');
        }
      });
  };

  const hdrStr =
    user_type === USER_TYPE.SYSTEM_ADMIN
      ? 'チェックリスト登録'
      : '独自チェックリスト登録';

  const btnStr =
    user_type === USER_TYPE.SYSTEM_ADMIN
      ? 'チェックリストを登録する'
      : '独自チェックリストを登録する';

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel={hdrStr}
        onClickActionHandler={handleOpen}
        isDisabled={selectedFiscalYear < pivFiscalYear}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName={hdrStr}
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <InspectionSubCategoryForm
            isDisabled={{ category: false }}
            isHidden={{
              checklist: false,
              checkbox: true,
            }}
            procType={PROC_TYPE.CREATE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel={btnStr}
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
