import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Divider, DialogContent } from '@material-ui/core';

import { ManualMasterContext } from 'views/pages/ManualMaster';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import SelectBoxField from 'views/molecules/SelectBoxField';
import InputUploadField from 'views/molecules/InputUploadField';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';
import axios from 'axios';
import { getHIEById } from 'utils/functions';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{ isDisabled: boolean }> = (props) => {
  const classes = useStyles();

  const {
    fileRef,
    setDatas,
    setOrgDatas,
    selectedCategory,
    checkedInfo,
    errorFile,
    setErrorFile,
    setSelectedFileName,
  } = React.useContext(ManualMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    setErrorFile('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = () => {
    const formData = new FormData();

    formData.append('category_id', checkedInfo.category_id);
    formData.append('manual_id', checkedInfo.manual_id);
    formData.append('file', fileRef.current.files[0]);

    setSelectedFileName(getHIEById('#file').value);

    axios
      .post('/api/manual/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setOrgDatas(res.data);

        let filtedData = res.data;
        if (selectedCategory !== 0) {
          filtedData = filtedData.filter((x) => {
            return x.category_id === selectedCategory;
          });
        }
        setDatas(filtedData);

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.file ? setErrorFile(errors.file.errorMsg) : setErrorFile('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='マニュアル・資料更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitleUpdate
          titleName='マニュアル・資料更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <SelectBoxField
            id='category'
            label='区分'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: checkedInfo.category_id,
                val: checkedInfo.category_name,
              },
            ]}
            isDisabled={true}
          />
          <InputUploadField
            id='file'
            label='マニュアル・資料を選択'
            className={classes.inputField}
            defaultValue=''
            errorMsg={errorFile}
            checklistRef={fileRef}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='マニュアル・資料を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
