import React from 'react';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { ChecklistUploadContext } from 'views/pages/ChecklistUpload';
import { DialogButtonUpload } from 'views/atoms/buttons/dialogs/Upload';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import InputUploadField from 'views/molecules/InputUploadField';
import SelectBoxField from 'views/molecules/SelectBoxField';

import { downloadExcel, getHSEById } from 'utils/functions';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
  downloadable: {
    color: '#0033CC',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

const CreateDialog: React.FC<{ isDisabled }> = () => {
  const classes = useStyles();

  const {
    fiscal_year,
    pref_code,
    bank_code,
    department_level_code,
    dept_code_lv2,
    dept_code_lv3,
    dept_code_lv4,
    dept_code_lv5,
    fileRef,
    errorFile,
    setErrorFile,
    errorChecklistName,
    setErrorChecklistName,
    checkedInfo,
    setCheckedInfo,
    setData,
    setOrgData,
    mapReturnedComment,
    setMapReturnedComment,
    mapVerificationHist,
    setMapVerificationHist,
    selectedCategory,
    selectedSubcategory,
    mapInspectionMonth2,
    mapPrio,
  } = React.useContext(ChecklistUploadContext);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [uuid, setUuid] = React.useState('');

  const year = parseInt(getHSEById('#fiscal_year')?.value);

  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setErrorFile('');
    setErrorChecklistName('');
  };

  const handleDownload = async () => {
    const {
      owner_user_type,
      category_code,
      category_name,
      subcategory_code,
      subcategory_name,
    } = checkedInfo;

    const res = await axios.post(
      '/api/checklist-upload/error',
      {
        uuid,
        owner_user_type,
        pref_code,
        bank_code,
        inspectionMonthVal: checkedInfo.inspectionMonthVal,
        department_level_code,
        dept_code_lv2,
        dept_code_lv3,
        dept_code_lv4,
        dept_code_lv5,
        category_code,
        category_name,
        subcategory_code,
        subcategory_name,
      },
      {
        responseType: 'blob',
      }
    );

    downloadExcel(res);
  };

  const handleUpload = () => {
    setBtnDisabled(true);

    const formData = new FormData();

    formData.append('fiscal_year', fiscal_year.toString());
    formData.append('owner_user_type', checkedInfo.owner_user_type);
    formData.append('inspection_month', checkedInfo.inspectionMonthKey);
    formData.append('inspectionMonthVal', checkedInfo.inspectionMonthVal);
    formData.append('pref_code', pref_code);
    formData.append('bank_code', bank_code);
    formData.append('department_level_code', department_level_code.toString());
    formData.append('dept_code_lv2', dept_code_lv2?.toString());
    formData.append('dept_code_lv3', dept_code_lv3?.toString());
    formData.append('dept_code_lv4', dept_code_lv4?.toString());
    formData.append('dept_code_lv5', dept_code_lv5?.toString());
    formData.append('category_code', checkedInfo.category_code);
    formData.append('subcategory_code', checkedInfo.subcategory_code);
    formData.append('file', fileRef.current.files[0]);

    axios
      .post('/api/checklist-upload/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        let filtedData = res.data.filter((x) => {
          return (
            x.fiscal_year === parseInt(getHSEById('#fiscal_year').value) &&
            x.inspection_month == checkedInfo.inspectionMonthKey
          );
        });

        if (selectedCategory !== 0) {
          filtedData = filtedData.filter((x) => {
            return x.category_code === selectedCategory;
          });
        }

        if (selectedSubcategory !== 0) {
          filtedData = filtedData.filter((x) => {
            return x.subcategory_code === selectedSubcategory;
          });
        }

        const tmp4 = filtedData.filter((x) => {
          const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

          let tmpAry = [0, 0, 0, 0, 0];

          if (mapInspectionMonth2.get(fiscal_year)) {
            tmpAry =
              mapInspectionMonth2.get(fiscal_year)[
                parseInt(x.inspection_month) - 1
              ];
          }

          const excludePrio = [
            mapPrio.get(key) ? mapPrio.get(key)[0] : 0,
            mapPrio.get(key) ? mapPrio.get(key)[1] : 0,
            mapPrio.get(key) ? mapPrio.get(key)[2] : 0,
            mapPrio.get(key) ? mapPrio.get(key)[3] : 0,
            mapPrio.get(key) ? mapPrio.get(key)[4] : 0,
          ];

          if (
            (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
            (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
            (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
            (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
            (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
          ) {
            return x;
          }
        });

        setData(tmp4);
        setOrgData(res.data);

        const key = [
          fiscal_year,
          pref_code,
          bank_code,
          checkedInfo.inspectionMonthKey,
          checkedInfo.category_code,
          checkedInfo.subcategory_code,
        ].join(':');

        mapReturnedComment.delete(key);
        mapVerificationHist.delete(key);

        setMapReturnedComment(mapReturnedComment);
        setMapVerificationHist(mapVerificationHist);

        const orgCheckedInfo = checkedInfo;
        setCheckedInfo({ id: -1 });
        setCheckedInfo(orgCheckedInfo);

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        if (errors) {
          errors.file ? setErrorFile(errors.file.errorMsg) : setErrorFile('');
        } else {
          setErrorFile('※ 以下のリンクからエラー内容をご確認ください。');

          setErrorChecklistName(
            `【エラー】${checkedInfo.inspectionMonthVal}_${checkedInfo.category_name}_${checkedInfo.subcategory_name}.xlsx`
          );

          setUuid(err.response.data.uuid);
        }
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  return (
    <Box component='span' style={{}}>
      <DialogButtonUpload
        buttonLabel='検査結果登録'
        onClickActionHandler={handleOpen}
        isDisabled={checkedInfo.key === -1}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='検査結果登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <SelectBoxField
            id='year'
            label='検査年度'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: year,
                val: year + ' 年度',
              },
            ]}
            isDisabled={true}
          />

          <SelectBoxField
            id='inspectionMonth'
            label='対象月'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: checkedInfo.inspectionMonthKey,
                val: checkedInfo.inspectionMonthVal,
              },
            ]}
            isDisabled={true}
          />
          <SelectBoxField
            id='category'
            label='チェックリスト区分'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: checkedInfo.category_code,
                val: checkedInfo.category_name,
              },
            ]}
            isDisabled={true}
          />
          <SelectBoxField
            id='subcategory'
            label='チェックリスト名'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: checkedInfo.subcategory_code,
                val: checkedInfo.subcategory_name,
              },
            ]}
            isDisabled={true}
          />
          <InputUploadField
            id='file'
            label='チェックリストを選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorFile}
            checklistRef={fileRef}
          />
          {errorChecklistName && (
            <span style={{ marginLeft: 20, fontSize: 12 }}>
              <a className={classes.downloadable} onClick={handleDownload}>
                {errorChecklistName}
              </a>
            </span>
          )}
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='検査結果を登録する'
          onClickActionHandler={handleUpload}
          disabled={btnDisabled}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
