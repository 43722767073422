import { useEffect, useState } from 'react';
import { selectOptionType } from 'types/common';
import { user } from 'types/user';

export const useDeptLv2 = (
  data: user[],
  selectedDeptLv: number
): [
  selectOptionType[],
  React.Dispatch<React.SetStateAction<selectOptionType>[]>
] => {
  const [deptLv2, setDeptLv2] = useState([]);

  useEffect(() => {
    let wk = null;

    switch (selectedDeptLv) {
      case 3: {
        wk = data.filter((x) => {
          return x.department_level_code === 2 && x.dept_code_lv2;
        });

        break;
      }

      case 4: {
        wk = data.filter((x) => {
          return x.department_level_code === 3 && x.dept_code_lv3;
        });

        break;
      }

      case 5: {
        wk = data.filter((x) => {
          return x.department_level_code === 4 && x.dept_code_lv4;
        });

        break;
      }
    }

    setDeptLv2(
      wk
        ?.map((x) => {
          return {
            key: x.dept_code_lv2,
            val: x.dept_name_lv2,
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        )
    );
  }, [data, selectedDeptLv]);

  return [deptLv2, setDeptLv2];
};
