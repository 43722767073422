import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE } from 'utils/constants';
import { ManualMasterForm } from 'views/components/forms/ManualMasterForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';
import { ManualMasterContext } from 'views/pages/ManualMaster';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{ isDisabled: boolean }> = (props) => {
  const classes = useStyles();

  const {
    setDatas,
    setOrgDatas,
    setSelectedCategory,
    checkedInfo,
    setCheckedInfo,
  } = useContext(ManualMasterContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    axios
      .post('/api/manual/delete', {
        category_id: checkedInfo.category_id,
        manual_id: checkedInfo.manual_id,
      })
      .then((res) => {
        const categMap = new Map();

        res.data.map((x) => {
          categMap.set(x.category_id, {
            key: x.category_id,
            val: x.category_name,
          });
        });

        setDatas(
          res.data.filter((x) => {
            return x.category_id === checkedInfo.category_id;
          })
        );

        setOrgDatas(res.data);

        setSelectedCategory(checkedInfo.category_id);

        setCheckedInfo({ manual_id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        console.log('errors', errors);
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='マニュアル・資料削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='マニュアル・資料削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <ManualMasterForm
            isDisabled={{
              categorys: true,
            }}
            procType={PROC_TYPE.DELETE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='マニュアル・資料を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
