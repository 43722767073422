import React, { useContext, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import LoopIcon from '@material-ui/icons/Loop';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';

import { FiscalYearMasterContext } from 'views/pages/FiscalYearMaster';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';
import { USER_TYPE } from 'utils/constants';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

export const UpdateDialog: React.FC = () => {
  const classes = useStyles();

  const { pref_code, bank_code, fiscal_year, user_type, publish_phase } =
    useContext(FiscalYearMasterContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [publishDialogOpen, setPublishDialog] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleTransferDialogOpen = () => {
    setTransferDialogOpen(true);
  };

  const handleTransferDialogClose = () => {
    setTransferDialogOpen(false);
  };

  const handlePublishDialogOpen = () => {
    setPublishDialog(true);
  };

  const handlePublishDialogClose = () => {
    setPublishDialog(false);
  };

  const handleUpdate = () => {
    //add 2022/04/27
    const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
    const userType = user.user_type;
    //add 2022/04/27
    axios
      .post('/api/fiscal-year-master/update', {
        pref_code,
        bank_code,
        fiscal_year,
        userType, //add 2022/4/27
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        //
      });
  };

  const handleTransfer = () => {
    axios
      .post('/api/fiscal-year-master/transfer', {
        fiscal_year,
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        //
      });
  };

  const handlePublish = () => {
    axios
      .post('/api/fiscal-year-master/publish', {
        fiscal_year,
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        //
      });
  };

  return (
    <Box component='span' style={{}}>
      {user_type === USER_TYPE.SYSTEM_ADMIN ? (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            variant='outlined'
            startIcon={<LoopIcon />}
            color='primary'
            style={{ marginBottom: '10px' }}
            onClick={handleTransferDialogOpen}
            disabled={publish_phase === 1}
          >
            チェックリスト区分・チェックリスト・非表示列設定を移行する
          </Button>

          <Button
            variant='outlined'
            startIcon={<LockOpenIcon />}
            color='primary'
            onClick={handlePublishDialogOpen}
            disabled={publish_phase !== 1}
          >
            チェックリスト区分・チェックリスト・非表示列設定を公開する
          </Button>
        </div>
      ) : (
        <Button
          variant='outlined'
          startIcon={<UpdateIcon />}
          color='primary'
          onClick={handleOpen}
        >
          検査年度を更新する
        </Button>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={handleOpen}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='検査年度更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <div
            style={{
              padding: 25,
              textAlign: 'center',
            }}
          >
            <Typography variant='h5'>
              <span
                style={{
                  border: '1px solid gray',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {fiscal_year}&thinsp;年度
              </span>

              <span
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                ⇒
              </span>

              <span
                style={{
                  border: '1px solid gray',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {fiscal_year + 1}&thinsp;年度
              </span>
            </Typography>
          </div>

          <span
            style={{
              color: 'red',
              display: 'block',
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            ※ 更新後は年度を戻すことができません。ご注意ください。
          </span>
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='検査年度を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>

      <Dialog
        open={transferDialogOpen}
        onClose={handleTransferDialogClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='チェックリスト区分・チェックリスト・非表示列設定移行'
          onClickActionHandler={handleTransferDialogClose}
        />

        <Divider />

        <DialogContent>
          <div
            style={{
              padding: 25,
              textAlign: 'center',
            }}
          >
            <Typography variant='h5'>
              <span
                style={{
                  border: '1px solid gray',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {fiscal_year}&thinsp;年度
              </span>

              <span
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                ⇒
              </span>

              <span
                style={{
                  border: '1px solid gray',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {fiscal_year + 1}&thinsp;年度
              </span>
            </Typography>
          </div>
          <span
            style={{
              color: 'red',
              display: 'block',
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            ※
            チェックリスト区分・チェックリスト・非表示列設定を翌年度に移行します。移行処理は戻すことができませんので、ご注意ください。
          </span>
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='チェックリスト区分・チェックリスト・非表示列設定を移行する'
          onClickActionHandler={handleTransfer}
        />
      </Dialog>

      <Dialog
        open={publishDialogOpen}
        onClose={handlePublishDialogClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='チェックリスト区分・チェックリスト・非表示列設定公開'
          onClickActionHandler={handlePublishDialogClose}
        />

        <Divider />

        <DialogContent>
          <div
            style={{
              padding: 25,
              textAlign: 'center',
            }}
          >
            <Typography variant='h5'>
              <span
                style={{
                  border: '1px solid gray',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {fiscal_year}&thinsp;年度
              </span>
            </Typography>
          </div>

          <span
            style={{
              color: 'red',
              display: 'block',
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            ※
            チェックリスト区分・チェックリスト・非表示列設定を公開します。公開処理は戻すことができませんので、ご注意ください。
          </span>
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='チェックリスト区分・チェックリスト・非表示列設定を公開する'
          onClickActionHandler={handlePublish}
        />
      </Dialog>
    </Box>
  );
};
