import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@material-ui/core';

import CreateDialog from 'views/components/dialogs/InspectionMonthMaster/CreateDialog';
import { SelectControl } from 'views/molecules/SelectControl';

import { TplPage } from 'views/templates/TplPage';

export const InspectionMonthMasterContext = createContext(
  {} as {
    selectedFiscalYear;
    pref_code: string;
    bank_code: string;
    inspectionMonth;
    setInspectionMonth;
    startMonth: number;
  }
);

const InspectionMonthMaster: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const { pref_code, bank_code, owner_user_type } = user;

  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);

  const [mapStartMonth, setMapStartMonth] = React.useState(new Map());

  const [startMonth, setStartMonth] = useState(0);
  const [inspectionMonth, setInspectionMonth] = useState(new Map());

  const handleChangeFiscalYear = (e) => {
    const targetYear = parseInt(e.target.value);
    setSelectedFiscalYear(targetYear);
    setStartMonth(mapStartMonth.get(targetYear));
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/fiscal-year-option-temp', {
        params: {
          pref_code,
          bank_code,
          owner_user_type,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),

      axios.get('/api/ja-master/start-month', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/inspection-month', {
        params: {
          pref_code,
          bank_code,
        },
      }),
    ]).then((res) => {
      const fySmMap = new Map();

      Object.entries(res[1].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const fiscalYearAry = res[0].data.map((x) => {
        const key = `${x.fiscal_year}:${bank_code}`;
        return {
          key: x.fiscal_year,
          val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
        };
      });

      const curr_year = fiscalYearAry[0].key;

      const tmpMapStartMonth = new Map();

      res[2].data.forEach((x) => {
        tmpMapStartMonth.set(x.fiscal_year, x.start_month);
      });

      setMapStartMonth(new Map(tmpMapStartMonth));
      setStartMonth(tmpMapStartMonth.get(curr_year));

      setFiscalYearOption(fiscalYearAry);
      setSelectedFiscalYear(curr_year);

      const tmpMapInspectionMonth = new Map();

      res[3].data.forEach((x) => {
        const tmp = [];

        for (let i = 1; i <= 12; i++) {
          tmp.push(
            x['month_' + `0${i}`.slice(-2)].split('').map((x) => {
              return x === '1' ? true : false;
            })
          );
        }

        tmpMapInspectionMonth.set(x.fiscal_year, tmp);
      });

      setInspectionMonth(new Map(tmpMapInspectionMonth));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            検査対象月マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={{ width: '320px', marginRight: 8 }}
              value={selectedFiscalYear}
              options={fiscalYearOption}
              onChange={handleChangeFiscalYear}
            />
          </div>

          <InspectionMonthMasterContext.Provider
            value={{
              selectedFiscalYear,
              pref_code,
              bank_code,
              inspectionMonth,
              setInspectionMonth,
              startMonth,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />
            </Box>
          </InspectionMonthMasterContext.Provider>
        </main>
      }
    />
  );
};

export default InspectionMonthMaster;
