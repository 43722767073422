/* eslint-disable */
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TOKEN_KEY } from 'utils/constants';

class PrivateRoute extends Component {
  render() {
    let { component: Component, ...rest } = this.props;

    if (sessionStorage.getItem(TOKEN_KEY)) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      return <Redirect to='/login' />;
    }
  }
}
export default PrivateRoute;
