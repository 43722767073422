// &thinsp; => 8201
const SPACING = String.fromCharCode(8201);

const SYSTEM_NAME = `JA${SPACING}内部統制点検システム`;
const SYSTEM_SUB_NAME = `JICS（JA${SPACING}Internal${SPACING}Control${SPACING}inspection${SPACING}support${SPACING}System）`;

const COPYRIGHT = `Copyright${SPACING}©${SPACING}2022${SPACING}一般社団法人${SPACING}農協流通研究所`;

// ユーザ種別
const USER_TYPE = {
  // システム管理者
  SYSTEM_ADMIN: 1,
  // 全国機関
  ALL_PREF_ADMIN: 2,
  // 都道府県中央会
  PREF_ADMIN: 3,
  // JA管理者
  JA_ADMIN: 4,
  // JAユーザ
  USER: 5,
} as const;

// 部署コード
const DEPT_CODE = {
  JA_LV1: 1,
  JA_LV2: 2,
  JA_LV3: 3,
  JA_LV4: 4,
  JA_LV5: 5,
} as const;

export const PROC_TYPE = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
};

// 適正 一部不備 不備 対象外
export const SANKAKU = '△';
export const BATSU = '×';
export const HAIFU = '－';
export const MARUZEN = '〇';
export const MARUZEN2 = '◎';

// ページネーションサイズ
export const PAR_PAGE = 10;

//TODO localStorageでいいのか？TOKENはわかりやすくていいのか？
// 20220112 rikyo sessionStorageで設定する
export const TOKEN_KEY = 'NRK-JICS-TOKEN';

export { SYSTEM_NAME, SYSTEM_SUB_NAME, COPYRIGHT, USER_TYPE, DEPT_CODE };

export const CHECKLIST_KBN_OPT = [{ key: 0, val: '全区分' }];
export const CHECKLIST_NAME_OPT = [{ key: 0, val: '全チェックリスト' }];
export const NO_OPT = [{ key: 0, val: '―' }];

export const SELECTBOX_DISPLAY_CONTROL = {
  1: { jan: true, mth: false, clt: false, dpt: false },
  2: { jan: true, mth: true, clt: false, dpt: false },
  3: { jan: true, mth: true, clt: false, dpt: false },
  4: { jan: true, mth: true, clt: false, dpt: true },
  5: { jan: true, mth: true, clt: false, dpt: true },
  6: { jan: true, mth: true, clt: true, dpt: true },
  7: { jan: true, mth: true, clt: false, dpt: true },
  8: { jan: true, mth: true, clt: false, dpt: true },
  9: { jan: true, mth: true, clt: true, dpt: true },
  10: { jan: true, mth: true, clt: false, dpt: true },
  11: { jan: true, mth: true, clt: false, dpt: true },
  12: { jan: true, mth: true, clt: true, dpt: true },
  13: { jan: true, mth: true, clt: false, dpt: true },
  14: { jan: true, mth: true, clt: false, dpt: true },
  15: { jan: true, mth: true, clt: false, dpt: true },
  16: { jan: true, mth: true, clt: true, dpt: true },
  17: { jan: false, mth: true, clt: true, dpt: false },
  18: { jan: false, mth: true, clt: false, dpt: false },
};

export const API_ENDPOINT = [
  'dummy',
  '/api/report-type-1/download',
  '/api/report-type-2/download',
  '/api/report-type-3-add/download',
  '/api/report-type-3-9/download',
  '/api/report-type-4-10/download',
  '/api/report-type-5-11/download',
  '/api/report-type-6-12/download',
  '/api/report-type-7-13/download',
  '/api/report-type-8/download',
  '/api/report-type-3-9/download',
  '/api/report-type-4-10/download',
  '/api/report-type-5-11/download',
  '/api/report-type-6-12/download',
  '/api/report-type-7-13/download',
  '/api/report-type-14/download',
  '/api/report-type-15/download',
  '/api/report-type-17/download',
  '/api/report-type-18/download',
];
