import { useEffect, useState } from 'react';
import { selectOptionType } from 'types/common';
import { user } from 'types/user';

export const useDeptLv4 = (
  data: user[],
  selectedDeptLv: number
): [
  selectOptionType[],
  React.Dispatch<React.SetStateAction<selectOptionType>[]>
] => {
  const [deptLv4, setDeptLv4] = useState([]);

  useEffect(() => {
    let targetLv2;
    let targetLv3;

    switch (selectedDeptLv) {
      case 5: {
        targetLv2 = data
          .filter((x) => {
            return x.department_level_code === 4 && x.dept_code_lv4;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        targetLv3 = data
          .filter((x) => {
            return x.department_level_code === 4 && x.dept_code_lv4;
          })
          .filter((x) => {
            return (
              x.department_level_code === 4 && x.dept_code_lv2 === targetLv2
            );
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];
      }
    }

    setDeptLv4(
      data
        .filter((x) => {
          return (
            x.department_level_code === 4 &&
            x.dept_code_lv2 === targetLv2 &&
            x.dept_code_lv3 === targetLv3
          );
        })
        .map((x) => {
          return {
            key: x.dept_code_lv4,
            val: x.dept_name_lv4,
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        )
    );
  }, [data, selectedDeptLv]);

  return [deptLv4, setDeptLv4];
};
