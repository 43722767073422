import React from 'react';
import { FormControl, FormHelperText, OutlinedInput } from '@material-ui/core';

type Props = {
  className?: string;
  errorMsg?: string;
};

const DummyTextField: React.FC<Props> = (props) => {
  const { className, errorMsg = '' } = props;

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <OutlinedInput
        style={{ visibility: 'hidden' }}
        error={errorMsg.trim().length > 0}
      />
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};

export default DummyTextField;
