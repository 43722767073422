import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getHSEById } from 'utils/functions';
import { useDeptLv2 } from 'hooks/useDeptLv2';
import { useDeptLv3 } from 'hooks/useDeptLv3';
import { useDeptLv4 } from 'hooks/useDeptLv4';
import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import InputTextField from 'views/molecules/InputTextField';
import SelectBoxField from 'views/molecules/SelectBoxField';

const useStyles = makeStyles({
  inputField: {
    marginTop: 15,
  },
});

type Props = {
  isDisabled?: {
    deptLv2: boolean;
    deptLv3: boolean;
    deptLv4: boolean;
    deptName: boolean;
  };
  defaultValue?: {
    deptLv1: string;
    deptLv2: string;
    deptLv3: string;
    deptLv4: string;
    deptLv5: string;
  };
  targetDeptLv2?;
  targetDeptLv3?;
  targetDeptLv4?;
};

export const InspectionDepartmentForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { datas, errorMsgs, is_dept_lv2_invalid, currFiscalYear } = useContext(
    InspectionDepartmentContext
  );

  const { isDisabled, defaultValue } = props;

  const hse = getHSEById('#dept-lv');

  const selectedDeptLv = parseInt(hse.value);
  const selectedDeptVl = hse.options[hse.selectedIndex].text;

  const [deptLv2] = useDeptLv2(datas.orgData, selectedDeptLv);
  const [deptLv3, setDeptLv3] = useDeptLv3(datas.orgData, selectedDeptLv);
  const [deptLv4, setDeptLv4] = useDeptLv4(datas.orgData, selectedDeptLv);

  const handleChangeDept2 = (e) => {
    const target = parseInt(e.target.value);

    switch (selectedDeptLv) {
      case 4: {
        setDeptLv3(
          datas.orgData
            .filter((x) => {
              return x.department_level_code === 3 && x.dept_code_lv3;
            })
            .filter((x) => {
              return x.dept_code_lv2 === target;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );
        break;
      }
      case 5: {
        setDeptLv3(
          datas.orgData
            .filter((x) => {
              return x.dept_code_lv4 && x.dept_code_lv2 === target;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        const target2 = datas.orgData
          .filter((x) => {
            return x.dept_code_lv4 && x.dept_code_lv2 === target;
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];

        setDeptLv4(
          datas.orgData
            .filter((x) => {
              return x.dept_code_lv4 && x.dept_code_lv3 === target2;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );
        break;
      }
    }
  };

  const handleChangeDept3 = (e) => {
    const target = parseInt(e.target.value);

    setDeptLv4(
      datas.orgData
        .filter((x) => {
          return x.dept_code_lv3 === target && x.dept_code_lv4;
        })
        .map((x) => {
          return {
            key: x.dept_code_lv4,
            val: x.dept_name_lv4,
          };
        })
    );
  };

  let deptName = '';

  switch (selectedDeptLv) {
    case 1:
      deptName = defaultValue?.deptLv1;
      break;
    case 2:
      deptName = defaultValue?.deptLv2;
      break;
    case 3:
      deptName = defaultValue?.deptLv3;
      break;
    case 4:
      deptName = defaultValue?.deptLv4;
      break;
    case 5:
      deptName = defaultValue?.deptLv5;
      break;
    default:
      break;
  }

  return (
    <>
      <SelectBoxField
        id='fiscal-year'
        label='検査年度'
        className={classes.inputField}
        defaultValue={currFiscalYear}
        options={[{ key: currFiscalYear, val: `${currFiscalYear} 年度` }]}
        isDisabled={true}
      />
      <SelectBoxField
        id='inspection-department-level'
        label='階層レベル'
        className={classes.inputField}
        defaultValue={''}
        errorMsg={''}
        options={[{ key: selectedDeptLv, val: selectedDeptVl }]}
        isDisabled={true}
      />
      {selectedDeptLv > 2 && !is_dept_lv2_invalid && (
        <SelectBoxField
          id='inspection-department-code-2'
          label='階層レベルⅡの所属部署を選択'
          className={classes.inputField}
          defaultValue={''}
          errorMsg={''}
          options={props.targetDeptLv2 ? props.targetDeptLv2 : deptLv2}
          onChange={handleChangeDept2}
          isDisabled={isDisabled?.deptLv2}
        />
      )}
      {selectedDeptLv > 3 && (
        <SelectBoxField
          id='inspection-department-code-3'
          label='階層レベルⅢの所属部署を選択'
          className={classes.inputField}
          defaultValue={''}
          errorMsg={''}
          options={props.targetDeptLv3 ? props.targetDeptLv3 : deptLv3}
          onChange={handleChangeDept3}
          isDisabled={isDisabled?.deptLv3}
        />
      )}
      {selectedDeptLv > 4 && (
        <SelectBoxField
          id='inspection-department-code-4'
          label='階層レベルⅣの所属部署を選択'
          className={classes.inputField}
          defaultValue={''}
          errorMsg={''}
          options={props.targetDeptLv4 ? props.targetDeptLv4 : deptLv4}
          isDisabled={isDisabled?.deptLv4}
        />
      )}
      <InputTextField
        id='inspection-department-name'
        label='部署名'
        className={classes.inputField}
        defaultValue={deptName}
        errorMsg={errorMsgs.inspectionDepartmentName}
        isDisabled={isDisabled ? isDisabled.deptName : false}
      />
    </>
  );
};
