import { AxiosResponse } from 'axios';

export const convertJST = (orgDate: Date): string => {
  const tmpDate: Date = new Date(orgDate.toString().slice(0, 19));
  tmpDate.setHours(tmpDate.getHours() + 9);

  return (
    tmpDate.getFullYear() +
    '/' +
    ('00' + (tmpDate.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + tmpDate.getDate()).slice(-2) +
    ' ' +
    ('0' + tmpDate.getHours()).slice(-2) +
    ':' +
    ('0' + tmpDate.getMinutes()).slice(-2) +
    ':' +
    ('0' + tmpDate.getSeconds()).slice(-2)
  );
};

export const passwordGenerator = (): string => {
  const len = 8;
  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const string = letters + letters.toUpperCase() + numbers;
  const pattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8}$/;
  const limit = 100;

  let password = '';

  for (let i = 0; i < limit; i++) {
    password = '';

    for (let j = 0; j < len; j++) {
      password += string.charAt(Math.floor(Math.random() * string.length));
    }

    if (password?.trim().match(pattern)) {
      break;
    }
  }

  return password;
};

export const getHIEById = (id: string): HTMLInputElement => {
  return document.querySelector<HTMLInputElement>(id);
};

export const getHSEById = (id: string): HTMLSelectElement => {
  return document.querySelector<HTMLSelectElement>(id);
};

export const downloadExcel = (res: AxiosResponse): void => {
  const reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = reg.exec(res.headers['content-disposition']);

  if (matches && matches[1]) {
    const filename = decodeURI(matches[1].replace(/['"]/g, ''));

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([res.data]));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  } else {
    // Error
  }
};
