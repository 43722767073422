import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';

const setLabelPadding = (label: string): string => {
  let padding = '';

  if (label) {
    label.split('').map((c) => {
      c.match(/[ -~]/) ? (padding += c) : (padding += '　');
    });
  }
  return padding;
};

type Props = {
  id: string;
  label: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  errorMsg: string;
  isDisabled?: boolean;
};

const InputTextField: React.FC<Props> = (props) => {
  const {
    id,
    label,
    className,
    value,
    defaultValue = '',
    errorMsg = '',
    isDisabled = false,
  } = props;

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id={id}
        label={setLabelPadding(label)}
        value={value}
        defaultValue={defaultValue}
        error={errorMsg.trim().length > 0}
        disabled={isDisabled}
      />
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};

export default InputTextField;
