import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { USER_TYPE } from 'utils/constants';
import { downloadExcel } from 'utils/functions';
import { selectOptionType } from 'types/common';
import CreateDialog from 'views/components/dialogs/JaAdminMaster/CreateDialog';
import UpdateDialog from 'views/components/dialogs/JaAdminMaster/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/JaAdminMaster/DeleteDialog';
import DataTable from 'views/components/tables/JaAdminMaster/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const JaAdminContext = createContext(
  {} as {
    setDatas;
    setDatasWk;
    initialValues: {
      prefCode: Array<selectOptionType>;
      bankCode: Array<selectOptionType>;
      jaName: string;
    };
    itemValues: {
      jaName: string;
      password: string;
    };
    defaultValues: {
      userName: string;
      userDeptName: string;
      userPost: string;
      telephoneNumber: string;
      email: string;
    };
    selectOptions: {
      pref: Array<selectOptionType>;
      bank: Array<selectOptionType>;
    };
    errorMsgs: {
      prefCode: string;
      bankCode: string;
      jaName: string;
      userName: string;
      userDeptName: string;
      userPost: string;
      telephoneNumber: string;
      email: string;
      password: string;
      dummySideTel: string;
      dummySideEmail: string;
      dummySidePass: string;
    };
    setErrorMsgs: {
      clear: () => void;
      prefCode: React.Dispatch<React.SetStateAction<string>>;
      bankCode: React.Dispatch<React.SetStateAction<string>>;
      jaName: React.Dispatch<React.SetStateAction<string>>;
      userName: React.Dispatch<React.SetStateAction<string>>;
      userDeptName: React.Dispatch<React.SetStateAction<string>>;
      userPost: React.Dispatch<React.SetStateAction<string>>;
      telephoneNumber: React.Dispatch<React.SetStateAction<string>>;
      email: React.Dispatch<React.SetStateAction<string>>;
      password: React.Dispatch<React.SetStateAction<string>>;
      dummySideTel: React.Dispatch<React.SetStateAction<string>>;
      dummySideEmail: React.Dispatch<React.SetStateAction<string>>;
      dummySidePass: React.Dispatch<React.SetStateAction<string>>;
    };
    eventHandlers: {
      setPrefCode: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
      setBankCode: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
      setJaName: React.Dispatch<React.SetStateAction<string>>;
      setPassword: React.Dispatch<React.SetStateAction<string>>;
      handleChangePerf: (event: React.ChangeEvent<HTMLInputElement>) => void;
      handleChangeBank: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    errorUserName: string;
    errorUserDeptName: string;
    errorUserPost: string;
    errorTelephoneNumber: string;
    errorEmail: string;
    errorPassword: string;
    setErrorPassword: React.Dispatch<React.SetStateAction<string>>;
    setErrorDummySidePass: React.Dispatch<React.SetStateAction<string>>;
    checkboxToggles: boolean;
    setCheckboxToggles: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

const JaAdmin: React.FC = () => {
  const [jaMasterDatas, setJaMasterDatas] = useState([]);
  const [datas, setDatas] = useState([]);
  const [datasWk, setDatasWk] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({
    id: -1,
    user_name: '',
    user_dept_name: '',
    user_post: '',
    telephone_number: '',
    email: '',
    account_lock: false,
  });

  const [searchOptions, setSearchOptions] = useState([
    { key: '00', val: 'すべて' },
  ]);

  const [initialPrefCode, setInitialPrefCode] = useState([]);
  const [prefCode, setPrefCode] = useState([]);

  const [initialBankCode, setInitialBankCode] = useState([]);
  const [bankCode, setBankCode] = useState([]);

  const [initialJaName, setInitialJaName] = useState('');
  const [jaName, setJaName] = useState('');

  const [password, setPassword] = useState('');

  const [errorPrefCode, setErrorPrefCode] = useState('');
  const [errorBankCode, setErrorBankCode] = useState('');
  const [errorJaName, setErrorJaName] = useState('');
  const [errorUserName, setErrorUserName] = useState('');
  const [errorUserDeptName, setErrorUserDeptName] = useState('');
  const [errorUserPost, setErrorUserPost] = useState('');
  const [errorTelephoneNumber, setErrorTelephoneNumber] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [errorDummySideTel, setErrorDummySideTel] = useState('');
  const [errorDummySideEmail, setErrorDummySideEmail] = useState('');
  const [errorDummySidePass, setErrorDummySidePass] = useState('');

  const initialValues = {
    prefCode: initialPrefCode,
    bankCode: initialBankCode,
    jaName: initialJaName,
  };

  const itemValues = {
    jaName: jaName,
    password: password,
  };

  const defaultValues = {
    userName: checkedInfo.user_name,
    userDeptName: checkedInfo.user_dept_name,
    userPost: checkedInfo.user_post,
    telephoneNumber: checkedInfo.telephone_number,
    email: checkedInfo.email,
  };

  const errorMsgs = {
    prefCode: errorPrefCode,
    bankCode: errorBankCode,
    jaName: errorJaName,
    userName: errorUserName,
    userDeptName: errorUserDeptName,
    userPost: errorUserPost,
    telephoneNumber: errorTelephoneNumber,
    email: errorEmail,
    password: errorPassword,
    dummySideTel: errorDummySideTel,
    dummySideEmail: errorDummySideEmail,
    dummySidePass: errorDummySidePass,
  };

  const setOffset = useSetAtom(offsetAtom);

  const [checkboxToggles, setCheckboxToggles] = React.useState(false);

  const setErrorMsgs = {
    clear: () => {
      setErrorPrefCode('');
      setErrorBankCode('');
      setErrorJaName('');
      setErrorUserName('');
      setErrorUserDeptName('');
      setErrorUserPost('');
      setErrorTelephoneNumber('');
      setErrorEmail('');
      setErrorPassword('');
      setErrorDummySideTel('');
      setErrorDummySideEmail('');
      setErrorDummySidePass('');
    },
    prefCode: setErrorPrefCode,
    bankCode: setErrorBankCode,
    jaName: setErrorJaName,
    userName: setErrorUserName,
    userDeptName: setErrorUserDeptName,
    userPost: setErrorUserPost,
    telephoneNumber: setErrorTelephoneNumber,
    email: setErrorEmail,
    password: setErrorPassword,
    dummySideTel: setErrorDummySideTel,
    dummySideEmail: setErrorDummySideEmail,
    dummySidePass: setErrorDummySidePass,
  };

  const selectOptions = {
    pref: prefCode,
    bank: bankCode,
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/ja-admin-masters'),
      axios.get('/api/ja-admins', {
        params: { user_type: USER_TYPE.JA_ADMIN },
      }),
    ]).then((res) => {
      setJaMasterDatas(res[0].data[0]);
      setDatas(res[1].data);
      setDatasWk(res[1].data);

      const wkPC = res[0].data[0]
        .map((x) => {
          return {
            key: x.pref_code,
            val: x.pref_name,
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key == elm.key) == idx
        );

      setSearchOptions([
        ...searchOptions,
        ...res[1].data
          .map((x) => {
            return { key: x.pref_code, val: x.pref_name };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key == elm.key) == idx
          ),
      ]);

      setInitialPrefCode([...initialPrefCode, ...wkPC]);
      setPrefCode([...prefCode, ...wkPC]);

      const wkBC = res[0].data[0]
        .filter((val) => {
          return val.pref_code === res[0].data[0][0].pref_code;
        })
        .map((x) => {
          return { key: x.bank_code, val: x.bank_code };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key == elm.key) == idx
        );

      setInitialBankCode([...initialBankCode, ...wkBC]);
      setBankCode([...bankCode, ...wkBC]);

      setInitialJaName(res[0].data[0][0].ja_name);
      setJaName(res[0].data[0][0].ja_name);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSearch = (e) => {
    let wk = [];

    if (e.target.value === '00') {
      setDatasWk(datas);
    } else {
      wk = datas.filter((x) => {
        return x.pref_code === e.target.value;
      });

      setDatasWk(wk);
    }

    setCheckedInfo({
      id: -1,
      user_name: '',
      user_dept_name: '',
      user_post: '',
      telephone_number: '',
      email: '',
      account_lock: false,
    });
    setOffset(0);
  };

  const handleChangePerf = (e) => {
    const wk = jaMasterDatas
      .filter((x) => {
        return x.pref_code === e.target.value;
      })
      .map((x) => {
        return {
          key: x.bank_code,
          val: x.bank_code,
        };
      })
      .filter(
        (element, index, self) =>
          self.findIndex(
            (x) => x.key === element.key && x.key === element.key
          ) === index
      );

    setBankCode([...wk]);

    const wk2 = jaMasterDatas.filter((x) => {
      return x.pref_code === e.target.value;
    });

    setJaName(wk2[0].ja_name);
  };

  const handleChangeBank = (e) => {
    const wk = jaMasterDatas.filter((x) => {
      return x.bank_code === e.target.value;
    });

    setJaName(wk[0].ja_name);
  };

  const download = async () => {
    const res = await axios.post(
      '/api/ja-admin-master/download',
      {},
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            JA&thinsp;管理者マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl variant='outlined' style={{ width: '250px' }}>
              <InputLabel htmlFor='outlined-age-native-simple2'>
                都道府県を選択
              </InputLabel>
              <Select
                native
                id='searchPref'
                onChange={handleChangeSearch}
                label='都道府県を選択'
                style={{
                  width: '250px',
                }}
              >
                {searchOptions.map((elm) => (
                  <option key={elm.key} value={elm.key}>
                    {elm.val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <JaAdminContext.Provider
            value={{
              setDatas,
              setDatasWk,
              initialValues: initialValues,
              defaultValues: defaultValues,
              itemValues: itemValues,
              selectOptions: selectOptions,
              errorMsgs: errorMsgs,
              setErrorMsgs: setErrorMsgs,
              eventHandlers: {
                setPrefCode: setPrefCode,
                setBankCode: setBankCode,
                setJaName: setJaName,
                setPassword: setPassword,
                handleChangePerf: handleChangePerf,
                handleChangeBank: handleChangeBank,
              },
              errorUserName,
              errorUserDeptName,
              errorUserPost,
              errorTelephoneNumber,
              errorEmail,
              errorPassword,
              setErrorPassword,
              setErrorDummySidePass,
              checkboxToggles,
              setCheckboxToggles,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />

              <UpdateDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <Button
                variant='outlined'
                startIcon={<GetAppIcon />}
                onClick={download}
              >
                JA&thinsp;管理者一覧
              </Button>
            </Box>

            <DataTable
              datas={datasWk}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </JaAdminContext.Provider>
        </main>
      }
    />
  );
};

export default JaAdmin;
