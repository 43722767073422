import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

type Props = {
  buttonLabel: string;
  onClickActionHandler: () => void;
  isDisabled: boolean;
};

export const DialogButtonUpload: React.FC<Props> = (props) => {
  const { buttonLabel, onClickActionHandler, isDisabled } = props;

  return (
    <Button
      variant='outlined'
      startIcon={<AddIcon />}
      style={
        isDisabled
          ? { color: '#BDBDBD', border: '1px solid #BDBDBD' }
          : { color: '#006633', border: '1px solid #006633' }
      }
      onClick={onClickActionHandler}
      disabled={isDisabled}
    >
      {buttonLabel}
    </Button>
  );
};
