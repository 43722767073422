import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

const setLabelPadding = (label: string): string => {
  let padding = '';

  if (label) {
    label.split('').map((c) => {
      c.match(/[ -~]/) ? (padding += c) : (padding += '　');
    });
  }
  return padding;
};

type Props = {
  id: string;
  label: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  errorMsg: string;
  isDisabled?: boolean;
  checklistRef?;
};

const InputUploadField: React.FC<Props> = (props) => {
  const {
    id,
    label,
    className,
    defaultValue,
    errorMsg = '',
    isDisabled = false,
    checklistRef,
  } = props;
  const [checklistName, setChecklistName] = useState(defaultValue);

  const target = `target-${id}`;

  const handleClick = () => {
    setChecklistName('');
    document.querySelector<HTMLInputElement>(`#${target}`).click();

    // let obj = document.querySelector<HTMLInputElement>('#target').files;
    // obj.value = '';
  };

  const handleChecklistNameSet = () => {
    if (
      document.querySelector<HTMLInputElement>(`#${target}`).files.length > 0
    ) {
      setChecklistName(
        document.querySelector<HTMLInputElement>(`#${target}`)?.files[0].name
      );
    }
  };

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <InputLabel>{label}</InputLabel>
      {isDisabled ? (
        <OutlinedInput
          id={id}
          label={setLabelPadding(label)}
          value={checklistName}
          error={errorMsg.trim().length > 0}
          disabled={isDisabled}
        />
      ) : (
        <OutlinedInput
          id={id}
          label={setLabelPadding(label)}
          value={checklistName}
          error={errorMsg.trim().length > 0}
          disabled={isDisabled}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton edge='end'>
                <FolderOpenIcon />
              </IconButton>
            </InputAdornment>
          }
          onClick={handleClick}
        />
      )}
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
      <input
        type='file'
        // accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        id={target}
        name='checklist'
        style={{ display: 'none' }}
        onChange={handleChecklistNameSet}
        ref={checklistRef}
      />
    </FormControl>
  );
};

export default InputUploadField;
