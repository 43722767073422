import React from 'react';

import { TplPage } from 'views/templates/TplPage';

const Home: React.FC = () => {
  return (
    <TplPage
      content={
        <main
          style={{
            fontSize: '18px',
            paddingTop: '25px',
            textAlign: 'center',
          }}
        >
          上のメニューから処理を選択してください。
        </main>
      }
    />
  );
};

export default Home;
