import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ManualMasterContext } from 'views/pages/ManualMaster';
import SelectBoxField from 'views/molecules/SelectBoxField';
import InputUploadField from 'views/molecules/InputUploadField';
import { PROC_TYPE } from 'utils/constants';
import InputTextField from 'views/molecules/InputTextField';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  selectBox: {
    marginTop: 12,
    width: '100%',
  },
});

type Props = {
  isDisabled: {
    categorys: boolean;
  };
  procType: number;
};

export const ManualMasterForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { categoryOpt, checkedInfo, fileRef, errorFile, selectedFileName } =
    React.useContext(ManualMasterContext);

  return (
    <>
      {props.procType === PROC_TYPE.DELETE && (
        <>
          <InputTextField
            id='category'
            label='区分'
            className={classes.inputField}
            defaultValue={checkedInfo.category_name}
            errorMsg={''}
            isDisabled={true}
          />

          <InputTextField
            id='manual'
            label='マニュアル・資料'
            className={classes.inputField}
            defaultValue={selectedFileName}
            errorMsg={''}
            isDisabled={true}
          />
        </>
      )}
      {props.procType === PROC_TYPE.CREATE && (
        <>
          <SelectBoxField
            id='category'
            label='区分を選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={''}
            options={categoryOpt}
            isDisabled={props.isDisabled.categorys}
          />

          <InputUploadField
            id='checklist'
            label='マニュアル・資料を選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorFile}
            checklistRef={fileRef}
          />
        </>
      )}
    </>
  );
};
