import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { UserContext } from 'views/pages/User';
import { UserForm } from 'views/components/forms/UserForm';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    selectedDeptLvOption,
    deptDatas,
    selectedDeptLv,
    setSelectedDeptLv,
    setCheckedInfo,
    setDatas,
    setDeptLv2,
    setDeptLv3,
    setDeptLv4,
    setDeptLv5,
    setErrorDeptLvs,
    setErrorDeptLv1,
    setErrorDeptLv2,
    setErrorDeptLv3,
    setErrorDeptLv4,
    setErrorDeptLv5,
    setErrorUserName,
    setErrorUserPost,
    setErrorTelephoneNumber,
    setErrorEmail,
    setErrorPassword,
    createButtonDisabled,
    is_dept_lv2_invalid,
    isPlural,
    setIsPlural,
  } = React.useContext(UserContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);

    const selectedDeptLv = parseInt(selectedDeptLvOption[0].key);

    setSelectedDeptLv(selectedDeptLv);

    switch (selectedDeptLv) {
      case 3: {
        setDeptLv2(
          deptDatas
            .filter((x) => {
              return x.department_level_code === 3;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv2,
                val: x.dept_name_lv2,
              };
            })
        );

        const target_lv2 = deptDatas
          .filter((x) => {
            return x.department_level_code === 3;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 3 && x.dept_code_lv2 === target_lv2
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        break;
      }

      case 4: {
        setDeptLv2(
          deptDatas
            .filter((x) => {
              return x.department_level_code === 4;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv2,
                val: x.dept_name_lv2,
              };
            })
        );

        const target_lv2 = deptDatas
          .filter((x) => {
            return x.department_level_code === 4;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 4 && x.dept_code_lv2 === target_lv2
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        const target_lv3 = deptDatas
          .filter((x) => {
            return x.department_level_code === 4;
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];

        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 4 &&
                x.dept_code_lv2 === target_lv2 &&
                x.dept_code_lv3 === target_lv3
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );
        break;
      }

      case 5: {
        setDeptLv2(
          deptDatas
            .filter((x) => {
              return x.department_level_code === 5;
            })
            .map((x) => {
              return {
                key: x.dept_code_lv2,
                val: x.dept_name_lv2,
              };
            })
        );

        const target_lv2 = deptDatas
          .filter((x) => {
            return x.department_level_code === 5;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        setDeptLv3(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 && x.dept_code_lv2 === target_lv2
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv3,
                val: x.dept_name_lv3,
              };
            })
        );

        const target_lv3 = deptDatas
          .filter((x) => {
            return x.department_level_code === 5;
          })
          .map((x) => {
            return x.dept_code_lv3;
          })[0];

        setDeptLv4(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 &&
                x.dept_code_lv2 === target_lv2 &&
                x.dept_code_lv3 === target_lv3
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv4,
                val: x.dept_name_lv4,
              };
            })
        );

        const target_lv4 = deptDatas
          .filter((x) => {
            return x.department_level_code === 5;
          })
          .map((x) => {
            return x.dept_code_lv4;
          })[0];

        setDeptLv5(
          deptDatas
            .filter((x) => {
              return (
                x.department_level_code === 5 &&
                x.dept_code_lv2 === target_lv2 &&
                x.dept_code_lv3 === target_lv3 &&
                x.dept_code_lv4 === target_lv4
              );
            })
            .map((x) => {
              return {
                key: x.dept_code_lv5,
                val: x.dept_name_lv5,
              };
            })
        );
        break;
      }
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorDeptLvs('');
    setErrorDeptLv1('');
    setErrorDeptLv2('');
    setErrorDeptLv3('');
    setErrorDeptLv4('');
    setErrorDeptLv5('');

    setErrorUserName('');
    setErrorUserPost('');
    setErrorTelephoneNumber('');
    setErrorEmail('');
    setErrorPassword('');

    setIsPlural(false);
  };

  const handleCreate = () => {
    const department_level_code = selectedDeptLv;

    const dept_code_lv1 =
      document.querySelector<HTMLSelectElement>('#dept-lv1');
    const dept_code_lv2 =
      document.querySelector<HTMLSelectElement>('#dept-lv2');
    const dept_code_lv3 =
      document.querySelector<HTMLSelectElement>('#dept-lv3');
    const dept_code_lv4 =
      document.querySelector<HTMLSelectElement>('#dept-lv4');
    const dept_code_lv5 =
      document.querySelector<HTMLSelectElement>('#dept-lv5');
    const user_name =
      document.querySelector<HTMLInputElement>('#user-name').value;
    const user_post =
      document.querySelector<HTMLInputElement>('#user-post').value;
    const telephone_number =
      document.querySelector<HTMLInputElement>('#telephone-number').value;
    const email = document.querySelector<HTMLInputElement>('#email').value;
    const password =
      document.querySelector<HTMLInputElement>('#password')?.value;

    axios
      .post('/api/user/create', {
        pref_code,
        bank_code,
        department_level_code,
        dept_code_lv1: dept_code_lv1?.value,
        dept_name_lv1: dept_code_lv1?.options[dept_code_lv1.selectedIndex].text,
        dept_code_lv2: dept_code_lv2?.value,
        dept_name_lv2: dept_code_lv2?.options[dept_code_lv2.selectedIndex].text,
        dept_code_lv3: dept_code_lv3?.value,
        dept_name_lv3: dept_code_lv3?.options[dept_code_lv3.selectedIndex].text,
        dept_code_lv4: dept_code_lv4?.value,
        dept_name_lv4: dept_code_lv4?.options[dept_code_lv4.selectedIndex].text,
        dept_code_lv5: dept_code_lv5?.value,
        dept_name_lv5: dept_code_lv5?.options[dept_code_lv5.selectedIndex].text,
        user_name,
        user_post,
        telephone_number,
        email,
        password,
        user_type: USER_TYPE.USER,
        isPlural,
      })
      .then((res) => {
        setDatas(res.data);
        setCheckedInfo({ id: -1, name: null });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.user_name
          ? setErrorUserName(errors.user_name.errorMsg)
          : setErrorUserName('');

        errors.user_post
          ? setErrorUserPost(errors.user_post.errorMsg)
          : setErrorUserPost('');

        errors.telephone_number
          ? setErrorTelephoneNumber(errors.telephone_number.errorMsg)
          : setErrorTelephoneNumber('');

        errors.email ? setErrorEmail(errors.email.errorMsg) : setErrorEmail('');

        errors.password
          ? setErrorPassword(errors.password.errorMsg)
          : setErrorPassword('');

        if (is_dept_lv2_invalid) {
          if (errors.user_name) {
            setErrorDeptLvs('　');
          } else {
            setErrorDeptLvs('');
          }

          if (errors.user_post) {
            setErrorDeptLv3('　');
          } else {
            setErrorDeptLv3('');
          }

          if (errors.telephone_number) {
            setErrorDeptLv4('　');
          } else {
            setErrorDeptLv4('');
          }
        } else {
          if (errors.user_name) {
            setErrorDeptLvs('　');
          } else {
            setErrorDeptLvs('');
          }

          if (errors.user_post) {
            setErrorDeptLv2('　');
          } else {
            setErrorDeptLv2('');
          }

          if (errors.telephone_number) {
            setErrorDeptLv3('　');
          } else {
            setErrorDeptLv3('');
          }

          if (errors.email) {
            setErrorDeptLv4('　');
          } else {
            setErrorDeptLv4('');
          }
        }
      });
  };

  const handleClickCheckbox = (e) => {
    setErrorDeptLvs('');
    setErrorDeptLv1('');
    setErrorDeptLv2('');
    setErrorDeptLv3('');
    setErrorDeptLv4('');
    setErrorDeptLv5('');

    setErrorUserName('');
    setErrorUserPost('');
    setErrorTelephoneNumber('');
    setErrorEmail('');
    setErrorPassword('');

    setIsPlural(e.target.checked);
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='ユーザ登録'
        onClickActionHandler={handleOpen}
        isDisabled={createButtonDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='ユーザ登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <UserForm
            isDisabled={{
              dept_lv1: false,
              dept_lv2: false,
              dept_lv3: false,
              dept_lv4: false,
              dept_lv5: false,
              userName: false,
              userPost: false,
              telephoneNumber: false,
              email: false,
              password: false,
            }}
            procType={PROC_TYPE.CREATE}
          />
        </DialogContent>

        <Divider />

        <div style={{ textAlign: 'center', marginTop: 15, marginBottom: -10 }}>
          <FormControlLabel
            control={<Checkbox color='primary' />}
            label='兼務ユーザとして登録する'
            onClick={handleClickCheckbox}
          />
        </div>

        <DialogActionsCreate
          buttonLabel='ユーザを登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
