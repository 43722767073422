import React, { useContext } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { convertJST } from 'utils/functions';
import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  deptDatas;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const {
    datas,
    is_dept_lv2_invalid,
    currFiscalYear,
    selectedFiscalYear,
    selectedDeptLv,
  } = useContext(InspectionDepartmentContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ id: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    id: number,
    dept_code_lv1: number,
    dept_name_lv1: string,
    dept_code_lv2: number,
    dept_name_lv2: string,
    dept_code_lv3: number,
    dept_name_lv3: string,
    dept_code_lv4: number,
    dept_name_lv4: string,
    dept_code_lv5: number,
    dept_name_lv5: string
  ) => {
    if (id === props.checkedInfo.id) {
      props.setCheckedInfo({
        id: -1,
      });
    } else {
      props.setCheckedInfo({
        id,
        dept_code_lv1,
        dept_name_lv1,
        dept_code_lv2,
        dept_name_lv2,
        dept_code_lv3,
        dept_name_lv3,
        dept_code_lv4,
        dept_name_lv4,
        dept_code_lv5,
        dept_name_lv5,
      });
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center'>選択</StyledTableCell>
              {selectedDeptLv === 1 && (
                <StyledTableCell>第Ⅰ階層</StyledTableCell>
              )}
              {selectedDeptLv >= 2 && !is_dept_lv2_invalid && (
                <StyledTableCell>第Ⅱ階層</StyledTableCell>
              )}
              {selectedDeptLv > 2 && <StyledTableCell>第Ⅲ階層</StyledTableCell>}
              {selectedDeptLv > 3 && <StyledTableCell>第Ⅳ階層</StyledTableCell>}
              {selectedDeptLv > 4 && <StyledTableCell>第Ⅴ階層</StyledTableCell>}
              <StyledTableCell>更新日時</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {datas.data?.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={[elm.id].join('')}>
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={props.checkedInfo.id === elm.id}
                    onChange={() => {
                      handleCheckbox(
                        elm.id,
                        elm.dept_code_lv1,
                        elm.dept_name_lv1,
                        elm.dept_code_lv2,
                        elm.dept_name_lv2,
                        elm.dept_code_lv3,
                        elm.dept_name_lv3,
                        elm.dept_code_lv4,
                        elm.dept_name_lv4,
                        elm.dept_code_lv5,
                        elm.dept_name_lv5
                      );
                    }}
                    disabled={currFiscalYear !== selectedFiscalYear}
                  />
                </StyledTableCell>
                {selectedDeptLv === 1 && (
                  <StyledTableCell>{elm.dept_name_lv1}</StyledTableCell>
                )}
                {selectedDeptLv >= 2 && !is_dept_lv2_invalid && (
                  <StyledTableCell>{elm.dept_name_lv2}</StyledTableCell>
                )}
                {selectedDeptLv > 2 && (
                  <StyledTableCell>{elm.dept_name_lv3}</StyledTableCell>
                )}
                {selectedDeptLv > 3 && (
                  <StyledTableCell>{elm.dept_name_lv4}</StyledTableCell>
                )}
                {selectedDeptLv > 4 && (
                  <StyledTableCell>{elm.dept_name_lv5}</StyledTableCell>
                )}

                <StyledTableCell>{convertJST(elm.updatedAt)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {datas.data.length > PAR_PAGE && (
        <JICSPagination
          length={datas.data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
