import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { passwordGenerator } from 'utils/functions';

const setLabelPadding = (label: string): string => {
  let padding = '';

  if (label) {
    label.split('').map((c) => {
      c.match(/[ -~]/) ? (padding += c) : (padding += '　');
    });
  }
  return padding;
};

type Props = {
  id: string;
  label: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  errorMsg: string;
  isDisabled?: boolean;
  isEdit?: boolean;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
};

const InputPasswordField: React.FC<Props> = (props) => {
  const {
    id,
    label,
    className,
    value,
    errorMsg = '',
    isDisabled = false,
    isEdit = false,
    setPassword,
  } = props;

  const handleClick = (): void => {
    setPassword(passwordGenerator());
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <FormControl variant='outlined' fullWidth className={className}>
      <InputLabel>{label}</InputLabel>
      {isEdit ? (
        <OutlinedInput
          id={id}
          type='password'
          label={setLabelPadding(label)}
          value={value}
          error={errorMsg.trim().length > 0}
          disabled={isDisabled}
          onChange={handleChangePassword}
        />
      ) : (
        <OutlinedInput
          id={id}
          label={setLabelPadding(label)}
          value={value}
          error={errorMsg.trim().length > 0}
          disabled={isDisabled}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton edge='end' onClick={handleClick}>
                <SettingsIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={handleChangePassword}
        />
      )}
      <FormHelperText error={errorMsg.trim().length > 0}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};

export default InputPasswordField;
