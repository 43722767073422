import React, { createContext, useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import axios from 'axios';
import { useSetAtom } from 'jotai';

import UpdateDialog from 'views/components/dialogs/NewsInformationMaster/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/NewsInformationMaster/DeleteDialog';
import CreateDialog from 'views/components/dialogs/NewsInformationMaster/CreateDialog';
import DataTable from 'views/components/tables/NewsInformationMaster/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const NewsInformationMasterContext = createContext(
  {} as {
    defaultValues: {
      fileRef1;
      fileRef2;
      fileRef3;
    };
    setDatas;
    setDatasWk;
    category;
    setCategory;
    setValue;
    setCheckedInfo;
  }
);

const NewsInformationMaster: React.FC = () => {
  const [datas, setDatas] = useState([]);
  const [datasWk, setDatasWk] = useState([]);
  const [category, setCategory] = useState([]);
  // const [categorys, setCaterorys] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({
    id: -1,
    tmp_name1: '',
    tmp_name2: '',
    tmp_name3: '',
  });
  const [value, setValue] = useState(1);
  // const allCategory = [{ key: 0, val: 'すべて' }];

  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const fileRef3 = useRef(null);

  const setOffset = useSetAtom(offsetAtom);

  useEffect(() => {
    Promise.all([
      axios.get('/api/news_category/getlist'),
      axios.get('/api/news_information/getlists'),
    ]).then((res) => {
      const options = res[0].data.map((x) => {
        return {
          key: x.category_code,
          val: x.category_name,
        };
      });
      setCategory(options);

      // setCaterorys([...allCategory, ...options]);
      setDatas(res[1].data);
      let wk = [];
      wk = res[1].data.filter((x) => {
        return x.category_code === value;
      });
      setDatasWk(wk);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            情報ボックス登録
          </Box>

          <NewsInformationMasterContext.Provider
            value={{
              defaultValues: {
                fileRef1,
                fileRef2,
                fileRef3,
              },
              setDatas,
              setDatasWk,
              category,
              setCategory,
              setValue,
              setCheckedInfo,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog datas={datas} category={category} />

              <UpdateDialog
                category={category}
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <DeleteDialog
                category={category}
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
            </Box>

            <DataTable
              datas={datas}
              datasWk={datasWk}
              setDatasWk={setDatasWk}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
              value={value}
              setValue={setValue}
              category={category}
            />
          </NewsInformationMasterContext.Provider>
        </main>
      }
    />
  );
};

export default NewsInformationMaster;
