import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { InstitutionAdminContext } from 'views/pages/InstitutionAdmin';
import { InstitutionAdminForm } from 'views/components/forms/InstitutionAdminForm';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    orgInstitution,
    setInstitution,
    setRegisteredInstitution,
    setDatas,
    setOrgDatas,
    setCheckedInfo,
    setErrorUserName,
    setErrorUserDeptName,
    setErrorUserPost,
    setErrorTelephoneNumber,
    setErrorEmail,
    setErrorPassword,
  } = React.useContext(InstitutionAdminContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
    setInstitution(orgInstitution);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorUserName('');
    setErrorUserDeptName('');
    setErrorUserPost('');
    setErrorTelephoneNumber('');
    setErrorEmail('');
    setErrorPassword('');
  };

  const handleCreate = () => {
    const institution_code =
      document.querySelector<HTMLSelectElement>('#institution-code').value;
    const user_name =
      document.querySelector<HTMLInputElement>('#user-name').value;
    const user_dept_name =
      document.querySelector<HTMLInputElement>('#user-dept-name').value;
    const user_post =
      document.querySelector<HTMLInputElement>('#user-post').value;
    const telephone_number =
      document.querySelector<HTMLInputElement>('#telephone-number').value;
    const email = document.querySelector<HTMLInputElement>('#email').value;
    const password =
      document.querySelector<HTMLInputElement>('#password').value;

    axios
      .post('/api/institution-admin/create', {
        institution_code,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        password,
        user_type: USER_TYPE.ALL_PREF_ADMIN,
      })
      .then((res) => {
        setDatas(
          res.data[0].filter((x) => {
            return x.institution_code === parseInt(institution_code);
          })
        );

        setOrgDatas(res.data[0]);

        setRegisteredInstitution([
          ...[{ key: '0', val: 'すべて' }],
          ...res.data[0]
            .map((x) => {
              return {
                key: x.institution_code,
                val: x.institution_name,
              };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex(
                  (x) => x.key === elm.key && x.val === elm.val
                ) === idx
            ),
        ]);

        document.querySelector<HTMLSelectElement>(
          '#selected-institution'
        ).value = institution_code;

        setCheckedInfo({ id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.user_name
          ? setErrorUserName(errors.user_name.errorMsg)
          : setErrorUserName('');

        errors.user_dept_name
          ? setErrorUserDeptName(errors.user_dept_name.errorMsg)
          : setErrorUserDeptName('');

        errors.user_post
          ? setErrorUserPost(errors.user_post.errorMsg)
          : setErrorUserPost('');

        errors.telephone_number
          ? setErrorTelephoneNumber(errors.telephone_number.errorMsg)
          : setErrorTelephoneNumber('');

        errors.email ? setErrorEmail(errors.email.errorMsg) : setErrorEmail('');

        errors.password
          ? setErrorPassword(errors.password.errorMsg)
          : setErrorPassword('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='管理者登録'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='全国機関管理者登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <InstitutionAdminForm
            isDisabled={{
              prefCentral: false,
              userName: false,
              userDeptName: false,
              userPost: false,
              telephoneNumber: false,
              email: false,
              password: false,
            }}
            procType={PROC_TYPE.CREATE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='全国機関管理者を登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
