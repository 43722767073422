import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Divider,
  List,
  ListItem,
  Popover,
  Toolbar,
  Typography,
  IconButton,
  Snackbar,
  Slide,
  SnackbarContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

import { SYSTEM_NAME, TOKEN_KEY, USER_TYPE } from 'utils/constants';
import InformationBox from 'views/components/menus/InformationBox';
import Checklist from 'views/components/menus/Checklist';
import ReportDownload from 'views/components/menus/ReportDownload';
import Master from 'views/components/menus/Master';
import Manual from 'views/components/menus/Manual';
import PasswordDialog from './dialogs/Header/PasswordDialog';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#7BD957',
    color: 'white',
    padding: '15px 50px 15px 50px',
    margin: 'auto',
    width: '100%',
    marginTop: '0',
  },
  toolbarTitle: {
    flex: 1,
  },
  logoutLink: {
    color: 'white',
    border: '1px solid white',
    padding: 10,
    fontSize: 16,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const {
    pref_code,
    bank_code,
    user_type,
    institution_code,
    department_level_code,
    dept_name_lv1,
    dept_name_lv2,
    dept_name_lv3,
    dept_name_lv4,
    dept_name_lv5,
  } = user;

  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [nickname, setNickname] = useState('');

  const isOpen = Boolean(anchorEl);

  const levelRomanNumeral = ['', 'Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ'];

  useEffect(() => {
    axios
      .post('/api/header', {
        user_type,
        pref_code,
        bank_code,
        institution_code,
      })
      .then((res) => {
        setNickname(res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const id = isOpen ? 'simple-popover' : undefined;

  return (
    <div style={{ backgroundColor: '#EFEFEF' }}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component='h2'
          variant='h5'
          color='inherit'
          noWrap
          className={classes.toolbarTitle}
        >
          {SYSTEM_NAME}（JICS）
        </Typography>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Slide}
          onClose={() => {
            setSnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{ backgroundColor: 'green' }}
            message='パスワードを変更しました。'
            action={
              <IconButton
                onClick={() => {
                  setSnackOpen(false);
                }}
                style={{ color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </Snackbar>
        <IconButton
          aria-describedby={id}
          onClick={handlePopoverOpen}
          // onMouseEnter={handlePopoverOpen}
          // onMouseDownCapture={handlePopoverClose}
        >
          <AccountCircleIcon
            style={{
              color: 'white',
              width: 45,
              height: 45,
            }}
          />
        </IconButton>

        <Popover
          id={id}
          // className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          // disableRestoreFocus
        >
          {user.bank_code && (
            <Typography style={{ marginRight: 10 }} display='inline'>
              {user.bank_code}
            </Typography>
          )}
          <Typography display='inline'>{nickname}</Typography>

          {(dept_name_lv1 ||
            dept_name_lv2 ||
            dept_name_lv3 ||
            dept_name_lv4 ||
            dept_name_lv5) && (
            <>
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                {dept_name_lv1 && <Typography>{dept_name_lv1}</Typography>}
                {dept_name_lv2 && <Typography>{dept_name_lv2}</Typography>}
                {dept_name_lv3 && <Typography>{dept_name_lv3}</Typography>}
                {dept_name_lv4 && <Typography>{dept_name_lv4}</Typography>}
                {dept_name_lv5 && <Typography>{dept_name_lv5}</Typography>}
              </div>

              <div style={{ marginTop: 5, marginBottom: 5 }}>
                {
                  <Typography>{`階層レベル${levelRomanNumeral[department_level_code]}`}</Typography>
                }
              </div>
            </>
          )}

          <div style={{ marginBottom: 5 }}>
            <Typography>{user.user_name}</Typography>
          </div>

          <div style={{ textAlign: 'center' }}>
            <PasswordDialog
              user={user}
              setAnchorEl={setAnchorEl}
              setSnackOpen={setSnackOpen}
            />
          </div>
        </Popover>

        <Button
          className={classes.logoutLink}
          onClick={() => {
            sessionStorage.removeItem('user');
            sessionStorage.removeItem(TOKEN_KEY);
            axios.get('/logout');
            window.location.href = '/login';
          }}
        >
          ログアウト
        </Button>
      </Toolbar>

      <List
        component='nav'
        style={{ backgroundColor: 'white', textAlign: 'center' }}
      >
        <ListItem component='div' style={{ textAlign: 'center' }}>
          <InformationBox />
          <Checklist />
          <ReportDownload />
          {(user_type === USER_TYPE.SYSTEM_ADMIN ||
            user_type === USER_TYPE.PREF_ADMIN ||
            user_type === USER_TYPE.JA_ADMIN) && <Master />}
          <Manual />
        </ListItem>
      </List>

      <Divider />
    </div>
  );
};

export default Header;
