import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistTempContext } from 'views/pages/ChecklistTemp';
import InputTextField from 'views/molecules/InputTextField';
import InputUploadField from 'views/molecules/InputUploadField';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
});

export const ChecklistTempForm: React.FC = () => {
  const classes = useStyles();

  const {
    checklistRef,
    defaultChecklist,
    defaultComment,
    errorChecklist,
    errorComment,
    disabledChecklist,
    disabledComment,
  } = React.useContext(ChecklistTempContext);

  return (
    <>
      <InputUploadField
        id='checklist'
        label='一時保存するチェックリストを選択'
        className={classes.inputField}
        defaultValue={defaultChecklist}
        errorMsg={errorChecklist}
        checklistRef={checklistRef}
        isDisabled={disabledChecklist}
      />
      <InputTextField
        id='comment'
        label='コメント'
        className={classes.inputField}
        defaultValue={defaultComment}
        errorMsg={errorComment}
        isDisabled={disabledComment}
      />
    </>
  );
};
