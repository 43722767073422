import { Container } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className='main-content'>
      <Container>
        <p>お探しのページは見つかりませんでした。</p>
        <Link to='/login'>ログイン画面へ</Link>
      </Container>
    </div>
  );
};
export default NotFound;
