import React from 'react';
import { Button } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';

type Props = {
  buttonLabel: string;
  onClickActionHandler: () => void;
  disabled: boolean;
};

export const DialogButtonDelete: React.FC<Props> = (props) => {
  const { buttonLabel, onClickActionHandler, disabled } = props;

  return (
    <Button
      variant='outlined'
      startIcon={<RemoveIcon />}
      color='secondary'
      onClick={onClickActionHandler}
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );
};
