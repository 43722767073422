import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Pagination from 'material-ui-flat-pagination';

const useStyles = makeStyles(() =>
  createStyles({
    pageNavi: {
      marginTop: 20,
      textAlign: 'center',
      '& > button': {
        width: 30,
        height: 30,
        margin: '0 3px',
        borderRadius: 4,
        border: '1px solid #B2B2B2',
      },
    },
    pageNaviCurrent: {
      cursor: 'default',
      color: 'white',
      backgroundColor: '#7BD957',
      '&:hover': {
        backgroundColor: 'rgba(123, 217, 87, 0.75)',
      },
    },
    pageNaviText: {
      color: 'white',
    },
    pageNaviStandard: {
      color: '#A2A2A2',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    pageNaviArrow: {
      color: '#A2A2A2',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#EFEFEF',
      },
    },
  })
);

export const JICSPagination: React.FC<{
  length: number;
  parPage: number;
  offset: number;
  handlePagination: (number) => void;
}> = (props) => {
  const classes = useStyles();

  const { length, parPage, offset, handlePagination } = props;

  return (
    <Pagination
      limit={parPage}
      offset={offset}
      total={length}
      onClick={(e, offset) => handlePagination(offset)}
      className={classes.pageNavi}
      classes={{
        rootStandard: classes.pageNaviStandard,
        rootCurrent: classes.pageNaviCurrent,
        rootEnd: classes.pageNaviArrow,
        text: classes.pageNaviText,
      }}
    />
  );
};
