import React from 'react';
import axios from 'axios';
import { Button, Toolbar, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { SYSTEM_NAME, TOKEN_KEY } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#7BD957',
    color: 'white',
    padding: '15px 50px 15px 50px',
    margin: 'auto',
    width: '100%',
    marginTop: '0',
  },
  toolbarTitle: {
    flex: 1,
  },
  logoutLink: {
    color: 'white',
    border: '1px solid white',
    padding: 10,
    fontSize: 16,
  },
}));

export const HeaderEx: React.FC = () => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: '#EFEFEF' }}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component='h2'
          variant='h5'
          color='inherit'
          noWrap
          className={classes.toolbarTitle}
        >
          {SYSTEM_NAME}（JICS）
        </Typography>

        <IconButton style={{ visibility: 'hidden' }}>
          <AccountCircleIcon
            style={{
              color: 'white',
              width: 45,
              height: 45,
            }}
          />
        </IconButton>

        <Button
          className={classes.logoutLink}
          onClick={() => {
            sessionStorage.removeItem('user');
            sessionStorage.removeItem(TOKEN_KEY);
            axios.get('/logout');
            window.location.href = '/login';
          }}
        >
          ログアウト
        </Button>
      </Toolbar>
    </div>
  );
};
