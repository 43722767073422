import React, { createContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { useDeptLvOption } from 'hooks/useDeptLvOption';
import { useJaMaster } from 'hooks/useJaMaster';
import { USER_TYPE } from 'utils/constants';
import { downloadExcel } from 'utils/functions';
import { selectOptionType } from 'types/common';
import DataTable from 'views/components/tables/UserMaster/DataTable';
import CreateDialog from 'views/components/dialogs/UserMaster/CreateDialog';
import UpdateDialog from 'views/components/dialogs/UserMaster/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/UserMaster/DeleteDialog';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const UserContext = createContext(
  {} as {
    pref_code: string;
    bank_code: string;
    selectedDeptLvOption: selectOptionType[];
    deptDatas;
    deptLv1: selectOptionType[];
    deptLv2: selectOptionType[];
    deptLv3: selectOptionType[];
    deptLv4: selectOptionType[];
    deptLv5: selectOptionType[];
    selectedDeptLv: number;
    setCheckedInfo;
    setDatas;
    setDeptLv1: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setDeptLv2: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setDeptLv3: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setDeptLv4: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setDeptLv5: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setSelectedDeptLv: React.Dispatch<React.SetStateAction<number>>;
    errorUserName: string;
    setErrorUserName: React.Dispatch<React.SetStateAction<string>>;
    errorUserDeptName: string;
    setErrorUserDeptName: React.Dispatch<React.SetStateAction<string>>;
    errorUserPost: string;
    setErrorUserPost: React.Dispatch<React.SetStateAction<string>>;
    errorTelephoneNumber: string;
    setErrorTelephoneNumber: React.Dispatch<React.SetStateAction<string>>;
    errorEmail: string;
    setErrorEmail: React.Dispatch<React.SetStateAction<string>>;
    errorPassword: string;
    setErrorPassword: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLvs: string;
    setErrorDeptLvs: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLv1: string;
    setErrorDeptLv1: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLv2: string;
    setErrorDeptLv2: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLv3: string;
    setErrorDeptLv3: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLv4: string;
    setErrorDeptLv4: React.Dispatch<React.SetStateAction<string>>;
    errorDeptLv5: string;
    setErrorDeptLv5: React.Dispatch<React.SetStateAction<string>>;
    is_dept_lv2_invalid: boolean;
    createButtonDisabled: boolean;
    checkboxToggles: boolean;
    setCheckboxToggles: React.Dispatch<React.SetStateAction<boolean>>;
    errorDummySideTel: string;
    setErrorDummySideTel: React.Dispatch<React.SetStateAction<string>>;
    errorDummySideEmail: string;
    setErrorDummySideEmail: React.Dispatch<React.SetStateAction<string>>;
    errorDummySidePass: string;
    setErrorDummySidePass: React.Dispatch<React.SetStateAction<string>>;
    isPlural: boolean;
    setIsPlural: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

export const User: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { pref_code, bank_code } = user;

  const [selectedDeptLvOption, setSelectedDeptLvOption] = useState([]);

  const [datas, setDatas] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({ id: -1, name: null });

  const [deptDatas, setDeptDatas] = useState([]);
  const [deptLv1, setDeptLv1] = useState([]);
  const [deptLv2, setDeptLv2] = useState([]);
  const [deptLv3, setDeptLv3] = useState([]);
  const [deptLv4, setDeptLv4] = useState([]);
  const [deptLv5, setDeptLv5] = useState([]);
  const [selectedDeptLv, setSelectedDeptLv] = useState(0);

  const [errorDeptLvs, setErrorDeptLvs] = useState('');
  const [errorDeptLv1, setErrorDeptLv1] = useState('');
  const [errorDeptLv2, setErrorDeptLv2] = useState('');
  const [errorDeptLv3, setErrorDeptLv3] = useState('');
  const [errorDeptLv4, setErrorDeptLv4] = useState('');
  const [errorDeptLv5, setErrorDeptLv5] = useState('');

  const [errorUserName, setErrorUserName] = useState('');
  const [errorUserDeptName, setErrorUserDeptName] = useState('');
  const [errorUserPost, setErrorUserPost] = useState('');
  const [errorTelephoneNumber, setErrorTelephoneNumber] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [errorDummySideTel, setErrorDummySideTel] = useState('');
  const [errorDummySideEmail, setErrorDummySideEmail] = useState('');
  const [errorDummySidePass, setErrorDummySidePass] = useState('');

  const setOffset = useSetAtom(offsetAtom);

  const [checkboxToggles, setCheckboxToggles] = React.useState(false);

  const tmp = useDeptLvOption(pref_code, bank_code);
  const deptLvOptions = tmp.length === 0 ? [{ key: '0', val: '' }] : tmp;

  const ja = useJaMaster(pref_code, bank_code);
  const is_dept_lv2_invalid = ja?.is_dept_lv2_invalid || false;

  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const [isPlural, setIsPlural] = useState(false);

  const handleChangeDeptLv = (e) => {
    setSelectedDeptLvOption([
      { key: e.target.value, val: deptLvOptions[e.target.selectedIndex].val },
    ]);

    setSelectedDeptLv(parseInt(e.target.value));

    setCheckedInfo({ id: -1, name: null });

    setOffset(0);

    const tmp = deptDatas.filter((x) => {
      return x.department_level_code === parseInt(e.target.value);
    });

    if (tmp.length === 0) {
      setCreateButtonDisabled(true);
    } else {
      setCreateButtonDisabled(false);
    }
  };

  const download = async () => {
    const res = await axios.post(
      '/api/user/download',
      { bank_code, user_type: USER_TYPE.USER },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/users', {
        params: {
          pref_code,
          bank_code,
          user_type: USER_TYPE.USER,
        },
      }),
      axios.get('/api/department-levels'),
      axios.get('/api/inspection-departments', {
        params: {
          pref_code,
          bank_code,
        },
      }),
    ]).then((res) => {
      // user + plural_user
      setDatas(res[0].data);

      const wk = res[1].data.map((x) => {
        return { key: x.department_level_code, val: x.department_level_name };
      });

      setSelectedDeptLvOption([{ key: wk[0].key, val: wk[0].val }]);

      setSelectedDeptLv(wk[0].key);

      setDeptDatas(res[2].data);

      setDeptLv1(
        res[2].data
          .filter((x) => {
            return x.department_level_code === 1;
          })
          .map((x) => {
            return {
              key: x.dept_code_lv1,
              val: x.dept_name_lv1,
            };
          })
      );

      setDeptLv2(
        res[2].data
          .filter((x) => {
            return x.department_level_code === 2;
          })
          .map((x) => {
            return {
              key: x.dept_code_lv2,
              val: x.dept_name_lv2,
            };
          })
      );

      const tmp = res[2].data.filter((x) => {
        return x.department_level_code === 1;
      });

      if (tmp.length === 0) {
        setCreateButtonDisabled(true);
      }
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            ユーザマスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl variant='outlined' style={{ width: '250px' }}>
              <InputLabel htmlFor='outlined-age-native-simple2'>
                階層レベルを選択
              </InputLabel>
              <Select
                native
                onChange={handleChangeDeptLv}
                label='階層レベルを選択'
                style={{
                  width: '250px',
                }}
              >
                {deptLvOptions &&
                  deptLvOptions.map((elm) => (
                    <option key={elm.key} value={elm.key}>
                      {elm.val}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>

          <UserContext.Provider
            value={{
              pref_code,
              bank_code,
              selectedDeptLvOption,
              deptDatas,
              deptLv1,
              deptLv2,
              deptLv3,
              deptLv4,
              deptLv5,
              selectedDeptLv,
              setCheckedInfo,
              setDatas,
              setDeptLv1,
              setDeptLv2,
              setDeptLv3,
              setDeptLv4,
              setDeptLv5,
              setSelectedDeptLv,
              errorUserName,
              setErrorUserName,
              errorUserDeptName,
              setErrorUserDeptName,
              errorUserPost,
              setErrorUserPost,
              errorTelephoneNumber,
              setErrorTelephoneNumber,
              errorEmail,
              setErrorEmail,
              errorPassword,
              setErrorPassword,
              errorDeptLvs,
              setErrorDeptLvs,
              errorDeptLv1,
              setErrorDeptLv1,
              errorDeptLv2,
              setErrorDeptLv2,
              errorDeptLv3,
              setErrorDeptLv3,
              errorDeptLv4,
              setErrorDeptLv4,
              errorDeptLv5,
              setErrorDeptLv5,
              is_dept_lv2_invalid,
              createButtonDisabled,
              checkboxToggles,
              setCheckboxToggles,
              errorDummySideTel,
              setErrorDummySideTel,
              errorDummySideEmail,
              setErrorDummySideEmail,
              errorDummySidePass,
              setErrorDummySidePass,
              isPlural,
              setIsPlural,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />
              <UpdateDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <Button
                variant='outlined'
                startIcon={<GetAppIcon />}
                onClick={download}
              >
                部署・ユーザ一覧
              </Button>
            </Box>

            <DataTable
              checklist={datas}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </UserContext.Provider>
        </main>
      }
    />
  );
};
