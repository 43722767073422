import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getHIEById } from 'utils/functions';
import { JaMasterForm } from 'views/components/forms/JaMasterForm';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC<{ datas; prefs; proc_type }> = (props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const [errorPrefCode, setErrorPrefCode] = useState('');
  const [errorBankCode, setErrorBankCode] = useState('');
  const [errorJaName, setErrorJaName] = useState('');
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [errorAddress, setErrorAddress] = useState('');
  const [errorStartMonth, setErrorStartMonth] = useState('');
  const [errorContactPersonnelName, setErrorContactPersonnelName] =
    useState('');
  const [errorContactPersonnelDeptName, setErrorContactPersonnelDeptName] =
    useState('');
  const [errorContactPersonnelRole, setErrorContactPersonnelRole] =
    useState('');
  const [
    errorContactPersonnelPhoneNumber,
    setErrorContactPersonnelPhoneNumber,
  ] = useState('');
  const [errorContactPersonnelEmail, setErrorContactPersonnelEmail] =
    useState('');
  const [errorRemarks, setErrorRemarks] = useState('');

  const handleOpen = () => {
    setIsOpen(true);
    setErrorPrefCode('');
    setErrorBankCode('');
    setErrorJaName('');
    setErrorPostalCode('');
    setErrorAddress('');
    setErrorStartMonth('');
    setErrorContactPersonnelName('');
    setErrorContactPersonnelDeptName('');
    setErrorContactPersonnelRole('');
    setErrorContactPersonnelPhoneNumber('');
    setErrorContactPersonnelEmail('');
    setErrorRemarks('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCreate = () => {
    const pref_code = getHIEById('#pref-code').value;
    const bank_code = getHIEById('#bank-code').value;
    const ja_name = getHIEById('#ja-name').value;
    const ja_nickname = getHIEById('#ja-nickname').value;
    const fiscal_year = getHIEById('#fiscal-year').value;
    const start_month = getHIEById('#start-month').value;
    const address = getHIEById('#address').value;
    const contact_name = getHIEById('#contact-name').value;
    const contact_dept_name = getHIEById('#contact-dept-name').value;
    const contact_role = getHIEById('#contact-role').value;
    const contact_phone_number = getHIEById('#contact-phone-number').value;
    const contact_email = getHIEById('#contact-email').value;
    const remarks = getHIEById('#remarks').value;
    const is_public = getHIEById('#is-public').checked;
    const is_dept_lv2_invalid = getHIEById('#is-dept-lv2-invalid').checked;

    axios
      .post('/api/ja-master/create', {
        pref_code,
        bank_code,
        ja_name,
        ja_nickname,
        fiscal_year,
        start_month,
        address,
        contact_name,
        contact_dept_name,
        contact_role,
        contact_phone_number,
        contact_email,
        remarks,
        is_public,
        is_dept_lv2_invalid,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.pref_code
          ? setErrorPrefCode(errors.pref_code.errorMsg)
          : setErrorPrefCode('');
        errors.bank_code
          ? setErrorBankCode(errors.bank_code.errorMsg)
          : setErrorBankCode('');
        errors.ja_name
          ? setErrorJaName(errors.ja_name.errorMsg)
          : setErrorJaName('');
        errors.ja_nickname
          ? setErrorPostalCode(errors.ja_nickname.errorMsg)
          : setErrorPostalCode('');
        errors.address
          ? setErrorAddress(errors.address.errorMsg)
          : setErrorAddress('');
        errors.start_month
          ? setErrorStartMonth(errors.start_month.errorMsg)
          : setErrorStartMonth('');
        errors.contact_name
          ? setErrorContactPersonnelName(errors.contact_name.errorMsg)
          : setErrorContactPersonnelName('');
        errors.contact_dept_name
          ? setErrorContactPersonnelDeptName(errors.contact_dept_name.errorMsg)
          : setErrorContactPersonnelDeptName('');
        errors.contact_role
          ? setErrorContactPersonnelRole(errors.contact_role.errorMsg)
          : setErrorContactPersonnelRole('');
        errors.contact_phone_number
          ? setErrorContactPersonnelPhoneNumber(
              errors.contact_phone_number.errorMsg
            )
          : setErrorContactPersonnelPhoneNumber('');
        errors.contact_email
          ? setErrorContactPersonnelEmail(errors.contact_email.errorMsg)
          : setErrorContactPersonnelEmail('');
        errors.remarks
          ? setErrorRemarks(errors.remarks.errorMsg)
          : setErrorRemarks('');

        if (errors.pref_code && !errors.contact_name) {
          setErrorPrefCode('　');
        } else if (!errors.pref_code && errors.contact_name) {
          setErrorPrefCode('　');
        }

        if (errors.bank_code && !errors.contact_dept_name) {
          setErrorContactPersonnelDeptName('　');
        } else if (!errors.bank_code && errors.contact_dept_name) {
          setErrorBankCode('　');
        }

        if (errors.ja_name && !errors.contact_role) {
          setErrorContactPersonnelRole('　');
        } else if (!errors.ja_name && errors.contact_role) {
          setErrorJaName('　');
        }

        if (errors.ja_nickname && !errors.contact_phone_number) {
          setErrorContactPersonnelPhoneNumber('　');
        } else if (!errors.ja_nickname && errors.contact_phone_number) {
          setErrorPostalCode('　');
        }

        if (errors.start_month && !errors.contact_email) {
          setErrorRemarks('　');
        } else if (!errors.start_month && errors.contact_email) {
          setErrorStartMonth('　');
        }

        if (errors.address && !errors.remarks) {
          setErrorContactPersonnelEmail('　');
        } else if (!errors.address && errors.remarks) {
          setErrorAddress('　');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='JA&thinsp;登録'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='JA&thinsp;登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <JaMasterForm
            classes={classes}
            items={{
              prefCode: {
                defaultValue: '',
                errMsg: errorPrefCode,
                isDisabled: false,
              },
              bankCode: {
                defaultValue: '',
                errMsg: errorBankCode,
                isDisabled: false,
              },
              jaName: {
                defaultValue: '',
                errMsg: errorJaName,
                isDisabled: false,
              },
              jaNickname: {
                defaultValue: '',
                errMsg: errorPostalCode,
                isDisabled: false,
              },
              fiscalYear: {
                defaultValue: 1,
                errMsg: errorStartMonth,
                isDisabled: false,
              },
              startMonth: {
                defaultValue: 1,
                errMsg: errorStartMonth,
                isDisabled: false,
              },
              address: {
                defaultValue: '',
                errMsg: errorAddress,
                isDisabled: false,
              },
              contactPersonnelName: {
                defaultValue: '',
                errMsg: errorContactPersonnelName,
                isDisabled: false,
              },
              contactPersonnelDeptName: {
                defaultValue: '',
                errMsg: errorContactPersonnelDeptName,
                isDisabled: false,
              },
              contactPersonnelRole: {
                defaultValue: '',
                errMsg: errorContactPersonnelRole,
                isDisabled: false,
              },
              contactPersonnelPhoneNumber: {
                defaultValue: '',
                errMsg: errorContactPersonnelPhoneNumber,
                isDisabled: false,
              },
              contactPersonnelEmail: {
                defaultValue: '',
                errMsg: errorContactPersonnelEmail,
                isDisabled: false,
              },
              remarks: {
                defaultValue: '',
                errMsg: errorRemarks,
                isDisabled: false,
              },
              is_public: {
                defaultValue: false,
                isDisabled: false,
              },
            }}
            options={{
              prefs: props.prefs,
              startMonths: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3].map((x) => {
                return { key: x, val: x + '月' };
              }),
            }}
            proc_type={props.proc_type}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='JA&thinsp;を登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
