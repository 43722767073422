import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Divider, DialogContent } from '@material-ui/core';

import { HiddenColumnMasterContext } from 'views/pages/HiddenColumnMaster';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import SelectBoxField from 'views/molecules/SelectBoxField';
import InputUploadField from 'views/molecules/InputUploadField';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';
import axios from 'axios';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
  selectBox: {
    marginTop: 12,
    width: '100%',
  },
});

const UpdateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    owner_user_type,
    pref_code,
    bank_code,
    fileRef,
    checkedInfo,
    categoryOption,
    setDatas,
    setOrgDatas,
    selectedFiscalYearOption,
    fiscalYearOption,
  } = React.useContext(HiddenColumnMasterContext);
  const [isOpen, setIsOpen] = useState(false);
  const [errorFile, setErrorFile] = useState('');

  const handleOpen = () => {
    setIsOpen(true);
    setErrorFile('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = () => {
    const formData = new FormData();

    formData.append('fiscal_year', selectedFiscalYearOption);
    formData.append('pref_code', pref_code);
    formData.append('owner_user_type', owner_user_type);
    formData.append('bank_code', bank_code);
    formData.append('category_code', checkedInfo.category_code);
    formData.append('file', fileRef.current.files[0]);

    axios
      .post('/api/hidden-column/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setDatas(
          res.data.filter((x) => {
            return x.fiscal_year === selectedFiscalYearOption;
          })
        );

        setOrgDatas(res.data);

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.file ? setErrorFile(errors.file.errorMsg) : setErrorFile('');
      });
  };

  return (
    <Box component='span'>
      <DialogButtonUpdate
        buttonLabel='非表示列設定'
        onClickActionHandler={handleOpen}
        disabled={checkedInfo.category_code === -1}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitleUpdate
          titleName='非表示列設定'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <SelectBoxField
            id='fiscal-year'
            label='検査年度'
            className={classes.selectBox}
            defaultValue={selectedFiscalYearOption}
            options={fiscalYearOption}
            isDisabled={true}
          />
          <SelectBoxField
            id='cate'
            label='チェックリスト区分'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={categoryOption}
            isDisabled={true}
          />
          <InputUploadField
            id='file'
            label='非表示列設定テンプレート'
            className={classes.inputField}
            defaultValue=''
            errorMsg={errorFile}
            checklistRef={fileRef}
          />
        </DialogContent>
        <Divider />
        <DialogActionsUpdate
          buttonLabel='非表示列を設定する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
