import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getHSEById } from 'utils/functions';
import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import { InspectionDepartmentForm } from 'views/components/forms/InspectionDepartmentForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    setErrorMsgs,
    setData,
    setOrgData,
    currFiscalYear,
  } = useContext(InspectionDepartmentContext);

  const hse = getHSEById('#dept-lv');
  const selectedDeptLv = parseInt(hse?.value);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorMsgs.inspectionDepartmentName('');
  };

  const handleUpdate = () => {
    const inspection_department_name = document.querySelector<HTMLInputElement>(
      '#inspection-department-name'
    ).value;

    axios
      .post('/api/inspection-department/update', {
        fiscal_year: currFiscalYear,
        pref_code,
        bank_code,
        department_level_code: selectedDeptLv,
        dept_code_lv1: props.checkedInfo.dept_code_lv1,
        dept_name_lv1: props.checkedInfo.dept_name_lv1,
        dept_code_lv2: props.checkedInfo.dept_code_lv2,
        dept_name_lv2: props.checkedInfo.dept_name_lv2,
        dept_code_lv3: props.checkedInfo.dept_code_lv3,
        dept_name_lv3: props.checkedInfo.dept_name_lv3,
        dept_code_lv4: props.checkedInfo.dept_code_lv4,
        dept_name_lv4: props.checkedInfo.dept_name_lv4,
        dept_code_lv5: props.checkedInfo.dept_code_lv5,
        dept_name_lv5: props.checkedInfo.dept_name_lv5,
        inspection_department_name,
      })
      .then((res) => {
        setData(
          res.data.filter((x) => {
            return (
              x.fiscal_year === currFiscalYear &&
              x.department_level_code === selectedDeptLv
            );
          })
        );

        setOrgData(res.data);

        switch (selectedDeptLv) {
          case 1: {
            props.checkedInfo.dept_name_lv1 = inspection_department_name;
            break;
          }
          case 2: {
            props.checkedInfo.dept_name_lv2 = inspection_department_name;
            break;
          }
          case 3: {
            props.checkedInfo.dept_name_lv3 = inspection_department_name;
            break;
          }
          case 4: {
            props.checkedInfo.dept_name_lv4 = inspection_department_name;
            break;
          }
          case 5: {
            props.checkedInfo.dept_name_lv5 = inspection_department_name;
            break;
          }
        }

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.inspection_department_name
          ? setErrorMsgs.inspectionDepartmentName(
              errors.inspection_department_name.errorMsg
            )
          : setErrorMsgs.inspectionDepartmentName('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='部署更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='部署更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <InspectionDepartmentForm
            isDisabled={{
              deptLv2: true,
              deptLv3: true,
              deptLv4: true,
              deptName: false,
            }}
            defaultValue={{
              deptLv1: props.checkedInfo.dept_name_lv1,
              deptLv2: props.checkedInfo.dept_name_lv2,
              deptLv3: props.checkedInfo.dept_name_lv3,
              deptLv4: props.checkedInfo.dept_name_lv4,
              deptLv5: props.checkedInfo.dept_name_lv5,
            }}
            targetDeptLv2={[
              {
                key: props.checkedInfo.dept_code_lv2,
                val: props.checkedInfo.dept_name_lv2,
              },
            ]}
            targetDeptLv3={[
              {
                key: props.checkedInfo.dept_code_lv3,
                val: props.checkedInfo.dept_name_lv3,
              },
            ]}
            targetDeptLv4={[
              {
                key: props.checkedInfo.dept_code_lv4,
                val: props.checkedInfo.dept_name_lv4,
              },
            ]}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='部署を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
