import React from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistControlContext } from 'views/pages/ChecklistControl';
import InputUploadField from 'views/molecules/InputUploadField';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import SelectBoxField from 'views/molecules/SelectBoxField';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC = () => {
  const classes = useStyles();

  const { currFiscalYear, pref_code, bank_code, fileRef, setData } =
    React.useContext(ChecklistControlContext);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [errorFile, setErrorFile] = React.useState('');

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorFile('');
  };

  const handleUpload = () => {
    const formData = new FormData();

    formData.append('fiscal_year', currFiscalYear.toString());
    formData.append('pref_code', pref_code);
    formData.append('bank_code', bank_code);
    formData.append('file', fileRef.current.files[0]);

    axios
      .post('/api/checklist-control/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setData(
          res.data.filter((x) => {
            return x.fiscal_year === currFiscalYear;
          })
        );

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.file ? setErrorFile(errors.file.errorMsg) : setErrorFile('');
      });
  };

  return (
    <Box component='span' style={{}}>
      <DialogButtonCreate
        buttonLabel='部署別チェックリスト登録'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='部署別チェックリスト登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <SelectBoxField
            id='year'
            label='検査年度'
            className={classes.inputField}
            defaultValue=''
            errorMsg=''
            options={[
              {
                key: currFiscalYear,
                val: `${currFiscalYear} 年度`,
              },
            ]}
            isDisabled={true}
          />

          <InputUploadField
            id='file'
            label='部署別チェックリストを選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorFile}
            checklistRef={fileRef}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='部署別チェックリストを登録する'
          onClickActionHandler={handleUpload}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
