import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import CreateDialog from 'views/components/dialogs/JaMaster/CreateDialog';
import UpdateDialog from 'views/components/dialogs/JaMaster/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/JaMaster/DeleteDialog';
import DataTable from 'views/components/tables/JaMaster/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { downloadExcel } from 'utils/functions';

export const JaMasterContext = React.createContext(
  {} as {
    setDatas;
    setDatasWk;
    fiscalYearOption;
    setFiscalYearOption;
    currFiscalYear;
    selectedFiscalYear;
    selectedStartMonth;
    setSelectedStartMonth;
  }
);

const JaMaster: React.FC = () => {
  const [prefs, setPrefs] = useState([]);
  const [datas, setDatas] = useState([]);
  const [datasWk, setDatasWk] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({ id: -1 });
  const [prefOptions, setPrefOptions] = useState([{ key: 0, val: 'すべて' }]);

  const setOffset = useSetAtom(offsetAtom);

  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [currFiscalYear, setCurrFiscalYear] = useState(0);
  const [perfCode, setPerfCode] = useState(0);

  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);

  const [selectedStartMonth, setSelectedStartMonth] = useState(0);

  useEffect(() => {
    Promise.all([
      axios.get('/api/fiscal-year-option-temp', {
        params: {
          owner_user_type: USER_TYPE.SYSTEM_ADMIN,
        },
      }),
      axios.get('/api/ja-masters'),
      axios.get('/api/prefectures'),
    ]).then((res) => {
      const fiscalYearAry = res[0].data.map((x) => {
        return { key: x.fiscal_year, val: `${x.fiscal_year} 年度` };
      });

      const curr_year = fiscalYearAry[0].key;

      setFiscalYearOption(fiscalYearAry);
      setCurrFiscalYear(curr_year);
      setSelectedFiscalYear(curr_year);

      const tmp = res[1].data[0].filter((x) => {
        return x.fiscal_year === curr_year;
      });

      const prefMap = new Map();

      tmp.map((x) => {
        prefMap.set(x.pref_code, {
          key: x.pref_code,
          val: x.pref_name,
        });
      });

      setPrefOptions([
        ...[{ key: 0, val: 'すべて' }],
        ...Array.from(prefMap.values()),
      ]);

      setPrefs(
        res[2].data[0].map((x) => {
          return {
            key: x.pref_code,
            val: x.pref_name,
          };
        })
      );

      setDatasWk(tmp);
      setDatas(res[1].data[0]);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = async () => {
    const res = await axios.post(
      '/api/ja-master/download',
      { fiscal_year: selectedFiscalYear },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  const handleChangeFiscalYear = (e) => {
    const targetYear = parseInt(e.target.value);
    setSelectedFiscalYear(targetYear);

    const tmp = datas.filter((x) => {
      return x.fiscal_year === targetYear;
    });

    const prefMap = new Map();

    tmp.map((x) => {
      prefMap.set(x.pref_code, {
        key: x.pref_code,
        val: x.pref_name,
      });
    });

    setPrefOptions([
      ...[{ key: 0, val: 'すべて' }],
      ...Array.from(prefMap.values()),
    ]);
    setPerfCode(0);

    setDatasWk(tmp);

    setCheckedInfo({ id: -1 });
    setOffset(0);
  };

  const handleChangePref = (e) => {
    const targetPref = e.target.value;
    setPerfCode(targetPref);

    const tmp = datas.filter((x) => {
      return (
        x.fiscal_year === selectedFiscalYear &&
        (targetPref == 0 || x.pref_code === targetPref)
      );
    });

    setDatasWk(tmp);

    setCheckedInfo({ id: -1 });
    setOffset(0);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            JA&thinsp;マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl
              variant='outlined'
              style={{ width: '250px', marginRight: 8 }}
            >
              <InputLabel
                shrink
                id='demo-simple-select-placeholder-label-label'
              >
                検査年度を選択
              </InputLabel>
              <Select
                native
                id='searchYear'
                value={selectedFiscalYear}
                onChange={handleChangeFiscalYear}
                label='検査年度を選択'
                style={{
                  width: '250px',
                }}
              >
                {fiscalYearOption.map((x, i) => (
                  <option value={x.key} key={i}>
                    {x.val}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl variant='outlined' style={{ width: '250px' }}>
              <InputLabel htmlFor='outlined-age-native-simple2'>
                都道府県を選択
              </InputLabel>
              <Select
                native
                id='searchPref'
                onChange={handleChangePref}
                label='都道府県を選択'
                style={{
                  width: '250px',
                }}
                value={perfCode}
              >
                {prefOptions.map((x, i) => (
                  <option value={x.key} key={i}>
                    {x.val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <JaMasterContext.Provider
            value={{
              setDatas,
              setDatasWk,
              fiscalYearOption,
              setFiscalYearOption,
              currFiscalYear,
              selectedFiscalYear,
              selectedStartMonth,
              setSelectedStartMonth,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog
                datas={datas}
                prefs={prefs}
                proc_type={PROC_TYPE.CREATE}
              />

              <UpdateDialog
                prefs={prefs}
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
              <DeleteDialog
                prefs={prefs}
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <Button
                variant='outlined'
                startIcon={<GetAppIcon />}
                onClick={download}
              >
                JA&thinsp;一覧
              </Button>
            </Box>

            <DataTable
              datas={datasWk}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </JaMasterContext.Provider>
        </main>
      }
    />
  );
};

export default JaMaster;
