import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { UserContext } from 'views/pages/User';
import { UserForm } from 'views/components/forms/UserForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{ checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    selectedDeptLv,
    setCheckedInfo,
    setDatas,
    setDeptLv1,
    setDeptLv2,
    setDeptLv3,
    setDeptLv4,
    setDeptLv5,
  } = React.useContext(UserContext);

  const {
    dept_code_lv1,
    dept_name_lv1,
    dept_code_lv2,
    dept_name_lv2,
    dept_code_lv3,
    dept_name_lv3,
    dept_code_lv4,
    dept_name_lv4,
    dept_code_lv5,
    dept_name_lv5,
    email,
  } = props.checkedInfo;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);

    setDeptLv1([
      {
        key: dept_code_lv1,
        val: dept_name_lv1,
      },
    ]);

    setDeptLv2([
      {
        key: dept_code_lv2,
        val: dept_name_lv2,
      },
    ]);

    setDeptLv3([
      {
        key: dept_code_lv3,
        val: dept_name_lv3,
      },
    ]);

    setDeptLv4([
      {
        key: dept_code_lv4,
        val: dept_name_lv4,
      },
    ]);

    setDeptLv5([
      {
        key: dept_code_lv5,
        val: dept_name_lv5,
      },
    ]);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    console.log(
      dept_code_lv1,
      dept_code_lv2,
      dept_code_lv3,
      dept_code_lv4,
      dept_code_lv5
    );

    axios
      .post('/api/user/delete', {
        pref_code,
        bank_code,
        department_level_code: selectedDeptLv,
        dept_code_lv1,
        dept_code_lv2,
        dept_code_lv3,
        dept_code_lv4,
        dept_code_lv5,
        email,
        user_type: USER_TYPE.USER,
      })
      .then((res) => {
        setDatas(res.data);
        setCheckedInfo({ id: -1, name: null });
        handleClose();
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='ユーザ削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='ユーザ削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <UserForm
            isDisabled={{
              dept_lv1: true,
              dept_lv2: true,
              dept_lv3: true,
              dept_lv4: true,
              dept_lv5: true,
              userName: true,
              userPost: true,
              telephoneNumber: true,
              email: true,
              password: false,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
            }}
            procType={PROC_TYPE.DELETE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='ユーザを削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
