import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InformationMasterForm } from 'views/components/forms/InformationMasterForm';
import { NewsInformationMasterContext } from 'views/pages/NewsInformationMaster';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  inputTextField: {
    marginTop: 12,
    // height: 30,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{ category; checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    setDatas,
    setDatasWk,
    category,
    setCategory,
    setValue,
    setCheckedInfo,
  } = React.useContext(NewsInformationMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    axios
      .post('/api/news_information/delete/master', {
        info_id: props.checkedInfo.id,
      })
      .then((res) => {
        setDatas(res.data);
        setCategory(category);
        const catenum: number = props.checkedInfo.category_code;
        let wk = [];
        wk = res.data.filter((x) => {
          return x.category_code === catenum;
        });
        setDatasWk(wk);
        setValue(catenum);
        setCheckedInfo({ id: -1 });
        handleClose();
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='情報削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='情報削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <InformationMasterForm
            classes={classes}
            items={{
              categoryCode: {
                defaultValue: props.checkedInfo.category_code,
                errMsg: '',
                isDisabled: true,
              },
              title: {
                defaultValue: props.checkedInfo.title,
                errMsg: '',
                isDisabled: true,
              },
              naiyou: {
                defaultValue: props.checkedInfo.naiyou,
                errMsg: '',
                isDisabled: true,
              },
              tmp_name1: {
                defaultValue: props.checkedInfo.tmp_name1,
                errMsg: '',
                isDisabled: true,
              },
              tmp_name2: {
                defaultValue: props.checkedInfo.tmp_name2,
                errMsg: '',
                isDisabled: true,
              },
              tmp_name3: {
                defaultValue: props.checkedInfo.tmp_name3,
                errMsg: '',
                isDisabled: true,
              },
            }}
            options={{
              category: props.category,
            }}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='情報を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
