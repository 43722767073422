import React from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';

import { convertJST, downloadExcel } from 'utils/functions';
import { ManualMasterContext } from 'views/pages/ManualMaster';
import axios from 'axios';
import { useAtom } from 'jotai';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC = () => {
  const classes = useStyles();

  const {
    datas,
    setDatas,
    orgDatas,
    checkedInfo,
    setCheckedInfo,
    categoryOpt,
    selectedCategory,
    setSelectedCategory,
    setSelectedFileName,
  } = React.useContext(ManualMasterContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setCheckedInfo({ manual_id: -1 });
    setOffset(offset);
  };

  const handleChange = (e, cate) => {
    setDatas(
      orgDatas.filter((x) => {
        return x.category_id === cate;
      })
    );

    setSelectedCategory(cate);

    setOffset(0);
  };

  const handleCheckbox = (info) => {
    if (
      info.category_id === checkedInfo.category_id &&
      info.manual_id === checkedInfo.manual_id
    ) {
      setCheckedInfo({ manual_id: -1 });
    } else {
      setCheckedInfo({
        category_id: info.category_id,
        category_name: info.category_name,
        manual_id: info.manual_id,
        manual_name: info.manual_name,
        extension: info.extension,
      });

      setSelectedFileName(`${info.manual_name}.${info.extension}`);
    }
  };

  const handleDownload = async (category_id: number, manual_id: number) => {
    const res = await axios.post(
      '/api/manual/download',
      { category_id, manual_id },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Tabs
          value={selectedCategory}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgba(0,0,0,0)',
              height: '10px',
            },
          }}
          onChange={handleChange}
          variant='fullWidth'
          centered
          style={{
            color: 'white',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {categoryOpt.map((x) => (
            <Tab value={x.key} key={x.key} label={x.val}></Tab>
          ))}
        </Tabs>

        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableBody>
            {datas?.slice(offset, offset + parPage).map((x, i) => (
              <StyledTableRow style={{ height: '50px' }} key={i}>
                <StyledTableCell align='center' style={{ width: '10%' }}>
                  <input
                    type='checkbox'
                    checked={
                      checkedInfo.category_id === x.category_id &&
                      checkedInfo.manual_id === x.manual_id
                    }
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, padding: 28 }}
                    onChange={() => {
                      handleCheckbox(x);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>
                  {convertJST(x.updatedAt).substring(0, 10)}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(x.category_id, x.manual_id);
                    }}
                  >
                    {x.manual_name}.{x.extension}
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {datas.length > PAR_PAGE && (
        <JICSPagination
          length={datas.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
