import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { JaMasterContext } from 'views/pages/JaMaster';
import { getHIEById } from 'utils/functions';
import { JaMasterForm } from 'views/components/forms/JaMasterForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{ prefs; checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const { selectedFiscalYear } = React.useContext(JaMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const [errorDelete, setErrorDelete] = useState('');

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);

    setErrorDelete('');
  };

  const handleDelete = () => {
    const pref_code = getHIEById('#pref-code').value;
    const bank_code = getHIEById('#bank-code').value;

    axios
      .post('/api/ja-master/delete', {
        fiscal_year: selectedFiscalYear,
        pref_code,
        bank_code,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        setErrorDelete(errors.delete.errorMsg);
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='JA&thinsp;削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='JA&thinsp;削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <JaMasterForm
            classes={classes}
            items={{
              prefCode: {
                defaultValue: props.checkedInfo.pref_code,
                errMsg: '',
                isDisabled: true,
              },
              bankCode: {
                defaultValue: props.checkedInfo.bank_code,
                errMsg: '',
                isDisabled: true,
              },
              jaName: {
                defaultValue: props.checkedInfo.ja_name,
                isDisabled: true,
                errMsg: '',
              },
              jaNickname: {
                defaultValue: props.checkedInfo.ja_nickname,
                isDisabled: true,
                errMsg: '',
              },
              fiscalYear: {
                defaultValue: props.checkedInfo.fiscal_year,
                errMsg: '',
                isDisabled: true,
              },
              startMonth: {
                defaultValue: props.checkedInfo.start_month,
                errMsg: '',
                isDisabled: true,
              },
              address: {
                defaultValue: props.checkedInfo.address,
                isDisabled: true,
                errMsg: '',
              },
              contactPersonnelName: {
                defaultValue: props.checkedInfo.contact_name,
                isDisabled: true,
                errMsg: '',
              },
              contactPersonnelDeptName: {
                defaultValue: props.checkedInfo.contact_dept_name,
                isDisabled: true,
                errMsg: '',
              },
              contactPersonnelRole: {
                defaultValue: props.checkedInfo.contact_role,
                isDisabled: true,
                errMsg: '',
              },
              contactPersonnelPhoneNumber: {
                defaultValue: props.checkedInfo.contact_phone_number,
                isDisabled: true,
                errMsg: '',
              },
              contactPersonnelEmail: {
                defaultValue: props.checkedInfo.contact_email,
                isDisabled: true,
                errMsg: '',
              },
              remarks: {
                defaultValue: props.checkedInfo.remarks,
                errMsg: '',
                isDisabled: true,
              },
              is_public: {
                defaultValue: props.checkedInfo.is_public,
                isDisabled: true,
              },
            }}
            options={{
              prefs: props.prefs,
              startMonths: [props.checkedInfo.start_month].map((x) => {
                return { key: x, val: x + '月' };
              }),
            }}
            errorDelete={errorDelete}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='JA&thinsp;を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
