import { useEffect, useState } from 'react';

import axios from 'axios';

import { selectOptionType } from 'types/common';
import { useJaMaster } from 'hooks/useJaMaster';

export const useDeptLvOption = (
  pref_code: string,
  bank_code: string
): selectOptionType[] => {
  const [deptLvOption, setDeptLvOption] = useState([]);

  const ja = useJaMaster(pref_code, bank_code);
  const is_dept_lv2_invalid = ja?.is_dept_lv2_invalid || false;

  useEffect(() => {
    axios.get('/api/department-levels').then((res) => {
      setDeptLvOption(
        res.data
          .filter((x) => {
            return !is_dept_lv2_invalid || x.department_level_code !== 2;
          })
          .map((x) => {
            return {
              key: x.department_level_code,
              val: x.department_level_name,
            };
          })
      );
    });
  }, [is_dept_lv2_invalid]);

  return deptLvOption;
};
