import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE } from 'utils/constants';
import { InspectionCategoryContext } from 'views/pages/InspectionCategory';
import { InspectionCategoryForm } from 'views/components/forms/InspectionCategoryForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    owner_user_type,
    setData,
    setOrgData,
    setCheckedInfo,
    setErrorCategoryName,
    currFiscalYear,
  } = useContext(InspectionCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setErrorCategoryName('');
  };

  const handleDelete = () => {
    axios
      .post('/api/inspection-category/delete', {
        fiscal_year: currFiscalYear,
        owner_user_type,
        category_code: props.checkedInfo.category_code,
      })
      .then((res) => {
        const tmp = res.data.filter((x) => {
          return x.fiscal_year == currFiscalYear;
        });

        setData(tmp);
        setOrgData(res.data);

        setCheckedInfo({ id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        setErrorCategoryName(errors.delete.errorMsg);
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='チェックリスト区分削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='チェックリスト区分削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <InspectionCategoryForm
            defaultValue={props.checkedInfo.category_name}
            procType={PROC_TYPE.DELETE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='チェックリスト区分を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
