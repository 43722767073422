import React, { createContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { USER_TYPE } from 'utils/constants';
import { downloadExcel } from 'utils/functions';
import { selectOptionType } from 'types/common';
import DataTable from 'views/components/tables/PrefCentralAdmin/DataTable';
import CreateDialog from 'views/components/dialogs/PrefCentralAdmin/CreateDialog';
import UpdateDialog from 'views/components/dialogs/PrefCentralAdmin/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/PrefCentralAdmin/DeleteDialog';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const PrefCentralAdminContext = createContext(
  {} as {
    prefCentral: selectOptionType[];
    orgPrefCentral: selectOptionType[];
    setPrefCentral: React.Dispatch<React.SetStateAction<selectOptionType>[]>;
    setPrefCentralOption;
    setCheckedInfo;
    orgDatas;
    setDatas;
    setOrgDatas;
    errorUserName: string;
    setErrorUserName: React.Dispatch<React.SetStateAction<string>>;
    errorUserDeptName: string;
    setErrorUserDeptName: React.Dispatch<React.SetStateAction<string>>;
    errorUserPost: string;
    setErrorUserPost: React.Dispatch<React.SetStateAction<string>>;
    errorTelephoneNumber: string;
    setErrorTelephoneNumber: React.Dispatch<React.SetStateAction<string>>;
    errorEmail: string;
    setErrorEmail: React.Dispatch<React.SetStateAction<string>>;
    errorPassword: string;
    setErrorPassword: React.Dispatch<React.SetStateAction<string>>;
    checkboxToggles: boolean;
    setCheckboxToggles: React.Dispatch<React.SetStateAction<boolean>>;
    errorPrefCode: string;
    setErrorPrefCode: React.Dispatch<React.SetStateAction<string>>;
    errorDummySideDept: string;
    setErrorDummySideDept: React.Dispatch<React.SetStateAction<string>>;
    errorDummySidePost: string;
    setErrorDummySidePost: React.Dispatch<React.SetStateAction<string>>;
    errorDummySideTel: string;
    setErrorDummySideTel: React.Dispatch<React.SetStateAction<string>>;
    errorDummySideEmail: string;
    setErrorDummySideEmail: React.Dispatch<React.SetStateAction<string>>;
    errorDummySidePass: string;
    setErrorDummySidePass: React.Dispatch<React.SetStateAction<string>>;
  }
);

export const PrefCentralAdmin: React.FC = () => {
  const [prefCentral, setPrefCentral] = useState([]);
  const [orgPrefCentral, setOrgPrefCentral] = useState([]);
  const [prefCentralOption, setPrefCentralOption] = useState([
    { key: '00', val: 'すべて' },
  ]);
  const [datas, setDatas] = useState([]);
  const [orgDatas, setOrgDatas] = useState([]);
  const [checkedInfo, setCheckedInfo] = useState({ id: -1 });

  const [errorUserName, setErrorUserName] = useState('');
  const [errorUserDeptName, setErrorUserDeptName] = useState('');
  const [errorUserPost, setErrorUserPost] = useState('');
  const [errorTelephoneNumber, setErrorTelephoneNumber] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [errorPrefCode, setErrorPrefCode] = useState('');
  const [errorDummySideDept, setErrorDummySideDept] = useState('');
  const [errorDummySidePost, setErrorDummySidePost] = useState('');
  const [errorDummySideTel, setErrorDummySideTel] = useState('');
  const [errorDummySideEmail, setErrorDummySideEmail] = useState('');
  const [errorDummySidePass, setErrorDummySidePass] = useState('');

  const setOffset = useSetAtom(offsetAtom);

  const [checkboxToggles, setCheckboxToggles] = React.useState(false);

  const handleChange = (e) => {
    const pref_code = e.target.value;

    if (pref_code === '00') {
      setDatas(orgDatas);
    } else {
      setDatas(
        orgDatas.filter((x) => {
          return x.pref_code === pref_code;
        })
      );
    }

    setCheckedInfo({ id: -1 });
    setOffset(0);
  };

  const download = async () => {
    const res = await axios.post(
      '/api/pref-central-admin/download',
      { user_type: USER_TYPE.PREF_ADMIN },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/prefectures'),
      axios.get('/api/pref-central-admins', {
        params: {
          user_type: USER_TYPE.PREF_ADMIN,
        },
      }),
    ]).then((res) => {
      setPrefCentral(
        res[0].data[0].map((x) => {
          return {
            key: x.pref_code,
            val: x.pref_central_name,
          };
        })
      );

      setOrgPrefCentral(
        res[0].data[0].map((x) => {
          return {
            key: x.pref_code,
            val: x.pref_central_name,
          };
        })
      );

      setPrefCentralOption([
        ...prefCentralOption,
        ...res[1].data[0]
          .map((x) => {
            return {
              key: x.pref_code,
              val: x.pref_central_name,
            };
          })
          .filter(
            (elm, idx, self) =>
              self.findIndex((x) => x.key === elm.key && x.val === elm.val) ===
              idx
          ),
      ]);

      setDatas(res[1].data[0]);
      setOrgDatas(res[1].data[0]);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            都道府県中央会管理者マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl variant='outlined' style={{ width: '300px' }}>
              <InputLabel htmlFor='outlined-age-native-simple2'>
                都道府県中央会を選択
              </InputLabel>
              <Select
                id='selected-pref'
                native
                onChange={handleChange}
                label='都道府県中央会を選択'
                style={{
                  width: '300px',
                }}
              >
                {prefCentralOption.map((x) => (
                  <option key={x.key} value={x.key}>
                    {x.val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <PrefCentralAdminContext.Provider
            value={{
              prefCentral,
              orgPrefCentral,
              setPrefCentral,
              setPrefCentralOption,
              setCheckedInfo,
              orgDatas,
              setDatas,
              setOrgDatas,
              errorUserName,
              setErrorUserName,
              errorUserDeptName,
              setErrorUserDeptName,
              errorUserPost,
              setErrorUserPost,
              errorTelephoneNumber,
              setErrorTelephoneNumber,
              errorEmail,
              setErrorEmail,
              errorPassword,
              setErrorPassword,
              checkboxToggles,
              setCheckboxToggles,
              errorPrefCode,
              setErrorPrefCode,
              errorDummySideDept,
              setErrorDummySideDept,
              errorDummySidePost,
              setErrorDummySidePost,
              errorDummySideTel,
              setErrorDummySideTel,
              errorDummySideEmail,
              setErrorDummySideEmail,
              errorDummySidePass,
              setErrorDummySidePass,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />
              <UpdateDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />

              <Button
                variant='outlined'
                startIcon={<GetAppIcon />}
                onClick={download}
              >
                管理者一覧
              </Button>
            </Box>

            <DataTable
              data={datas}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </PrefCentralAdminContext.Provider>
        </main>
      }
    />
  );
};
