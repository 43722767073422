import React, { useContext } from 'react';
import axios from 'axios';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { ChecklistTempContext } from 'views/pages/ChecklistTemp';
import { convertJST, downloadExcel } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  checklist;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const { pref_code, bank_code, email } = useContext(ChecklistTempContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ checklist_code: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    checklist_code: number,
    checklist_name: string,
    comment: string
  ) => {
    if (checklist_code === props.checkedInfo.checklist_code) {
      props.setCheckedInfo({ checklist_code: -1 });
    } else {
      props.setCheckedInfo({ checklist_code, checklist_name, comment });
    }
  };

  const handleDownload = async (checklist_code: number) => {
    const res = await axios.post(
      '/api/checklist-temp/download',
      { pref_code, bank_code, email, checklist_code },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell style={{ width: '10%', textAlign: 'center' }}>
                選択
              </StyledTableCell>
              <StyledTableCell style={{ width: '35%' }}>
                チェックリスト
              </StyledTableCell>
              <StyledTableCell style={{ width: '35%' }}>
                コメント
              </StyledTableCell>
              <StyledTableCell>一時保存日時</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody style={{ height: '100' }}>
            {props.checklist?.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.checklist_code}>
                <StyledTableCell style={{ textAlign: 'center' }}>
                  <input
                    type='checkbox'
                    checked={
                      props.checkedInfo.checklist_code === elm.checklist_code
                    }
                    className='target'
                    color='default'
                    onChange={() => {
                      handleCheckbox(
                        elm.checklist_code,
                        elm.checklist_name,
                        elm.comment
                      );
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell component='th' scope='row'>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(elm.checklist_code);
                    }}
                  >
                    {elm.checklist_name}
                  </span>
                </StyledTableCell>

                <StyledTableCell>{elm.comment}</StyledTableCell>
                <StyledTableCell>{convertJST(elm.updatedAt)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.checklist.length > PAR_PAGE && (
        <JICSPagination
          length={props.checklist.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
