import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  makeStyles,
} from '@material-ui/core';

import { USER_TYPE } from 'utils/constants';
import { HeaderEx } from 'views/components/HeaderEx';
import Footer from 'views/components/Footer';
import SelectBoxField2 from 'views/molecules/SelectBoxField2';

const useStyles = makeStyles({
  inputFieldFirst: {
    marginTop: 0,
  },
  inputField: {
    marginTop: 16,
  },
});

const PluralSelect: React.FC = () => {
  const classes = useStyles();
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const { email, pref_code, bank_code } = user;

  const [datas, setDatas] = useState([]);
  const [pluOption, setPluOption] = useState([{ key: 0, val: '' }]);

  const [deptLv, setDeptLv] = useState(0);
  const [deptLv2invalid, setDeptLv2invalid] = useState(false);

  const [deptOptLv1, setDeptOptLv1] = useState([]);
  const [deptOptLv2, setDeptOptLv2] = useState([]);
  const [deptOptLv3, setDeptOptLv3] = useState([]);
  const [deptOptLv4, setDeptOptLv4] = useState([]);
  const [deptOptLv5, setDeptOptLv5] = useState([]);

  const [selectedKeyLv1, setSelectedKeyLv1] = useState(0);
  const [selectedKeyLv2, setSelectedKeyLv2] = useState(0);
  const [selectedKeyLv3, setSelectedKeyLv3] = useState(0);
  const [selectedKeyLv4, setSelectedKeyLv4] = useState(0);
  const [selectedKeyLv5, setSelectedKeyLv5] = useState(0);

  useEffect(() => {
    Promise.all([
      axios.get('/api/users', {
        params: {
          pref_code,
          bank_code,
          user_type: USER_TYPE.USER,
        },
      }),
      axios.get('/api/department-levels'),
      axios.get('/api/ja-master', { params: { pref_code, bank_code } }),
    ]).then((res) => {
      // 部署データを保存する plural_users+user
      const userData = res[0].data.filter((x) => {
        return x.email === email;
      });

      setDatas([...userData]);

      const deptLvOption = [];

      let pluralUser = Array.from(
        new Set([...userData].map((x) => x.department_level_code))
      );

      // LV2無効フラグをフィルターする
      if (res[2].data.is_dept_lv2_invalid) {
        setDeptLv2invalid(true);
        pluralUser = pluralUser
          .filter((x) => {
            return x !== 2;
          })
          .map((x) => x);
      }

      // 部署階層を取得
      res[1].data.forEach((x) => {
        pluralUser.map((y) => {
          if (y == x.department_level_code) {
            deptLvOption.push({
              key: x.department_level_code,
              val: x.department_level_name,
            });
          }
        });
      });

      setPluOption(deptLvOption);

      const target = parseInt(deptLvOption[0].key);

      setDeptLv(target);

      let lv1Opt, lv2Opt, lv3Opt, lv4Opt, lv5Opt, lv2Key;

      const tmp = [...userData];

      if (target === 1) {
        lv1Opt = getDeptLv1(tmp);
        setSelectedKeyLv1(lv1Opt[0].key);
        setDeptOptLv1(lv1Opt);
      }

      if (target >= 2 && !deptLv2invalid) {
        lv2Opt = getDeptLv2(target, tmp);
        setSelectedKeyLv2(lv2Opt[0].key);
        setDeptOptLv2(lv2Opt);
        lv2Key = lv2Opt[0].key;
      }

      if (target >= 3) {
        lv3Opt = getDeptLv3(target, tmp, lv2Key);
        setSelectedKeyLv3(lv3Opt[0].key);
        setDeptOptLv3(lv3Opt);
      }

      if (target >= 4) {
        lv4Opt = getDeptLv4(target, tmp, lv2Key, lv3Opt[0].key);
        setSelectedKeyLv4(lv4Opt[0].key);
        setDeptOptLv4(lv4Opt);
      }

      if (target >= 5) {
        lv5Opt = getDeptLv5(target, tmp, lv2Key, lv3Opt[0].key, lv4Opt[0].key);
        setSelectedKeyLv5(lv5Opt[0]?.key);
        setDeptOptLv5(lv5Opt);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 選択さた内容をクリアする
  const clearSelectedDatas = () => {
    setDeptOptLv1([]);
    setDeptOptLv2([]);
    setDeptOptLv3([]);
    setDeptOptLv4([]);
    setDeptOptLv5([]);

    setSelectedKeyLv1(0);
    setSelectedKeyLv2(0);
    setSelectedKeyLv3(0);
    setSelectedKeyLv4(0);
    setSelectedKeyLv5(0);
  };

  const handleChangePlu = (e) => {
    // 前回選択内容をクリアする
    clearSelectedDatas();
    // 選択内容を設定する

    const nowLv = parseInt(e.target.value);

    setDeptLv(nowLv);

    let lv1Opt, lv2Opt, lv3Opt, lv4Opt, lv5Opt, lv2Key;

    if (nowLv === 1) {
      lv1Opt = getDeptLv1(datas);
      setSelectedKeyLv1(lv1Opt[0].key);
      setDeptOptLv1(lv1Opt);
    }

    if (nowLv >= 2 && !deptLv2invalid) {
      lv2Opt = getDeptLv2(nowLv, datas);
      setSelectedKeyLv2(lv2Opt[0].key);
      setDeptOptLv2(lv2Opt);
      lv2Key = lv2Opt[0].key;
    }

    if (nowLv >= 3) {
      lv3Opt = getDeptLv3(nowLv, datas, lv2Key);
      setSelectedKeyLv3(lv3Opt[0].key);
      setDeptOptLv3(lv3Opt);
    }

    if (nowLv >= 4) {
      lv4Opt = getDeptLv4(nowLv, datas, lv2Key, lv3Opt[0].key);
      setSelectedKeyLv4(lv4Opt[0].key);
      setDeptOptLv4(lv4Opt);
    }

    if (nowLv >= 5) {
      lv5Opt = getDeptLv5(nowLv, datas, lv2Key, lv3Opt[0].key, lv4Opt[0].key);
      setSelectedKeyLv5(lv5Opt[0]?.key);
      setDeptOptLv5(lv5Opt);
    }
  };

  const getDeptLv1 = (data) => {
    return data
      .filter((x) => {
        return x.department_level_code === 1;
      })
      .map((x) => {
        return {
          key: x.dept_code_lv1,
          val: x.dept_name_lv1,
        };
      });
  };

  const getDeptLv2 = (department_level_code: number, data) => {
    const deptLv2Datas = data
      .filter((x) => {
        return x.department_level_code === department_level_code;
      })
      .map((x) => {
        return {
          key: x.dept_code_lv2,
          val: x.dept_name_lv2,
        };
      });

    // データの中の重複な選択肢を除外する
    return unique(deptLv2Datas);
  };

  const getDeptLv3 = (department_level_code: number, data, ...keyLv) => {
    const deptLv3Datas = data
      .filter((x) => {
        return x.department_level_code === department_level_code;
      })
      .filter((x) => {
        return keyLv[0] ? x.dept_code_lv2 === keyLv[0] : true;
      })
      .map((x) => {
        return {
          key: x.dept_code_lv3,
          val: x.dept_name_lv3,
        };
      });

    // データの中の重複な選択肢を除外する
    return unique(deptLv3Datas);
  };

  const getDeptLv4 = (department_level_code: number, data, ...keyLv) => {
    const deptLv4Datas = data
      .filter((x) => {
        return x.department_level_code === department_level_code;
      })
      .filter((x) => {
        return keyLv[0] ? x.dept_code_lv2 === keyLv[0] : true;
      })
      .filter((x) => {
        return x.dept_code_lv3 === keyLv[1];
      })
      .map((x) => {
        return {
          key: x.dept_code_lv4,
          val: x.dept_name_lv4,
        };
      });
    // データの中の重複な選択肢を除外する
    return unique(deptLv4Datas);
  };

  const getDeptLv5 = (department_level_code: number, data, ...keyLv) => {
    return data
      .filter((x) => {
        return x.department_level_code === department_level_code;
      })
      .filter((x) => {
        return keyLv[0] ? x.dept_code_lv2 === keyLv[0] : true;
      })
      .filter((x) => {
        return x.dept_code_lv3 === keyLv[1];
      })
      .filter((x) => {
        return x.dept_code_lv4 === keyLv[2];
      })
      .map((x) => {
        return {
          key: x.dept_code_lv5,
          val: x.dept_name_lv5,
        };
      });
  };

  const unique = (deptDatas) => {
    // データの中の重複な選択肢を除外する
    const data = [];
    const finalData = [];
    for (let i = 0; i < deptDatas.length; i++) {
      data[deptDatas[i].key] = deptDatas[i];
    }
    for (const item in data) {
      finalData.push(data[item]);
    }

    return finalData;
  };

  /*
   * 階層レベルⅠの所属部署の制御メソッド
   */
  const handleChangeLv1 = (e) => {
    const keyLv1 = parseInt(e.target.value);
    // 選択内容を保存する
    setSelectedKeyLv1(keyLv1);
  };

  /*
   * 階層レベルⅡの所属部署の制御メソッド
   */
  const handleChangeLv2 = (e) => {
    const keyLv2 = parseInt(e.target.value);
    // 選択内容を保存する
    setSelectedKeyLv2(keyLv2);

    let lv3Opt, lv4Opt, lv5Opt;
    let keyLv3, keyLv4;

    if (deptLv >= 3) {
      lv3Opt = getDeptLv3(deptLv, datas, keyLv2);
      setSelectedKeyLv3(lv3Opt[0].key);
      setDeptOptLv3(lv3Opt);

      keyLv3 = lv3Opt[0].key;
    }

    if (deptLv >= 4) {
      lv4Opt = getDeptLv4(deptLv, datas, keyLv2, keyLv3);
      setSelectedKeyLv4(lv4Opt[0].key);
      setDeptOptLv4(lv4Opt);

      keyLv4 = lv4Opt[0].key;
    }

    if (deptLv >= 5) {
      lv5Opt = getDeptLv5(deptLv, datas, keyLv2, keyLv3, keyLv4);
      setSelectedKeyLv5(lv5Opt[0].key);
      setDeptOptLv5(lv5Opt);
    }
  };

  /*
   * 階層レベルⅢの所属部署の制御メソッド
   */
  const handleChangeLv3 = (e) => {
    const keyLv3 = parseInt(e.target.value);
    // 選択内容を保存する
    setSelectedKeyLv3(keyLv3);

    let lv4Opt, lv5Opt;
    let keyLv4;

    if (deptLv >= 4) {
      lv4Opt = getDeptLv4(deptLv, datas, selectedKeyLv2, keyLv3);
      setSelectedKeyLv4(lv4Opt[0].key);
      setDeptOptLv4(lv4Opt);

      keyLv4 = lv4Opt[0].key;
    }

    if (deptLv >= 5) {
      lv5Opt = getDeptLv5(deptLv, datas, selectedKeyLv2, keyLv3, keyLv4);
      setSelectedKeyLv5(lv5Opt[0].key);
      setDeptOptLv5(lv5Opt);
    }
  };

  /*
   * 階層レベルⅣの所属部署の制御メソッド
   */
  const handleChangeLv4 = (e) => {
    const keyLv4 = parseInt(e.target.value);
    // 選択内容を保存する
    setSelectedKeyLv4(keyLv4);

    let lv5Opt;

    if (deptLv >= 5) {
      lv5Opt = getDeptLv5(
        deptLv,
        datas,
        selectedKeyLv2,
        selectedKeyLv3,
        keyLv4
      );
      setSelectedKeyLv5(lv5Opt[0].key);
      setDeptOptLv5(lv5Opt);
    }
  };

  /*
   * 階層レベルⅤの所属部署の制御メソッド
   */
  const handleChangeLv5 = (e) => {
    const keyLv5 = parseInt(e.target.value);
    // 選択内容を保存する
    setSelectedKeyLv5(keyLv5);
  };

  /**
   * ログイン処理
   */
  const deptLogin = () => {
    let dept1name, dept2name, dept3name, dept4name, dept5name;

    deptOptLv1.forEach((opt) => {
      if (opt.key === selectedKeyLv1) {
        dept1name = opt.val;
      }
    });
    deptOptLv2.forEach((opt) => {
      if (opt.key === selectedKeyLv2) {
        dept2name = opt.val;
      }
    });
    deptOptLv3.forEach((opt) => {
      if (opt.key === selectedKeyLv3) {
        dept3name = opt.val;
      }
    });
    deptOptLv4.forEach((opt) => {
      if (opt.key === selectedKeyLv4) {
        dept4name = opt.val;
      }
    });
    deptOptLv5.forEach((opt) => {
      if (opt.key === selectedKeyLv5) {
        dept5name = opt.val;
      }
    });

    datas.forEach((data) => {
      if (
        deptLv == data.department_level_code &&
        (!dept1name || (dept1name && dept1name == data.dept_name_lv1)) &&
        (!dept2name || (dept2name && dept2name == data.dept_name_lv2)) &&
        (!dept3name || (dept3name && dept3name == data.dept_name_lv3)) &&
        (!dept4name || (dept4name && dept4name == data.dept_name_lv4)) &&
        (!dept5name || (dept5name && dept5name == data.dept_name_lv5))
      ) {
        user.department_level_code = data.department_level_code;
        user.telephone_number = data.telephone_number;
        user.user_dept_name = data.user_dept_name;
        user.user_name = data.user_name;
        user.user_post = data.user_post;
        user.user_type = 5;

        user.dept_code_lv1 = data.dept_code_lv1;
        user.dept_code_lv2 = data.dept_code_lv2;
        user.dept_code_lv3 = data.dept_code_lv3;
        user.dept_code_lv4 = data.dept_code_lv4;
        user.dept_code_lv5 = data.dept_code_lv5;

        user.dept_name_lv1 = data.dept_name_lv1;
        user.dept_name_lv2 = data.dept_name_lv2;
        user.dept_name_lv3 = data.dept_name_lv3;
        user.dept_name_lv4 = data.dept_name_lv4;
        user.dept_name_lv5 = data.dept_name_lv5;
      }
    });

    sessionStorage.setItem('user', JSON.stringify(user));
    window.location.href = '/home';
  };

  return (
    <div style={{ backgroundColor: '#EFEFEF' }}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#EFEFEF',
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <HeaderEx />

          <main>
            <Box
              style={{
                paddingTop: 40,
                textAlign: 'center',
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              検査部署選択
            </Box>

            <div
              style={{
                fontSize: '18px',
                paddingTop: 30,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControl variant='outlined' style={{ width: 300 }}>
                <SelectBoxField2
                  id='deptLv'
                  label='階層レベルを選択'
                  labelId=''
                  className={classes.inputFieldFirst}
                  value={deptLv}
                  errorMsg=''
                  options={pluOption}
                  onChange={handleChangePlu}
                  isDisabled={false}
                />
              </FormControl>

              <FormControl
                style={{
                  display: deptOptLv1.length > 0 ? '' : 'none',
                  width: 300,
                }}
              >
                {deptOptLv1 && selectedKeyLv1 && (
                  <SelectBoxField2
                    id='dept-lv1'
                    label='階層レベルⅠの部署を選択'
                    labelId=''
                    className={classes.inputField}
                    value={selectedKeyLv1}
                    errorMsg=''
                    options={deptOptLv1}
                    onChange={handleChangeLv1}
                    isDisabled={false}
                  />
                )}
              </FormControl>

              <FormControl
                style={{
                  display: deptOptLv2.length > 0 ? '' : 'none',
                  width: 300,
                }}
              >
                {deptOptLv2 && selectedKeyLv2 && (
                  <SelectBoxField2
                    id='dept-lv2'
                    label='階層レベルⅡの部署を選択'
                    labelId=''
                    className={classes.inputField}
                    value={selectedKeyLv2}
                    errorMsg=''
                    options={deptOptLv2}
                    onChange={handleChangeLv2}
                    isDisabled={false}
                  />
                )}
              </FormControl>

              <FormControl
                style={{
                  display: deptOptLv3.length > 0 ? '' : 'none',
                  width: 300,
                }}
              >
                {deptOptLv3 && selectedKeyLv3 && (
                  <SelectBoxField2
                    id='dept-lv3'
                    label='階層レベルⅢの部署を選択'
                    labelId=''
                    className={classes.inputField}
                    value={selectedKeyLv3}
                    errorMsg=''
                    options={deptOptLv3}
                    onChange={handleChangeLv3}
                    isDisabled={false}
                  />
                )}
              </FormControl>

              <FormControl
                style={{
                  display: deptOptLv4.length > 0 ? '' : 'none',
                  width: 300,
                }}
              >
                {deptOptLv4 && selectedKeyLv4 && (
                  <SelectBoxField2
                    id='dept-lv4'
                    label='階層レベルⅣの部署を選択'
                    labelId=''
                    className={classes.inputField}
                    value={selectedKeyLv4}
                    errorMsg=''
                    options={deptOptLv4}
                    onChange={handleChangeLv4}
                    isDisabled={false}
                  />
                )}
              </FormControl>

              <FormControl
                style={{
                  display: deptOptLv5.length > 0 ? '' : 'none',
                  width: 300,
                }}
              >
                {deptOptLv5 && selectedKeyLv5 && (
                  <SelectBoxField2
                    id='dept-lv5'
                    label='階層レベルⅤの部署を選択'
                    labelId=''
                    className={classes.inputField}
                    value={selectedKeyLv5}
                    errorMsg=''
                    options={deptOptLv5}
                    onChange={handleChangeLv5}
                    isDisabled={false}
                  />
                )}
              </FormControl>

              <Button
                variant='contained'
                color='primary'
                onClick={deptLogin}
                style={{ height: 50, marginTop: 16, width: 300 }}
              >
                検査部署を選択する
              </Button>
            </div>
          </main>

          <Footer />
        </Box>
      </Container>
    </div>
  );
};

export default PluralSelect;
