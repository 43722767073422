import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE } from 'utils/constants';
import { JaAdminContext } from 'views/pages/JaAdmin';
import { JaAdminForm } from 'views/components/forms/JaAdminForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { eventHandlers } = React.useContext(JaAdminContext);

  const handleOpen = () => {
    eventHandlers.setPrefCode([
      { key: props.checkedInfo.pref_code, val: props.checkedInfo.pref_name },
    ]);

    eventHandlers.setBankCode([
      { key: props.checkedInfo.bank_code, val: props.checkedInfo.bank_code },
    ]);

    eventHandlers.setJaName(props.checkedInfo.ja_name);

    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    axios
      .post('/api/ja-admin-master/delete', {
        pref_code: props.checkedInfo.pref_code,
        bank_code: props.checkedInfo.bank_code,
        email: props.checkedInfo.email,
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel='JA&thinsp;管理者削除'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='JA&thinsp;管理者削除'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <JaAdminForm
            isDisabled={{
              prefCode: true,
              bankCode: true,
              userName: true,
              userDeptName: true,
              userPost: true,
              telephoneNumber: true,
              email: true,
              password: true,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userDeptName: props.checkedInfo.user_dept_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
            }}
            procType={PROC_TYPE.DELETE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsDelete
          buttonLabel='JA&thinsp;管理者を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
