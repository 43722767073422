import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getHSEById } from 'utils/functions';
import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import { InspectionDepartmentForm } from 'views/components/forms/InspectionDepartmentForm';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    setErrorMsgs,
    setData,
    setOrgData,
    setCheckedInfo,
    createButtonDisabled,
    currFiscalYear,
    setSelectedFiscalYear,
  } = useContext(InspectionDepartmentContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorMsgs.inspectionDepartmentName('');
  };

  const handleCreate = () => {
    const selectedDeptLv = parseInt(getHSEById('#dept-lv').value);

    const inspection_department_name = document.querySelector<HTMLInputElement>(
      '#inspection-department-name'
    ).value;

    const dept_code_lv2 = document.querySelector<HTMLSelectElement>(
      '#inspection-department-code-2'
    );

    const dept_code_lv3 = document.querySelector<HTMLSelectElement>(
      '#inspection-department-code-3'
    );

    const dept_code_lv4 = document.querySelector<HTMLSelectElement>(
      '#inspection-department-code-4'
    );

    axios
      .post('/api/inspection-department/create', {
        fiscal_year: currFiscalYear,
        pref_code,
        bank_code,
        department_level_code: selectedDeptLv,
        inspection_department_name,
        dept_code_lv2: dept_code_lv2?.value,
        dept_name_lv2: dept_code_lv2?.options[dept_code_lv2.selectedIndex].text,
        dept_code_lv3: dept_code_lv3?.value,
        dept_name_lv3: dept_code_lv3?.options[dept_code_lv3.selectedIndex].text,
        dept_code_lv4: dept_code_lv4?.value,
        dept_name_lv4: dept_code_lv4?.options[dept_code_lv4.selectedIndex].text,
      })
      .then((res) => {
        setData(
          res.data.filter((x) => {
            return (
              x.fiscal_year === currFiscalYear &&
              x.department_level_code === selectedDeptLv
            );
          })
        );

        setSelectedFiscalYear(currFiscalYear);

        setOrgData(res.data);
        setCheckedInfo({ id: -1, name: null });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.inspection_department_name
          ? setErrorMsgs.inspectionDepartmentName(
              errors.inspection_department_name.errorMsg
            )
          : setErrorMsgs.inspectionDepartmentName('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='部署登録'
        onClickActionHandler={handleOpen}
        isDisabled={createButtonDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='部署登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <InspectionDepartmentForm />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='部署を登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
