import { Container } from '@material-ui/core';
import React from 'react';

const InternalServer: React.FC = () => {
  return (
    <div className='main-content'>
      <Container>
        <h1>500 Internal Server.</h1>
      </Container>
    </div>
  );
};
export default InternalServer;
