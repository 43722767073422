import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InformationMasterForm } from 'views/components/forms/InformationMasterForm';
import { NewsInformationMasterContext } from 'views/pages/NewsInformationMaster';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

import { getHIEById } from 'utils/functions';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  inputTextField: {
    marginTop: 12,
    // marginBottom: 12,
    // height: 30,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{ category; checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    defaultValues,
    setDatas,
    setDatasWk,
    category,
    setCategory,
    setValue,
  } = React.useContext(NewsInformationMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const [errorCategoryCode, setErrorCategoryCode] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorNaiyou, setErrorNaiyou] = useState('');
  const [errorTmpName1, setErrorTmpName1] = useState('');
  const [errorTmpName2, setErrorTmpName2] = useState('');
  const [errorTmpName3, setErrorTmpName3] = useState('');

  const handleOpen = () => {
    setIsOpen(true);
    setErrorCategoryCode('');
    setErrorTitle('');
    setErrorNaiyou('');
    setErrorTmpName1('');
    setErrorTmpName2('');
    setErrorTmpName3('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = () => {
    const tmpName1 = getHIEById('#tmp-name1').value;
    const tmpName2 = getHIEById('#tmp-name2').value;
    const tmpName3 = getHIEById('#tmp-name3').value;

    const formData = new FormData();

    formData.append('info_id', props.checkedInfo.id);
    formData.append(
      'category_code',
      document.querySelector<HTMLInputElement>('#cate-code').value
    );
    formData.append(
      'title',
      document.querySelector<HTMLInputElement>('#title').value
    );
    formData.append(
      'naiyou',
      document.querySelector<HTMLInputElement>('#naiyou').value
    );

    // props.checkedInfo.tmp_name1 = '';
    // props.checkedInfo.tmp_name2 = '';
    // props.checkedInfo.tmp_name3 = '';

    // if (tmpName1 && tmpName2 && tmpName3) {
    //   props.checkedInfo.tmp_name1 = tmpName1;
    //   props.checkedInfo.tmp_name2 = tmpName2;
    //   props.checkedInfo.tmp_name3 = tmpName3;
    // } else if (tmpName1 && tmpName2) {
    //   props.checkedInfo.tmp_name1 = tmpName1;
    //   props.checkedInfo.tmp_name2 = tmpName2;
    // } else if (tmpName2 && tmpName3) {
    //   props.checkedInfo.tmp_name1 = tmpName2;
    //   props.checkedInfo.tmp_name2 = tmpName3;
    // } else if (tmpName1 && tmpName3) {
    //   props.checkedInfo.tmp_name1 = tmpName1;
    //   props.checkedInfo.tmp_name2 = tmpName3;
    // } else if (tmpName1) {
    //   props.checkedInfo.tmp_name1 = tmpName1;
    // } else if (tmpName2) {
    //   props.checkedInfo.tmp_name1 = tmpName2;
    // } else if (tmpName3) {
    //   props.checkedInfo.tmp_name1 = tmpName3;
    // }

    formData.append('tmp_name1', tmpName1);
    formData.append('tmp_name2', tmpName2);
    formData.append('tmp_name3', tmpName3);

    formData.append('files', defaultValues.fileRef1.current.files[0]);
    formData.append('files', defaultValues.fileRef2.current.files[0]);
    formData.append('files', defaultValues.fileRef3.current.files[0]);

    axios
      .post('/api/news_information/update/master', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setDatas(res.data);
        setCategory(category);
        const catenum = parseInt(
          document.querySelector<HTMLInputElement>('#cate-code').value
        );
        let wk = [];
        wk = res.data.filter((x) => {
          return x.category_code === catenum;
        });
        setDatasWk(wk);
        setValue(catenum);

        props.checkedInfo.title =
          document.querySelector<HTMLInputElement>('#title').value;
        props.checkedInfo.naiyou =
          document.querySelector<HTMLInputElement>('#naiyou').value;

        props.checkedInfo.tmp_name1 = '';
        props.checkedInfo.tmp_name2 = '';
        props.checkedInfo.tmp_name3 = '';

        if (tmpName1 && tmpName2 && tmpName3) {
          props.checkedInfo.tmp_name1 = tmpName1;
          props.checkedInfo.tmp_name2 = tmpName2;
          props.checkedInfo.tmp_name3 = tmpName3;
        } else if (tmpName1 && tmpName2) {
          props.checkedInfo.tmp_name1 = tmpName1;
          props.checkedInfo.tmp_name2 = tmpName2;
        } else if (tmpName2 && tmpName3) {
          props.checkedInfo.tmp_name1 = tmpName2;
          props.checkedInfo.tmp_name2 = tmpName3;
        } else if (tmpName1 && tmpName3) {
          props.checkedInfo.tmp_name1 = tmpName1;
          props.checkedInfo.tmp_name2 = tmpName3;
        } else if (tmpName1) {
          props.checkedInfo.tmp_name1 = tmpName1;
        } else if (tmpName2) {
          props.checkedInfo.tmp_name1 = tmpName2;
        } else if (tmpName3) {
          props.checkedInfo.tmp_name1 = tmpName3;
        }

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.category_code
          ? setErrorCategoryCode(errors.category_code.errorMsg)
          : setErrorCategoryCode('');
        errors.title ? setErrorTitle(errors.title.errorMsg) : setErrorTitle('');
        errors.naiyou
          ? setErrorNaiyou(errors.naiyou.errorMsg)
          : setErrorNaiyou('');
        errors.tmp_name1
          ? setErrorTmpName1(errors.tmp_name1.errorMsg)
          : setErrorTmpName1('');
        errors.tmp_name2
          ? setErrorTmpName2(errors.tmp_name2.errorMsg)
          : setErrorTmpName2('');
        errors.tmp_name3
          ? setErrorTmpName3(errors.tmp_name3.errorMsg)
          : setErrorTmpName3('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='情報更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitleUpdate
          titleName='情報更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <InformationMasterForm
            classes={classes}
            items={{
              categoryCode: {
                defaultValue: props.checkedInfo.category_code,
                errMsg: errorCategoryCode,
                isDisabled: false,
              },
              title: {
                defaultValue: props.checkedInfo.title,
                errMsg: errorTitle,
                isDisabled: false,
              },
              naiyou: {
                defaultValue: props.checkedInfo.naiyou,
                errMsg: errorNaiyou,
                isDisabled: false,
              },
              tmp_name1: {
                defaultValue: props.checkedInfo.tmp_name1,
                errMsg: errorTmpName1,
                isDisabled: false,
              },
              tmp_name2: {
                defaultValue: props.checkedInfo.tmp_name2,
                errMsg: errorTmpName2,
                isDisabled: false,
              },
              tmp_name3: {
                defaultValue: props.checkedInfo.tmp_name3,
                errMsg: errorTmpName3,
                isDisabled: false,
              },
            }}
            options={{
              category: props.category,
            }}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='情報を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
