import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const ApproveDialog: React.FC<{
  checkedIds;
  checkedInfos;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();
  let isExistUnCheck = false;
  props.checkedInfos.forEach((checkedInfo) => {
    if (checkedInfo.check_status === 0) {
      isExistUnCheck = true;
    }
  });

  const {
    user_type,
    pref_code,
    selectedFiscalYear,
    setData,
    setOrgData,
    approvedIds,
    setApprovedIds,
    setApprovedInfo,
  } = React.useContext(InspectionSubCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleApproveChecklist = () => {
    Promise.all([
      axios.post('/api/inspection-subcategory/approve', {
        user_type,
        fiscal_year: selectedFiscalYear,
        pref_code,
        approvedInfo: JSON.stringify([...approvedIds]),
      }),

      axios.get('/api/approved_checklist'),
    ]).then((res) => {
      const tmpMap = new Map();

      const approvedMap = new Map();

      res[1].data
        .filter((x) => {
          return x.pref_code === pref_code;
        })
        .map((x) => {
          const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
          approvedMap.set(key, 'dummy');
        });

      res[0].data
        .filter((x) => {
          return x.fiscal_year == selectedFiscalYear;
        })
        .map((x) => {
          const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

          if (
            (!x.pref_code && !x.bank_code) ||
            (x.pref_code === pref_code && !x.bank_code)
          ) {
            if (approvedMap.get(key)) {
              x['check_status'] = 1;
            } else {
              x['check_status'] = 0;
            }
            tmpMap.set(key, x);
          }
        });

      setApprovedInfo(res[1].data);

      setData(Array.from(tmpMap.values()));
      setOrgData(res[0].data);

      const chk = document.querySelectorAll<HTMLInputElement>(
        "input[type='checkbox']"
      );

      chk.forEach((x) => {
        x.checked = false;
      });

      setApprovedIds(new Map());

      handleClose();
    });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='チェックリスト承認'
        onClickActionHandler={handleOpen}
        isDisabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='チェックリスト承認'
          onClickActionHandler={handleClose}
        />

        <Divider />

        {isExistUnCheck && (
          <DialogContent>
            <span
              style={{
                color: 'red',
                fontSize: 13,
                textAlign: 'center',
              }}
            >
              ※&thinsp;承認されたチェックリストを未承認に戻すことはできません。
              <br />
              &emsp;&thinsp;ご注意ください。
            </span>
          </DialogContent>
        )}
        {!isExistUnCheck && (
          <DialogContent>
            注意：選択されたチェックリストに「未承認」が存在しないので、承認できません。
          </DialogContent>
        )}

        <Divider />

        {isExistUnCheck && (
          <DialogActionsCreate
            buttonLabel='チェックリストを承認する'
            onClickActionHandler={handleApproveChecklist}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default ApproveDialog;
