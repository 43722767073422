import React, { useEffect, useState } from 'react';
import { Box, FormControl, IconButton, InputBase } from '@material-ui/core';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import _ from 'lodash';

import DataTable from 'views/components/tables/NewsInformationList/DataTable';
import SearchIcon from '@material-ui/icons/Search';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

const NewsInformationList: React.FC = () => {
  const [datas, setDatas] = useState([]);
  const [datasWk, setDatasWk] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [value, setValue] = useState(1);
  const [word, setWord] = useState('');
  const [searchWk, setSearchWk] = useState([]);
  const searchTab = [{ key: 0, val: '検索結果' }];
  const [nothing, setNothing] = useState(false);

  const setOffset = useSetAtom(offsetAtom);

  useEffect(() => {
    Promise.all([
      axios.get('/api/news_category/getlist'),
      axios.get('/api/news_information/getlists'),
    ]).then((res) => {
      const options = res[0].data.map((x) => {
        return {
          key: x.category_code,
          val: x.category_name,
        };
      });

      setCategorys([...options, ...searchTab]);
      setDatas(res[1].data);
      let wk = [];
      wk = res[1].data.filter((x) => {
        return x.category_code === value;
      });
      console.log('ファーストデータ', wk);
      setDatasWk(wk);
      if (wk.length === 0) {
        setNothing(true);
      }
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (word: string) => {
    setSearchWk([]);
    setValue(0);
    const search = word;
    const searchWk = datas.filter((x) => {
      return x.naiyou.toLowerCase().includes(search.toLowerCase());
    });
    const searchWk2 = datas.filter((x) => {
      return x.title.toLowerCase().includes(search.toLowerCase());
    });
    // 検索ワード空白時にエラーメッセージ呼ぶか
    if (search === '') {
      setDatasWk([]);
      setNothing(true);
    } else {
      const a = _.uniqBy([...searchWk, ...searchWk2], 'info_id');
      const result = a.sort((b, c) => {
        return b.updatedAt > c.updatedAt ? -1 : 1;
      });
      setDatasWk(result);
      setSearchWk(result);
      if (result.length > 0) {
        setNothing(false);
      } else {
        setNothing(true);
      }
    }
    setOffset(0);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            情報ボックス
          </Box>

          <Box
            style={{ display: 'flex' }}
            alignItems='center'
            justifyContent='center'
            mt={3}
            mb={3}
          >
            <FormControl
              style={{
                padding: '2px 4px',
                display: 'flex',
                // alignItems: 'center',
                width: 300,
                border: '1px solid #DCDCDC',
              }}
            >
              <InputBase
                type='text'
                // aria-label='検索'
                placeholder='キーワードを入力'
                onChange={(e) => {
                  setWord(e.target.value);
                }}
                style={{ flex: 1, marginLeft: 15 }}
                endAdornment={
                  <IconButton
                    type='submit'
                    onClick={() => handleSearch(word)}
                    style={{ padding: 10 }}
                  >
                    <SearchIcon />
                  </IconButton>
                }
              />
            </FormControl>
          </Box>

          <DataTable
            datas={datas}
            datasWk={datasWk}
            setDatasWk={setDatasWk}
            value={value}
            setValue={setValue}
            category={categorys}
            searchWk={searchWk}
            nothing={nothing}
            setNothing={setNothing}
          />
        </main>
      }
    />
  );
};

export default NewsInformationList;
