import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ListItemText } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        background: 'none',
      },
    },
  })
);

const InformationBox: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItemText style={{ borderRight: '1px solid gray' }}>
      <Button
        className={classes.menu}
        onClick={() => history.replace('/news-information-list')}
      >
        情報ボックス
      </Button>
    </ListItemText>
  );
};

export default InformationBox;
