import { useEffect, useState } from 'react';
import { selectOptionType } from 'types/common';
import { user } from 'types/user';

export const useDeptLv3 = (
  data: user[],
  selectedDeptLv: number
): [
  selectOptionType[],
  React.Dispatch<React.SetStateAction<selectOptionType>[]>
] => {
  const [deptLv3, setDeptLv3] = useState([]);

  useEffect(() => {
    let wk = null;

    switch (selectedDeptLv) {
      case 4: {
        const target = data
          .filter((x) => {
            return x.department_level_code === 3 && x.dept_code_lv3;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        wk = data.filter((x) => {
          return x.department_level_code === 3 && x.dept_code_lv2 === target;
        });

        break;
      }

      case 5: {
        const target = data
          .filter((x) => {
            return x.department_level_code === 4 && x.dept_code_lv4;
          })
          .map((x) => {
            return x.dept_code_lv2;
          })[0];

        wk = data.filter((x) => {
          return x.department_level_code === 4 && x.dept_code_lv2 === target;
        });

        break;
      }
    }

    setDeptLv3(
      wk
        ?.map((x) => {
          return {
            key: x.dept_code_lv3,
            val: x.dept_name_lv3,
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        )
    );
  }, [data, selectedDeptLv]);

  return [deptLv3, setDeptLv3];
};
