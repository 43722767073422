import React, { useState } from 'react';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InformationMasterForm } from 'views/components/forms/InformationMasterForm';
import axios from 'axios';
import { NewsInformationMasterContext } from 'views/pages/NewsInformationMaster';

import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  inputTextField: {
    marginTop: 12,
    // height: 30,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC<{ datas; category }> = (props) => {
  const classes = useStyles();

  const {
    defaultValues,
    setDatas,
    setDatasWk,
    category,
    setCategory,
    setValue,
    setCheckedInfo,
  } = React.useContext(NewsInformationMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const [errorCategoryCode, setErrorCategoryCode] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorNaiyou, setErrorNaiyou] = useState('');
  const [errorTmpName1, setErrorTmpName1] = useState('');
  const [errorTmpName2, setErrorTmpName2] = useState('');
  const [errorTmpName3, setErrorTmpName3] = useState('');

  const handleOpen = () => {
    setIsOpen(true);
    setErrorCategoryCode('');
    setErrorTitle('');
    setErrorNaiyou('');
    setErrorTmpName1('');
    setErrorTmpName2('');
    setErrorTmpName3('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCreate = () => {
    const formData = new FormData();

    formData.append(
      'category_code',
      document.querySelector<HTMLInputElement>('#cate-code').value
    );
    formData.append(
      'title',
      document.querySelector<HTMLInputElement>('#title').value
    );
    formData.append(
      'naiyou',
      document.querySelector<HTMLInputElement>('#naiyou').value
    );

    formData.append('files', defaultValues.fileRef1.current.files[0]);
    formData.append('files', defaultValues.fileRef2.current.files[0]);
    formData.append('files', defaultValues.fileRef3.current.files[0]);

    axios
      .post('/api/news_information/create/master', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setDatas(res.data);
        setCategory(category);
        const catenum = parseInt(
          document.querySelector<HTMLInputElement>('#cate-code').value
        );
        let wk = [];
        wk = res.data.filter((x) => {
          return x.category_code === catenum;
        });
        setDatasWk(wk);
        setValue(catenum);
        setCheckedInfo({ id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.category_code
          ? setErrorCategoryCode(errors.category_code.errorMsg)
          : setErrorCategoryCode('');
        errors.title ? setErrorTitle(errors.title.errorMsg) : setErrorTitle('');
        errors.naiyou
          ? setErrorNaiyou(errors.naiyou.errorMsg)
          : setErrorNaiyou('');
        errors.tmp_name1
          ? setErrorTmpName1(errors.tmp_name1.errorMsg)
          : setErrorTmpName1('');
        errors.tmp_name2
          ? setErrorTmpName2(errors.tmp_name2.errorMsg)
          : setErrorTmpName2('');
        errors.tmp_name3
          ? setErrorTmpName3(errors.tmp_name3.errorMsg)
          : setErrorTmpName3('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='情報登録'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='情報登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{
            marginTop: 12,
            marginBottom: 6,
            height: '100%',
          }}
        >
          <InformationMasterForm
            classes={classes}
            items={{
              categoryCode: {
                defaultValue: '',
                errMsg: errorCategoryCode,
                isDisabled: false,
              },
              title: {
                defaultValue: '',
                errMsg: errorTitle,
                isDisabled: false,
              },
              naiyou: {
                defaultValue: '',
                errMsg: errorNaiyou,
                isDisabled: false,
              },
              tmp_name1: {
                defaultValue: '',
                errMsg: errorTmpName1,
                isDisabled: false,
              },
              tmp_name2: {
                defaultValue: '',
                errMsg: errorTmpName2,
                isDisabled: false,
              },
              tmp_name3: {
                defaultValue: '',
                errMsg: errorTmpName3,
                isDisabled: false,
              },
            }}
            options={{
              category: props.category,
            }}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='情報を登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
