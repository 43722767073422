import React from 'react';
import { Button, DialogActions } from '@material-ui/core';

type Props = {
  buttonLabel: string;
  onClickActionHandler: () => void;
};

export const DialogActionsUpdate: React.FC<Props> = (props) => {
  const { buttonLabel, onClickActionHandler } = props;

  return (
    <DialogActions style={{ margin: 16, justifyContent: 'center' }}>
      <Button
        onClick={onClickActionHandler}
        variant='outlined'
        color='primary'
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        {buttonLabel}
      </Button>
    </DialogActions>
  );
};
