import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import InputPasswordField from 'views/molecules/InputPasswordField';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';
import axios from 'axios';

const useStyle = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const PasswordDialog: React.FC<{ user; setAnchorEl; setSnackOpen }> = (
  props
) => {
  const classes = useStyle();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [curPassword, setCurPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const [curErrMsg, setCurErrMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [confErrMsg, setConfErrMsg] = useState('');

  const email = props.user.email;
  const pref = props.user.pref_code;
  const bank = props.user.bank_code;

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    // errMsg = '';
    setDialogOpen(false);
    props.setAnchorEl(null);
  };

  const handleUpdate = () => {
    setCurErrMsg('');
    setErrMsg('');
    setConfErrMsg('');
    const curPassword =
      document.querySelector<HTMLInputElement>('#cur-password')?.value;
    const password =
      document.querySelector<HTMLInputElement>('#password')?.value;
    const confPassword =
      document.querySelector<HTMLInputElement>('#conf-password')?.value;

    axios
      .post(
        '/authPass',
        {
          loginId: email,
          password: curPassword,
        },
        {
          headers: {
            'content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        console.log('認証');
        axios
          .post('/api/password-change/update', {
            password,
            confPassword,
            email,
            pref,
            bank,
          })

          .then(() => {
            props.setAnchorEl(null);
            props.setSnackOpen(true);
          })
          .catch((err) => {
            setCurPassword('');
            setPassword('');
            setConfPassword('');
            const errors = err.response.data.errors;

            errors ? setErrMsg(errors.password?.errorMsg) : setErrMsg('');
            errors
              ? setConfErrMsg(errors.conf_password?.errorMsg)
              : setConfErrMsg('');
          });
      })
      .catch(() => {
        setCurPassword('');
        setPassword('');
        setConfPassword('');
        if (curPassword == '') {
          setCurErrMsg('※ パスワードを入力してください。');
        } else {
          setCurErrMsg('※ パスワードが正しくありません。');
        }
      });
  };

  return (
    <Box component='span'>
      <DialogButtonUpdate
        buttonLabel='パスワード変更'
        onClickActionHandler={handleOpen}
        disabled={false}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='パスワード変更'
          onClickActionHandler={handleClose}
        />
        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <InputPasswordField
            id='cur-password'
            label='現在のパスワード'
            className={classes.inputField}
            value={curPassword}
            errorMsg={curErrMsg}
            setPassword={setCurPassword}
            isEdit={true}
          />
          <InputPasswordField
            id='password'
            label='新しいパスワード'
            className={classes.inputField}
            value={password}
            errorMsg={errMsg}
            setPassword={setPassword}
            isEdit={true}
          />
          <InputPasswordField
            id='conf-password'
            label='確認用パスワード'
            className={classes.inputField}
            value={confPassword}
            errorMsg={confErrMsg}
            setPassword={setConfPassword}
            isEdit={true}
          />
        </DialogContent>
        <Divider />

        <DialogActionsUpdate
          buttonLabel='パスワードを変更する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default PasswordDialog;
