import React from 'react';
import { DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  titleName: string;
  onClickActionHandler: () => void;
};

export const DialogTitleDelete: React.FC<Props> = (props) => {
  const { titleName, onClickActionHandler } = props;

  return (
    <DialogTitle
      style={{
        textAlign: 'center',
        backgroundColor: '#e91e63',
        color: 'white',
        padding: 20,
      }}
    >
      <Typography variant='h5'>
        {/* titleName を中央寄せにするためのダミー */}
        <CloseIcon
          style={{
            visibility: 'hidden',
            float: 'left',
            marginTop: '4px',
          }}
        />
        {titleName}
        <CloseIcon
          onClick={onClickActionHandler}
          style={{
            cursor: 'pointer',
            float: 'right',
            marginTop: '4px',
          }}
        />
      </Typography>
    </DialogTitle>
  );
};
