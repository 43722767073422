import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { JaMasterContext } from 'views/pages/JaMaster';
import { getHIEById } from 'utils/functions';
import { JaMasterForm } from 'views/components/forms/JaMasterForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{ prefs; checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const { selectedFiscalYear, selectedStartMonth, setSelectedStartMonth } =
    React.useContext(JaMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const [errorPrefCode, setErrorPrefCode] = useState('');
  const [errorBankCode, setErrorBankCode] = useState('');
  const [errorJaName, setErrorJaName] = useState('');
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [errorAddress, setErrorAddress] = useState('');
  const [errorStartMonth, setErrorStartMonth] = useState('');
  const [errorContactPersonnelName, setErrorContactPersonnelName] =
    useState('');
  const [errorContactPersonnelDeptName, setErrorContactPersonnelDeptName] =
    useState('');
  const [errorContactPersonnelRole, setErrorContactPersonnelRole] =
    useState('');
  const [
    errorContactPersonnelPhoneNumber,
    setErrorContactPersonnelPhoneNumber,
  ] = useState('');
  const [errorContactPersonnelEmail, setErrorContactPersonnelEmail] =
    useState('');
  const [errorRemarks, setErrorRemarks] = useState('');

  const handleOpen = () => {
    setIsOpen(true);

    setErrorPrefCode('');
    setErrorBankCode('');
    setErrorJaName('');
    setErrorPostalCode('');
    setErrorAddress('');
    setErrorStartMonth('');
    setErrorContactPersonnelName('');
    setErrorContactPersonnelDeptName('');
    setErrorContactPersonnelRole('');
    setErrorContactPersonnelPhoneNumber('');
    setErrorContactPersonnelEmail('');
    setErrorRemarks('');

    setSelectedStartMonth(props.checkedInfo.start_month);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = () => {
    const pref_code = getHIEById('#pref-code').value;
    const bank_code = getHIEById('#bank-code').value;
    const ja_name = getHIEById('#ja-name').value;
    const ja_nickname = getHIEById('#ja-nickname').value;
    const address = getHIEById('#address').value;
    const contact_name = getHIEById('#contact-name').value;
    const contact_dept_name = getHIEById('#contact-dept-name').value;
    const contact_role = getHIEById('#contact-role').value;
    const contact_phone_number = getHIEById('#contact-phone-number').value;
    const contact_email = getHIEById('#contact-email').value;
    const remarks = getHIEById('#remarks').value;
    const is_public = getHIEById('#is-public').checked;

    // const searchYear = getHIEById('#searchYear').value;
    // const searchPref = getHIEById('#searchPref').value;

    axios
      .post('/api/ja-master/update', {
        fiscal_year: selectedFiscalYear,
        pref_code,
        bank_code,
        ja_name,
        ja_nickname,
        address,
        contact_name,
        contact_dept_name,
        contact_role,
        contact_phone_number,
        contact_email,
        remarks,
        is_public,
        start_month: selectedStartMonth,
      })
      .then(() => {
        window.location.reload();

        // setDatas(res.data);
        // if (searchPref === '00') {
        //   setDatasWk(
        //     res.data.filter((x) => {
        //       return x.fiscal_year == searchYear;
        //     })
        //   );
        // } else {
        //   setDatasWk(
        //     res.data.filter((x) => {
        //       return x.fiscal_year == searchYear && x.pref_code == searchPref;
        //     })
        //   );
        // }
        // props.checkedInfo.ja_name = ja_name;
        // props.checkedInfo.ja_nickname = ja_nickname;
        // props.checkedInfo.address = address;
        // props.checkedInfo.contact_name = contact_name;
        // props.checkedInfo.contact_dept_name = contact_dept_name;
        // props.checkedInfo.contact_role = contact_role;
        // props.checkedInfo.contact_phone_number = contact_phone_number;
        // props.checkedInfo.contact_email = contact_email;
        // props.checkedInfo.remarks = remarks;
        // props.checkedInfo.is_public = is_public;
        // handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.pref_code
          ? setErrorPrefCode(errors.pref_code.errorMsg)
          : setErrorPrefCode('');
        errors.bank_code
          ? setErrorBankCode(errors.bank_code.errorMsg)
          : setErrorBankCode('');
        errors.ja_name
          ? setErrorJaName(errors.ja_name.errorMsg)
          : setErrorJaName('');
        errors.ja_nickname
          ? setErrorPostalCode(errors.ja_nickname.errorMsg)
          : setErrorPostalCode('');
        errors.address
          ? setErrorAddress(errors.address.errorMsg)
          : setErrorAddress('');
        errors.start_month
          ? setErrorStartMonth(errors.start_month.errorMsg)
          : setErrorStartMonth('');
        errors.contact_name
          ? setErrorContactPersonnelName(errors.contact_name.errorMsg)
          : setErrorContactPersonnelName('');
        errors.contact_dept_name
          ? setErrorContactPersonnelDeptName(errors.contact_dept_name.errorMsg)
          : setErrorContactPersonnelDeptName('');
        errors.contact_role
          ? setErrorContactPersonnelRole(errors.contact_role.errorMsg)
          : setErrorContactPersonnelRole('');
        errors.contact_phone_number
          ? setErrorContactPersonnelPhoneNumber(
              errors.contact_phone_number.errorMsg
            )
          : setErrorContactPersonnelPhoneNumber('');
        errors.contact_email
          ? setErrorContactPersonnelEmail(errors.contact_email.errorMsg)
          : setErrorContactPersonnelEmail('');
        errors.remarks
          ? setErrorRemarks(errors.remarks.errorMsg)
          : setErrorRemarks('');

        if (errors.pref_code && !errors.contact_name) {
          setErrorPrefCode('　');
        } else if (!errors.pref_code && errors.contact_name) {
          setErrorPrefCode('　');
        }

        if (errors.bank_code && !errors.contact_dept_name) {
          setErrorContactPersonnelDeptName('　');
        } else if (!errors.bank_code && errors.contact_dept_name) {
          setErrorBankCode('　');
        }

        if (errors.ja_name && !errors.contact_role) {
          setErrorContactPersonnelRole('　');
        } else if (!errors.ja_name && errors.contact_role) {
          setErrorJaName('　');
        }

        if (errors.ja_nickname && !errors.contact_phone_number) {
          setErrorContactPersonnelPhoneNumber('　');
        } else if (!errors.ja_nickname && errors.contact_phone_number) {
          setErrorPostalCode('　');
        }

        if (errors.start_month && !errors.contact_email) {
          setErrorRemarks('　');
        } else if (!errors.start_month && errors.contact_email) {
          setErrorStartMonth('　');
        }

        if (errors.address && !errors.remarks) {
          setErrorContactPersonnelEmail('　');
        } else if (!errors.address && errors.remarks) {
          setErrorAddress('　');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='JA&thinsp;更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='JA&thinsp;更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <JaMasterForm
            classes={classes}
            items={{
              prefCode: {
                defaultValue: props.checkedInfo.pref_code,
                errMsg: errorPrefCode,
                isDisabled: true,
              },
              bankCode: {
                defaultValue: props.checkedInfo.bank_code,
                errMsg: errorBankCode,
                isDisabled: true,
              },
              jaName: {
                defaultValue: props.checkedInfo.ja_name,
                errMsg: errorJaName,
                isDisabled: false,
              },
              jaNickname: {
                defaultValue: props.checkedInfo.ja_nickname,
                errMsg: errorPostalCode,
                isDisabled: false,
              },
              fiscalYear: {
                defaultValue: selectedFiscalYear,
                errMsg: errorStartMonth,
                isDisabled: true,
              },
              startMonth: {
                defaultValue: selectedStartMonth,
                errMsg: errorStartMonth,
                isDisabled: true,
              },
              address: {
                defaultValue: props.checkedInfo.address,
                errMsg: errorAddress,
                isDisabled: false,
              },
              contactPersonnelName: {
                defaultValue: props.checkedInfo.contact_name,
                errMsg: errorContactPersonnelName,
                isDisabled: false,
              },
              contactPersonnelDeptName: {
                defaultValue: props.checkedInfo.contact_dept_name,
                errMsg: errorContactPersonnelDeptName,
                isDisabled: false,
              },
              contactPersonnelRole: {
                defaultValue: props.checkedInfo.contact_role,
                errMsg: errorContactPersonnelRole,
                isDisabled: false,
              },
              contactPersonnelPhoneNumber: {
                defaultValue: props.checkedInfo.contact_phone_number,
                errMsg: errorContactPersonnelPhoneNumber,
                isDisabled: false,
              },
              contactPersonnelEmail: {
                defaultValue: props.checkedInfo.contact_email,
                errMsg: errorContactPersonnelEmail,
                isDisabled: false,
              },
              remarks: {
                defaultValue: props.checkedInfo.remarks,
                errMsg: errorRemarks,
                isDisabled: false,
              },
              is_public: {
                defaultValue: props.checkedInfo.is_public,
                isDisabled: false,
              },
            }}
            options={{
              prefs: props.prefs,
              startMonths: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3].map((x) => {
                return { key: x, val: x + '月' };
              }),
            }}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='JA&thinsp;を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
