import React, { createContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import UpdateDialog from 'views/components/dialogs/ManualMaster/UpdateDialog';
import DataTable from 'views/components/tables/ManualMaster/DataTable';
import CreateDialog from 'views/components/dialogs/ManualMaster/CreateDialog';
import DeleteDialog from 'views/components/dialogs/ManualMaster/DeleteDialog';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const ManualMasterContext = createContext(
  {} as {
    owner_user_type;
    pref_code;
    bank_code: string;
    fileRef: React.RefObject<HTMLInputElement>;
    datas;
    orgDatas;
    checkedInfo;
    setDatas;
    setOrgDatas;
    setCheckedInfo;
    categoryOpt;
    selectedCategory;
    setSelectedCategory;
    errorFile;
    setErrorFile;
    selectedFileName;
    setSelectedFileName;
  }
);

export const ManualMaster: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const bankCode = user.bank_code;
  const userType = user.user_type;
  const prefCode = user.pref_code;

  const fileRef = React.useRef<HTMLInputElement>(null);

  const [datas, setDatas] = useState([]);
  const [orgDatas, setOrgDatas] = useState([]);

  const [categoryOpt, setCategoryOpt] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const [checkedInfo, setCheckedInfo] = useState({ manual_id: -1 });
  const setOffset = useSetAtom(offsetAtom);

  const [errorFile, setErrorFile] = useState('');

  const [selectedFileName, setSelectedFileName] = useState('');

  useEffect(() => {
    Promise.all([
      axios.get('/api/manual_category'),
      axios.get('/api/manual'),
    ]).then((res) => {
      const opt = res[0].data.map((x) => {
        return {
          key: x.category_id,
          val: x.category_name,
        };
      });
      setCategoryOpt(opt);

      const categMap = new Map();

      res[1].data.map((x) => {
        categMap.set(x.category_id, {
          key: x.category_id,
          val: x.category_name,
        });
      });

      const initialCategoryId = Array.from(categMap.values())[0]?.key;

      setSelectedCategory(initialCategoryId);

      setDatas(
        res[1].data.filter((x) => {
          return x.category_id === initialCategoryId;
        })
      );
      setOrgDatas(res[1].data);

      setCheckedInfo({ manual_id: -1 });
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            マニュアル・資料登録
          </Box>

          <ManualMasterContext.Provider
            value={{
              owner_user_type: userType,
              pref_code: prefCode,
              bank_code: bankCode,
              fileRef: fileRef,
              datas: datas,
              orgDatas,
              checkedInfo,
              setDatas,
              setOrgDatas,
              setCheckedInfo,
              categoryOpt,
              selectedCategory,
              setSelectedCategory,
              errorFile,
              setErrorFile,
              selectedFileName,
              setSelectedFileName,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />

              <UpdateDialog isDisabled={checkedInfo.manual_id === -1} />

              <DeleteDialog isDisabled={checkedInfo.manual_id === -1} />
            </Box>

            <DataTable />
          </ManualMasterContext.Provider>
        </main>
      }
    />
  );
};
