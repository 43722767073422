import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { UserContext } from 'views/pages/User';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  checklist;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const { selectedDeptLv, is_dept_lv2_invalid } = React.useContext(UserContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ id: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    id: number,
    dept_code_lv1: number,
    dept_name_lv1: string,
    dept_code_lv2: number,
    dept_name_lv2: string,
    dept_code_lv3: number,
    dept_name_lv3: string,
    dept_code_lv4: number,
    dept_name_lv4: string,
    dept_code_lv5: number,
    dept_name_lv5: string,
    bank_code: number,
    user_name: string,
    user_dept_name: string,
    user_post: string,
    telephone_number: string,
    email: string,
    account_lock: boolean
  ) => {
    if (id === props.checkedInfo.id) {
      props.setCheckedInfo({
        id: -1,
      });
    } else {
      props.setCheckedInfo({
        id,
        dept_code_lv1,
        dept_name_lv1,
        dept_code_lv2,
        dept_name_lv2,
        dept_code_lv3,
        dept_name_lv3,
        dept_code_lv4,
        dept_name_lv4,
        dept_code_lv5,
        dept_name_lv5,
        bank_code,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        account_lock,
      });
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center'>選択</StyledTableCell>
              {selectedDeptLv === 1 && (
                <StyledTableCell>第Ⅰ階層</StyledTableCell>
              )}
              {selectedDeptLv >= 2 && !is_dept_lv2_invalid && (
                <StyledTableCell>第Ⅱ階層</StyledTableCell>
              )}
              {selectedDeptLv > 2 && <StyledTableCell>第Ⅲ階層</StyledTableCell>}
              {selectedDeptLv > 3 && <StyledTableCell>第Ⅳ階層</StyledTableCell>}
              {selectedDeptLv > 4 && <StyledTableCell>第Ⅴ階層</StyledTableCell>}
              <StyledTableCell>ユーザ名</StyledTableCell>
              <StyledTableCell>メールアドレス</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.checklist
              .filter((x) => {
                return x.department_level_code === selectedDeptLv;
              })
              .slice(offset, offset + parPage)
              .map((x) => (
                <StyledTableRow key={x.id}>
                  <StyledTableCell align='center'>
                    <input
                      type='checkbox'
                      checked={props.checkedInfo.id === x.id}
                      onChange={() => {
                        handleCheckbox(
                          x.id,
                          x.dept_code_lv1,
                          x.dept_name_lv1,
                          x.dept_code_lv2,
                          x.dept_name_lv2,
                          x.dept_code_lv3,
                          x.dept_name_lv3,
                          x.dept_code_lv4,
                          x.dept_name_lv4,
                          x.dept_code_lv5,
                          x.dept_name_lv5,
                          x.bank_code,
                          x.user_name,
                          x.user_dept_name,
                          x.user_post,
                          x.telephone_number,
                          x.email,
                          x.account_lock
                        );
                      }}
                    />
                  </StyledTableCell>
                  {selectedDeptLv === 1 && (
                    <StyledTableCell>{x.dept_name_lv1}</StyledTableCell>
                  )}
                  {selectedDeptLv >= 2 && !is_dept_lv2_invalid && (
                    <StyledTableCell>{x.dept_name_lv2}</StyledTableCell>
                  )}
                  {selectedDeptLv > 2 && (
                    <StyledTableCell>{x.dept_name_lv3}</StyledTableCell>
                  )}
                  {selectedDeptLv > 3 && (
                    <StyledTableCell>{x.dept_name_lv4}</StyledTableCell>
                  )}
                  {selectedDeptLv > 4 && (
                    <StyledTableCell>{x.dept_name_lv5}</StyledTableCell>
                  )}
                  <StyledTableCell>{x.user_name}</StyledTableCell>
                  <StyledTableCell>{x.email}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.checklist.filter((x) => {
        return x.department_level_code === selectedDeptLv;
      }).length > PAR_PAGE && (
        <JICSPagination
          length={
            props.checklist.filter((x) => {
              return x.department_level_code === selectedDeptLv;
            }).length
          }
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
