import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Divider, DialogContent } from '@material-ui/core';

import { ManualMasterContext } from 'views/pages/ManualMaster';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { PROC_TYPE } from 'utils/constants';
import { ManualMasterForm } from 'views/components/forms/ManualMasterForm';
import { offsetAtom } from 'jotai/atoms';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const CreateDialog: React.FC = () => {
  const classes = useStyles();

  const {
    fileRef,
    setDatas,
    setOrgDatas,
    setCheckedInfo,
    setSelectedCategory,
    setErrorFile,
  } = React.useContext(ManualMasterContext);

  const [isOpen, setIsOpen] = useState(false);

  const setOffset = useSetAtom(offsetAtom);

  const handleOpen = () => {
    setIsOpen(true);
    setErrorFile('');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCreate = () => {
    const formData = new FormData();

    const target = document.querySelector<HTMLInputElement>('#category').value;

    formData.append('category_id', target);
    formData.append('file', fileRef.current.files[0]);

    axios
      .post('/api/manual/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const categMap = new Map();

        res.data.map((x) => {
          categMap.set(x.category_id, {
            key: x.category_id,
            val: x.category_name,
          });
        });

        setDatas(
          res.data.filter((x) => {
            return x.category_id === parseInt(target);
          })
        );

        setOrgDatas(res.data);
        setSelectedCategory(parseInt(target));
        setOffset(0);

        setCheckedInfo({ manual_id: -1 });
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.file ? setErrorFile(errors.file.errorMsg) : setErrorFile('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='マニュアル・資料登録'
        onClickActionHandler={handleOpen}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitleCreate
          titleName='マニュアル・資料登録'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <ManualMasterForm
            isDisabled={{
              categorys: false,
            }}
            procType={PROC_TYPE.CREATE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='マニュアル・資料を登録する'
          onClickActionHandler={handleCreate}
        />
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
