import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Divider, FormControl, OutlinedInput, Select } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    minHeight: '50vh',
    maxHeight: '50vh',
    overflow: 'auto',
    border: '1px solid lightgray',
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  selectLeft: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  selectRight: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    pointerEvents: 'none',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const TransferList: React.FC<{
  selectedFiscalYear;
  pref_code;
  bank_code;
  lv;
  data;
  checkedInfo;
}> = (props) => {
  const classes = useStyles();

  const options = [
    { key: 1, val: '階層レベルⅠ' },
    { key: 2, val: '階層レベルⅡ' },
    { key: 3, val: '階層レベルⅢ' },
    { key: 4, val: '階層レベルⅣ' },
    { key: 5, val: '階層レベルⅤ' },
  ];

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [selectedDeptLv, setSelectedDeptLv] = React.useState(0);

  const [maxDeptLv, setMaxDeptLv] = React.useState(1);

  const [setting_dept_lv3, setSetting_dept_lv3] = React.useState('');
  const [setting_dept_lv4, setSetting_dept_lv4] = React.useState('');
  const [setting_dept_lv5, setSetting_dept_lv5] = React.useState('');

  React.useEffect(() => {
    const dept = [];

    setSelectedDeptLv(parseInt(props.lv));

    if (parseInt(props.lv) <= 3) {
      props.data
        .filter((x) => {
          return (
            x.fiscal_year === props.selectedFiscalYear &&
            x.department_level_code === 3
          );
        })
        .map((x) => {
          const key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
          const val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

          dept.push({ key, val });
        });
    } else if (parseInt(props.lv) === 4) {
      props.data
        .filter((x) => {
          return (
            x.fiscal_year === props.selectedFiscalYear &&
            x.department_level_code === 4
          );
        })
        .map((x) => {
          const key =
            x.dept_code_lv2 + '#' + x.dept_code_lv3 + '#' + x.dept_code_lv4;
          const val =
            x.dept_name_lv2 + '　' + x.dept_name_lv3 + '　' + x.dept_name_lv4;

          dept.push({ key, val });
        });
    } else if (parseInt(props.lv) === 5) {
      props.data
        .filter((x) => {
          return (
            x.fiscal_year === props.selectedFiscalYear &&
            x.department_level_code === 5
          );
        })
        .map((x) => {
          const key =
            x.dept_code_lv2 +
            '#' +
            x.dept_code_lv3 +
            '#' +
            x.dept_code_lv4 +
            '#' +
            x.dept_code_lv5;
          const val =
            x.dept_name_lv2 +
            '　' +
            x.dept_name_lv3 +
            '　' +
            x.dept_name_lv4 +
            '　' +
            x.dept_name_lv5;

          dept.push({ key, val });
        });
    }

    setLeft(dept);

    let deptLv = 1;

    props.data
      .filter((x) => {
        return x.fiscal_year === props.selectedFiscalYear;
      })
      .map((x) => {
        if (x.department_level_code >= deptLv) {
          deptLv = x.department_level_code;
        }
      });

    setMaxDeptLv(deptLv);

    axios
      .get('/api/inspection-department/setting-select', {
        params: {
          fiscal_year: props.selectedFiscalYear,
          pref_code: props.pref_code,
          bank_code: props.bank_code,
          department_level_code: props.lv,
          dept_code_lv1: props.checkedInfo.dept_code_lv1,
          dept_code_lv2: props.checkedInfo.dept_code_lv2,
          dept_code_lv3: props.checkedInfo.dept_code_lv3,
          dept_code_lv4: props.checkedInfo.dept_code_lv4,
          dept_code_lv5: props.checkedInfo.dept_code_lv5,
        },
      })
      .then((res) => {
        setSetting_dept_lv3(res.data.setting_dept_lv3);
        setSetting_dept_lv4(res.data.setting_dept_lv4);
        setSetting_dept_lv5(res.data.setting_dept_lv5);

        {
          const dept = [];

          if (props.lv <= 3) {
            if (res.data.setting_dept_lv3) {
              props.data
                .filter((x) => {
                  return x.fiscal_year === props.selectedFiscalYear;
                })
                .filter((x) => {
                  return res.data.setting_dept_lv3?.split(',').includes(
                    [
                      x.dept_code_lv2,
                      x.dept_code_lv3,
                      x.dept_code_lv4,
                      x.dept_code_lv5,
                    ]
                      .filter((x) => {
                        return x;
                      })
                      .join('#')
                  );
                })
                .map((x) => {
                  const key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
                  const val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

                  dept.push({ key, val });
                });
            }
          } else if (props.lv === 4) {
            if (res.data.setting_dept_lv4) {
              props.data
                .filter((x) => {
                  return x.fiscal_year === props.selectedFiscalYear;
                })
                .filter((x) => {
                  return res.data.setting_dept_lv4?.split(',').includes(
                    [
                      x.dept_code_lv2,
                      x.dept_code_lv3,
                      x.dept_code_lv4,
                      x.dept_code_lv5,
                    ]
                      .filter((x) => {
                        return x;
                      })
                      .join('#')
                  );
                })
                .map((x) => {
                  const key =
                    x.dept_code_lv2 +
                    '#' +
                    x.dept_code_lv3 +
                    '#' +
                    x.dept_code_lv4;
                  const val =
                    x.dept_name_lv2 +
                    '　' +
                    x.dept_name_lv3 +
                    '　' +
                    x.dept_name_lv4;

                  dept.push({ key, val });
                });
            }
          } else if (props.lv === 5) {
            if (res.data.setting_dept_lv5) {
              props.data
                .filter((x) => {
                  return x.fiscal_year === props.selectedFiscalYear;
                })
                .filter((x) => {
                  return res.data.setting_dept_lv5?.split(',').includes(
                    [
                      x.dept_code_lv2,
                      x.dept_code_lv3,
                      x.dept_code_lv4,
                      x.dept_code_lv5,
                    ]
                      .filter((x) => {
                        return x;
                      })
                      .join('#')
                  );
                })
                .map((x) => {
                  const key =
                    x.dept_code_lv2 +
                    '#' +
                    x.dept_code_lv3 +
                    '#' +
                    x.dept_code_lv4 +
                    '#' +
                    x.dept_code_lv5;
                  const val =
                    x.dept_name_lv2 +
                    '　' +
                    x.dept_name_lv3 +
                    '　' +
                    x.dept_name_lv4 +
                    '　' +
                    x.dept_name_lv5;

                  dept.push({ key, val });
                });
            }
          }

          setRight(dept);
        }
        {
          const dept = [];

          if (props.lv <= 3) {
            props.data
              .filter((x) => {
                return (
                  x.fiscal_year === props.selectedFiscalYear &&
                  x.department_level_code === 3
                );
              })
              .filter((x) => {
                return !res.data.setting_dept_lv3?.split(',').includes(
                  [
                    x.dept_code_lv2,
                    x.dept_code_lv3,
                    x.dept_code_lv4,
                    x.dept_code_lv5,
                  ]
                    .filter((x) => {
                      return x;
                    })
                    .join('#')
                );
              })
              .map((x) => {
                const key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
                const val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

                dept.push({ key, val });
              });
          } else if (parseInt(props.lv) === 4) {
            props.data
              .filter((x) => {
                return (
                  x.fiscal_year === props.selectedFiscalYear &&
                  x.department_level_code === 4
                );
              })
              .filter((x) => {
                return !res.data.setting_dept_lv4?.split(',').includes(
                  [
                    x.dept_code_lv2,
                    x.dept_code_lv3,
                    x.dept_code_lv4,
                    x.dept_code_lv5,
                  ]
                    .filter((x) => {
                      return x;
                    })
                    .join('#')
                );
              })
              .map((x) => {
                const key =
                  x.dept_code_lv2 +
                  '#' +
                  x.dept_code_lv3 +
                  '#' +
                  x.dept_code_lv4;

                const val =
                  x.dept_name_lv2 +
                  '　' +
                  x.dept_name_lv3 +
                  '　' +
                  x.dept_name_lv4;

                dept.push({ key, val });
              });
          } else if (parseInt(props.lv) === 5) {
            props.data
              .filter((x) => {
                return (
                  x.fiscal_year === props.selectedFiscalYear &&
                  x.department_level_code === 5
                );
              })
              .filter((x) => {
                return !res.data.setting_dept_lv5?.split(',').includes(
                  [
                    x.dept_code_lv2,
                    x.dept_code_lv3,
                    x.dept_code_lv4,
                    x.dept_code_lv5,
                  ]
                    .filter((x) => {
                      return x;
                    })
                    .join('#')
                );
              })
              .map((x) => {
                const key =
                  x.dept_code_lv2 +
                  '#' +
                  x.dept_code_lv3 +
                  '#' +
                  x.dept_code_lv4 +
                  '#' +
                  x.dept_code_lv5;

                const val =
                  x.dept_name_lv2 +
                  '　' +
                  x.dept_name_lv3 +
                  '　' +
                  x.dept_name_lv4 +
                  '　' +
                  x.dept_name_lv5;

                dept.push({ key, val });
              });
          }

          setLeft(dept);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    axios
      .post('/api/inspection-department/setting-create', {
        fiscal_year: props.selectedFiscalYear,
        pref_code: props.pref_code,
        bank_code: props.bank_code,
        department_level_code: props.lv,
        dept_code_lv1: props.checkedInfo.dept_code_lv1,
        dept_code_lv2: props.checkedInfo.dept_code_lv2,
        dept_code_lv3: props.checkedInfo.dept_code_lv3,
        dept_code_lv4: props.checkedInfo.dept_code_lv4,
        dept_code_lv5: props.checkedInfo.dept_code_lv5,
        selectedDeptLv,
        deptInfo: leftChecked,
      })
      .then((res) => {
        const dept = [];

        props.data
          .filter((x) => {
            return x.fiscal_year === props.selectedFiscalYear;
          })
          .filter((x) => {
            return res.data.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            let key;
            let val;

            if (selectedDeptLv <= 3) {
              key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
              val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

              setSetting_dept_lv3(res.data);
            } else if (selectedDeptLv === 4) {
              key =
                x.dept_code_lv2 + '#' + x.dept_code_lv3 + '#' + x.dept_code_lv4;
              val =
                x.dept_name_lv2 +
                '　' +
                x.dept_name_lv3 +
                '　' +
                x.dept_name_lv4;

              setSetting_dept_lv4(res.data);
            } else if (selectedDeptLv === 5) {
              key =
                x.dept_code_lv2 +
                '#' +
                x.dept_code_lv3 +
                '#' +
                x.dept_code_lv4 +
                '#' +
                x.dept_code_lv5;
              val =
                x.dept_name_lv2 +
                '　' +
                x.dept_name_lv3 +
                '　' +
                x.dept_name_lv4 +
                '　' +
                x.dept_name_lv5;

              setSetting_dept_lv5(res.data);
            }

            dept.push({ key, val });
          });

        setRight(dept);
      });

    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    axios
      .post('/api/inspection-department/setting-delete', {
        fiscal_year: props.selectedFiscalYear,
        pref_code: props.pref_code,
        bank_code: props.bank_code,
        department_level_code: props.lv,
        dept_code_lv1: props.checkedInfo.dept_code_lv1,
        dept_code_lv2: props.checkedInfo.dept_code_lv2,
        dept_code_lv3: props.checkedInfo.dept_code_lv3,
        dept_code_lv4: props.checkedInfo.dept_code_lv4,
        dept_code_lv5: props.checkedInfo.dept_code_lv5,
        selectedDeptLv,
        deptInfo: rightChecked,
      })
      .then((res) => {
        const dept = [];

        if (selectedDeptLv <= 3) {
          props.data
            .filter((x) => {
              return (
                x.fiscal_year === props.selectedFiscalYear &&
                x.department_level_code === 3
              );
            })
            .filter((x) => {
              return !res.data.split(',').includes(
                [
                  x.dept_code_lv2,
                  x.dept_code_lv3,
                  x.dept_code_lv4,
                  x.dept_code_lv5,
                ]
                  .filter((x) => {
                    return x;
                  })
                  .join('#')
              );
            })
            .map((x) => {
              const key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
              const val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

              dept.push({ key, val });
            });

          setSetting_dept_lv3(res.data);
        } else if (selectedDeptLv === 4) {
          props.data
            .filter((x) => {
              return (
                x.fiscal_year === props.selectedFiscalYear &&
                x.department_level_code === 4
              );
            })
            .filter((x) => {
              return !res.data.split(',').includes(
                [
                  x.dept_code_lv2,
                  x.dept_code_lv3,
                  x.dept_code_lv4,
                  x.dept_code_lv5,
                ]
                  .filter((x) => {
                    return x;
                  })
                  .join('#')
              );
            })
            .map((x) => {
              const key =
                x.dept_code_lv2 + '#' + x.dept_code_lv3 + '#' + x.dept_code_lv4;

              const val =
                x.dept_name_lv2 +
                '　' +
                x.dept_name_lv3 +
                '　' +
                x.dept_name_lv4;

              dept.push({ key, val });
            });

          setSetting_dept_lv4(res.data);
        } else if (selectedDeptLv === 5) {
          props.data
            .filter((x) => {
              return (
                x.fiscal_year === props.selectedFiscalYear &&
                x.department_level_code === 5
              );
            })
            .filter((x) => {
              return !res.data.split(',').includes(
                [
                  x.dept_code_lv2,
                  x.dept_code_lv3,
                  x.dept_code_lv4,
                  x.dept_code_lv5,
                ]
                  .filter((x) => {
                    return x;
                  })
                  .join('#')
              );
            })
            .map((x) => {
              const key =
                x.dept_code_lv2 +
                '#' +
                x.dept_code_lv3 +
                '#' +
                x.dept_code_lv4 +
                '#' +
                x.dept_code_lv5;
              const val =
                x.dept_name_lv2 +
                '　' +
                x.dept_name_lv3 +
                '　' +
                x.dept_name_lv4 +
                '　' +
                x.dept_name_lv5;

              dept.push({ key, val });
            });

          setSetting_dept_lv5(res.data);
        }

        setLeft(dept);
      });

    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleChangeDeptLv = (e) => {
    const deptLv = parseInt(e.target.value);

    setSelectedDeptLv(deptLv);

    {
      const dept = [];

      let key;
      let val;

      if (deptLv === 3) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 3
            );
          })
          .filter((x) => {
            return !setting_dept_lv3?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
            val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

            dept.push({ key, val });
          });
      } else if (deptLv === 4) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 4
            );
          })
          .filter((x) => {
            return !setting_dept_lv4?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key =
              x.dept_code_lv2 + '#' + x.dept_code_lv3 + '#' + x.dept_code_lv4;
            val =
              x.dept_name_lv2 + '　' + x.dept_name_lv3 + '　' + x.dept_name_lv4;

            dept.push({ key, val });
          });
      } else if (deptLv === 5) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 5
            );
          })
          .filter((x) => {
            return !setting_dept_lv5?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key =
              x.dept_code_lv2 +
              '#' +
              x.dept_code_lv3 +
              '#' +
              x.dept_code_lv4 +
              '#' +
              x.dept_code_lv5;

            val =
              x.dept_name_lv2 +
              '　' +
              x.dept_name_lv3 +
              '　' +
              x.dept_name_lv4 +
              '　' +
              x.dept_name_lv5;

            dept.push({ key, val });
          });
      }

      setLeft(dept);
    }
    {
      const dept = [];

      let key;
      let val;

      if (deptLv === 3) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 3
            );
          })
          .filter((x) => {
            return setting_dept_lv3?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key = x.dept_code_lv2 + '#' + x.dept_code_lv3;
            val = x.dept_name_lv2 + '　' + x.dept_name_lv3;

            dept.push({ key, val });
          });
      } else if (deptLv === 4) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 4
            );
          })
          .filter((x) => {
            return setting_dept_lv4?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key =
              x.dept_code_lv2 + '#' + x.dept_code_lv3 + '#' + x.dept_code_lv4;
            val =
              x.dept_name_lv2 + '　' + x.dept_name_lv3 + '　' + x.dept_name_lv4;

            dept.push({ key, val });
          });
      } else if (deptLv === 5) {
        props.data
          .filter((x) => {
            return (
              x.fiscal_year === props.selectedFiscalYear &&
              x.department_level_code === 5
            );
          })
          .filter((x) => {
            return setting_dept_lv5?.split(',').includes(
              [
                x.dept_code_lv2,
                x.dept_code_lv3,
                x.dept_code_lv4,
                x.dept_code_lv5,
              ]
                .filter((x) => {
                  return x;
                })
                .join('#')
            );
          })
          .map((x) => {
            key =
              x.dept_code_lv2 +
              '#' +
              x.dept_code_lv3 +
              '#' +
              x.dept_code_lv4 +
              '#' +
              x.dept_code_lv5;

            val =
              x.dept_name_lv2 +
              '　' +
              x.dept_name_lv3 +
              '　' +
              x.dept_name_lv4 +
              '　' +
              x.dept_name_lv5;

            dept.push({ key, val });
          });
      }

      setRight(dept);
    }
  };

  const customList = (items, proc) => (
    <Paper className={classes.paper}>
      <div style={{ textAlign: 'center' }}>
        <FormControl variant='outlined'>
          {proc === 'REG' ? (
            <Select
              native
              className={classes.selectLeft}
              input={<OutlinedInput margin='dense' />}
              value={selectedDeptLv}
              onChange={handleChangeDeptLv}
            >
              {options
                .filter((x) => {
                  if (props.lv <= 3) {
                    return x.key >= 3;
                  } else if (props.lv === 4) {
                    return x.key >= 4;
                  } else if (props.lv === 5) {
                    return x.key >= 5;
                  }
                })
                .filter((x) => {
                  return x.key <= maxDeptLv;
                })
                .map((x, i) => (
                  <option value={x.key} key={i}>
                    {x.val}
                  </option>
                ))}
            </Select>
          ) : (
            <Select
              native
              className={classes.selectRight}
              input={<OutlinedInput margin='dense' />}
              inputProps={{ IconComponent: () => null }}
            >
              <option>閲覧・検証可能部署</option>
            </Select>
          )}
        </FormControl>
      </div>

      <Divider />

      <List component='div' role='list'>
        {items.map((item) => {
          const labelId = `transfer-list-item-${item}-label`;

          return (
            <ListItem
              key={item}
              role='listitem'
              dense
              button
              style={{ margin: 0, padding: 0 }}
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  style={{
                    transform: 'scale(0.7)',
                    margin: 0,
                    paddingLeft: 8,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  color={proc === 'REG' ? 'primary' : 'secondary'}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${item.val}`}
                style={{ marginLeft: -16 }}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item xs={5}>
        {customList(left, 'REG')}
      </Grid>
      <Grid item style={{ padding: 16 }}>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            color='primary'
            size='medium'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            登録
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            size='medium'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            削除
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(right, 'DEL')}
      </Grid>
    </Grid>
  );
};
