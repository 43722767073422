import { useEffect, useState } from 'react';

import axios from 'axios';

export type jaMasterType = {
  fiscal_year: number;
  is_dept_lv2_invalid: boolean;
};

export const useJaMaster = (
  pref_code: string,
  bank_code: string
): jaMasterType => {
  const [jaMaster, setJaMaster] = useState<jaMasterType>();

  useEffect(() => {
    axios
      .get('/api/ja-master', {
        params: {
          pref_code,
          bank_code,
        },
      })
      .then((res) => {
        setJaMaster(res.data);
      });
  }, [pref_code, bank_code]);

  return jaMaster;
};
