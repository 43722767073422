import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { DataTable } from 'views/components/tables/ChecklistConfirm/DataTable';
import {
  CHECKLIST_KBN_OPT,
  CHECKLIST_NAME_OPT,
  NO_OPT,
  USER_TYPE,
} from 'utils/constants';

import { TplPage } from 'views/templates/TplPage';
import { SelectControl } from 'views/molecules/SelectControl';
import { offsetAtom } from 'jotai/atoms';

export const ChecklistConfirmContext = React.createContext(
  {} as {
    fiscal_year;
    yearOption;
    pref_code;
    bank_code;
    data;
    dept_code_lv2;
    deptLv2Option;
    dept_code_lv3;
    deptLv3Option;
    dept_code_lv4;
    deptLv4Option;
    dept_code_lv5;
    deptLv5Option;
    user_type;
    user;
    dispData;
    inspectionMonth;
    selectedInspectionMonth;
  }
);

let currYear = 0;
let currPref = '0';
let currBank = '0';
let currDeptLv2 = 0;
let currDeptLv3 = 0;
let currDeptLv4 = 0;
let currDeptLv5 = 0;

export const ChecklistConfirm: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const {
    user_type,
    pref_code,
    bank_code,
    department_level_code,
    dept_code_lv1,
    dept_code_lv2,
    dept_code_lv3,
    dept_code_lv4,
    dept_code_lv5,
  } = user;

  const noselectedOption = [{ key: 0, val: '選択なし' }];

  const [prefOption, setPrefOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const [yearOption, setYearOption] = useState([]);

  const [selectedPref, setSelectedPref] = useState<string>('');
  const [selectedBank, setSelectedBank] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<number>(0);

  const [inspectionMonth, setInspectionMonth] = React.useState([]);
  const [mapInspectionMonth, setMapInspectionMonth] = React.useState(new Map());
  const [selectedInspectionMonth, setSelectedInspectionMonth] =
    React.useState(1);

  const [categOption, setCategOption] = React.useState(CHECKLIST_KBN_OPT);
  const [selectedCateg, setSelectedCateg] = useState<number>(0);

  const [subCategOption, setSubCategOption] = React.useState(NO_OPT);
  const [selectedSubCateg, setSelectedSubCateg] = useState<number>(0);

  const [dispData, setDispData] = React.useState([]);
  const [orgData, setOrgData] = React.useState([]);

  const [deptLv2Option, setDeptLv2Option] = useState([]);
  const [deptLv3Option, setDeptLv3Option] = useState([]);
  const [deptLv4Option, setDeptLv4Option] = useState([]);
  const [deptLv5Option, setDeptLv5Option] = useState([]);

  const [selectedDeptLv2, setSelectedDeptLv2] = useState<string | number>('');
  const [selectedDeptLv3, setSelectedDeptLv3] = useState<string | number>('');
  const [selectedDeptLv4, setSelectedDeptLv4] = useState<string | number>('');
  const [selectedDeptLv5, setSelectedDeptLv5] = useState<string | number>('');

  const setOffset = useSetAtom(offsetAtom);

  const [skipDeptLv2, setSkipDeptLv2] = useState(false);

  const getPrefOpt = (targetData, targetYear) => {
    const prefMap = new Map();

    targetData
      .filter((x) => {
        return x.fiscal_year === targetYear;
      })
      .map((x) => {
        prefMap.set(x.pref_code, {
          key: x.pref_code,
          val: x.pref_name,
        });
      });

    currPref = Array.from(prefMap.keys())[0];

    setPrefOption(Array.from(prefMap.values()));
    setSelectedPref(currPref);

    setOffset(0);
  };

  const getBankOpt = (targetData, targetYear, targetPref) => {
    const bankMap = new Map();

    targetData
      .filter((x) => {
        return x.fiscal_year === targetYear && x.pref_code === targetPref;
      })
      .map((x) => {
        bankMap.set(x.bank_code, {
          key: x.bank_code,
          val: x.ja_nickname,
        });
      });

    currBank = Array.from(bankMap.keys())[0];

    setBankOption(Array.from(bankMap.values()));
    setSelectedBank(currBank);
  };

  const getSkipLv2 = (targetData, targetYear, targetPref, targetBank) => {
    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank
        );
      })
      .map((x) => {
        setSkipDeptLv2(x.is_dept_lv2_invalid);
      });
  };

  const getDeptLv2Opt = (targetData, targetYear, targetPref, targetBank) => {
    const deptLv2Map = new Map();

    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank
        );
      })
      .map((x) => {
        deptLv2Map.set(x.dept_code_lv2, {
          key: x.dept_code_lv2,
          val: x.dept_name_lv2,
        });
      });

    currDeptLv2 = Array.from(deptLv2Map.keys())[0];

    setDeptLv2Option(Array.from(deptLv2Map.values()));
    setSelectedDeptLv2(currDeptLv2);
  };

  const getDeptLv3Opt = (targetData, targetYear, targetPref, targetBank) => {
    const deptLv3Map = new Map();

    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank &&
          x.dept_code_lv2 === currDeptLv2
        );
      })
      .map((x) => {
        deptLv3Map.set(x.dept_code_lv3, {
          key: x.dept_code_lv3,
          val: x.dept_name_lv3,
        });
      });

    currDeptLv3 = Array.from(deptLv3Map.keys())[0];

    setDeptLv3Option(Array.from(deptLv3Map.values()));
    setSelectedDeptLv3(currDeptLv3);
  };

  const getDeptLv4Opt = (targetData, targetYear, targetPref, targetBank) => {
    const deptLv4Map = new Map();

    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          x.dept_code_lv4
        );
      })
      .map((x) => {
        deptLv4Map.set(x.dept_code_lv4, {
          key: x.dept_code_lv4,
          val: x.dept_name_lv4,
        });
      });

    if (department_level_code < 4) {
      if (deptLv4Map.size > 0) {
        setDeptLv4Option([
          ...noselectedOption,
          ...Array.from(deptLv4Map.values()),
        ]);
      } else {
        setDeptLv4Option([...NO_OPT, ...Array.from(deptLv4Map.values())]);
      }

      currDeptLv4 = 0;
      setSelectedDeptLv4(0);
    } else {
      setDeptLv4Option(Array.from(deptLv4Map.values()));
      currDeptLv4 = Array.from(deptLv4Map.keys())[0];
      setSelectedDeptLv4(Array.from(deptLv4Map.keys())[0]);
    }
  };

  const getDeptLv5Opt = (targetData, targetYear, targetPref, targetBank) => {
    const deptLv5Map = new Map();

    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          x.dept_code_lv4 === currDeptLv4 &&
          x.dept_code_lv5
        );
      })
      .map((x) => {
        deptLv5Map.set(x.dept_code_lv5, {
          key: x.dept_code_lv5,
          val: x.dept_name_lv5,
        });
      });

    if (department_level_code < 5) {
      if (deptLv5Map.size > 0) {
        setDeptLv5Option([
          ...noselectedOption,
          ...Array.from(deptLv5Map.values()),
        ]);
      } else {
        setDeptLv5Option([...NO_OPT, ...Array.from(deptLv5Map.values())]);
      }

      currDeptLv5 = 0;
      setSelectedDeptLv5(0);
    } else {
      setDeptLv5Option(Array.from(deptLv5Map.values()));
      currDeptLv5 = Array.from(deptLv5Map.keys())[0];
      setSelectedDeptLv5(Array.from(deptLv5Map.keys())[0]);
    }
  };

  const getCatgOpt = (
    targetData,
    targetYear,
    targetPref,
    targetBank,
    targetInspectionMonth
  ) => {
    const categMap = new Map();

    targetData
      .filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.pref_code === targetPref &&
          x.bank_code === targetBank &&
          x.inspection_month === targetInspectionMonth &&
          (skipDeptLv2 || x.dept_code_lv2 === currDeptLv2) &&
          x.dept_code_lv3 === currDeptLv3 &&
          (currDeptLv4 ? x.dept_code_lv4 === currDeptLv4 : !x.dept_code_lv4) &&
          (currDeptLv5 ? x.dept_code_lv5 === currDeptLv5 : !x.dept_code_lv5)
        );
      })
      .map((x) => {
        categMap.set(x.category_code, {
          key: x.category_code,
          val:
            x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
              ? x.category_name.substring(2)
              : x.category_name,
        });
      });

    setCategOption([...CHECKLIST_KBN_OPT, ...Array.from(categMap.values())]);
    setSelectedCateg(0);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/checklist-result'),
      axios.get('/api/fiscal-year-start-month'),
      axios.get('/api/inspection-departments', {
        params: {
          pref_code,
          bank_code,
        },
      }),
      axios.get('/api/ja-master/start-month', {
        params: {
          pref_code,
          bank_code,
        },
      }),
    ]).then((res) => {
      let tmp;

      const mapDept = new Map();

      res[2].data
        .filter((x) => {
          return (
            x.department_level_code === department_level_code &&
            x.dept_code_lv1 === dept_code_lv1 &&
            x.dept_code_lv2 === dept_code_lv2 &&
            x.dept_code_lv3 === dept_code_lv3 &&
            x.dept_code_lv4 === dept_code_lv4 &&
            x.dept_code_lv5 === dept_code_lv5
          );
        })
        .forEach((x) => {
          mapDept.set(x.fiscal_year, [
            x.setting_dept_lv3,
            x.setting_dept_lv4,
            x.setting_dept_lv5,
          ]);
        });

      if (
        user_type === USER_TYPE.SYSTEM_ADMIN ||
        user_type === USER_TYPE.ALL_PREF_ADMIN
      ) {
        tmp = res[0].data;
      } else if (user_type === USER_TYPE.PREF_ADMIN) {
        tmp = res[0].data.filter((x) => {
          return x.pref_code === pref_code && x.is_public;
        });
      } else if (user_type === USER_TYPE.JA_ADMIN) {
        tmp = res[0].data.filter((x) => {
          return x.pref_code === pref_code && x.bank_code === bank_code;
        });
      } else if (user_type === USER_TYPE.USER) {
        tmp = res[0].data
          .filter((x) => {
            return x.pref_code === pref_code && x.bank_code === bank_code;
          })
          .filter((x) => {
            let setting_dept_lv3 = '';
            let setting_dept_lv4 = '';
            let setting_dept_lv5 = '';

            if (mapDept.has(x.fiscal_year)) {
              setting_dept_lv3 = mapDept.get(x.fiscal_year)[0];
              setting_dept_lv4 = mapDept.get(x.fiscal_year)[1];
              setting_dept_lv5 = mapDept.get(x.fiscal_year)[2];
            }

            if (
              x.department_level_code === 3 &&
              setting_dept_lv3
                ?.split(',')
                .includes([x.dept_code_lv2, x.dept_code_lv3].join('#'))
            ) {
              return x;
            } else if (
              x.department_level_code === 4 &&
              setting_dept_lv4
                ?.split(',')
                .includes(
                  [x.dept_code_lv2, x.dept_code_lv3, x.dept_code_lv4].join('#')
                )
            ) {
              return x;
            } else if (
              x.department_level_code === 5 &&
              setting_dept_lv5
                ?.split(',')
                .includes(
                  [
                    x.dept_code_lv2,
                    x.dept_code_lv3,
                    x.dept_code_lv4,
                    x.dept_code_lv5,
                  ].join('#')
                )
            ) {
              return x;
            }
          });
      }

      const fySmMap = new Map();

      Object.entries(res[1].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const fiscalYearMap = new Map();

      tmp.map((x) => {
        const key = `${x.fiscal_year}:${bank_code}`;

        fiscalYearMap.set(x.fiscal_year, {
          key: x.fiscal_year,
          val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
        });
      });

      currYear = Array.from(fiscalYearMap.keys())[0];

      setYearOption(Array.from(fiscalYearMap.values()));
      setSelectedYear(currYear);

      getPrefOpt(tmp, currYear);
      getBankOpt(tmp, currYear, currPref);
      getSkipLv2(tmp, currYear, currPref, currBank);

      getDeptLv2Opt(tmp, currYear, currPref, currBank);
      getDeptLv3Opt(tmp, currYear, currPref, currBank);
      getDeptLv4Opt(tmp, currYear, currPref, currBank);
      getDeptLv5Opt(tmp, currYear, currPref, currBank);

      getCatgOpt(tmp, currYear, currPref, currBank, 1);

      setDispData(
        tmp.filter((x) => {
          return (
            x.fiscal_year === currYear &&
            x.pref_code === currPref &&
            x.bank_code === currBank &&
            x.inspection_month === 1 &&
            x.dept_code_lv2 === currDeptLv2 &&
            x.dept_code_lv3 === currDeptLv3 &&
            (department_level_code < 4 ? !x.dept_code_lv4 : x.dept_code_lv4) &&
            (department_level_code < 5 ? !x.dept_code_lv5 : x.dept_code_lv5)
          );
        })
      );

      setOrgData(tmp);

      const tmpMap = new Map();
      res[3].data.forEach((x) => {
        const tmp = [];

        for (let i = 1; i <= 12; i++) {
          const month = x.start_month + i - 1;

          tmp.push({
            key: i,
            val: month > 12 ? `${month - 12}月` : `${month}月`,
          });
        }

        tmpMap.set(`${x.fiscal_year}:${x.pref_code}:${x.bank_code}`, tmp);
      });

      setMapInspectionMonth(new Map(tmpMap));
      setInspectionMonth(tmpMap.get(`${currYear}:${currPref}:${currBank}`));

      setSelectedInspectionMonth(
        tmpMap.get(`${currYear}:${currPref}:${currBank}`)[0].val
      );
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * 「年度」選択onChange
   */
  const handleChangeFiscalYear = (e) => {
    const keyYear = parseInt(e.target.value);
    setSelectedYear(keyYear);

    getPrefOpt(orgData, keyYear);
    getBankOpt(orgData, keyYear, currPref);
    getSkipLv2(orgData, keyYear, currPref, currBank);

    getDeptLv2Opt(orgData, keyYear, currPref, currBank);
    getDeptLv3Opt(orgData, keyYear, currPref, currBank);
    getDeptLv4Opt(orgData, keyYear, currPref, currBank);
    getDeptLv5Opt(orgData, keyYear, currPref, currBank);

    getCatgOpt(orgData, keyYear, currPref, currBank, 1);

    let tmp;

    if (
      user_type === USER_TYPE.SYSTEM_ADMIN ||
      user_type === USER_TYPE.ALL_PREF_ADMIN
    ) {
      tmp = orgData;
    } else if (user_type === USER_TYPE.PREF_ADMIN) {
      tmp = orgData.filter((x) => {
        return x.pref_code === pref_code && x.is_public;
      });
    } else if (user_type === USER_TYPE.JA_ADMIN) {
      tmp = orgData.filter((x) => {
        return x.pref_code === pref_code && x.bank_code === bank_code;
      });
    } else if (user_type === USER_TYPE.USER) {
      if (department_level_code === 1 || department_level_code === 2) {
        tmp = orgData.filter((x) => {
          return x.pref_code === pref_code && x.bank_code === bank_code;
        });
      } else if (department_level_code === 3) {
        tmp = orgData.filter((x) => {
          return (
            x.pref_code === pref_code &&
            x.bank_code === bank_code &&
            x.dept_code_lv2 === dept_code_lv2 &&
            x.dept_code_lv3 === dept_code_lv3
          );
        });
      } else if (department_level_code === 4) {
        tmp = orgData.filter((x) => {
          return (
            x.pref_code === pref_code &&
            x.bank_code === bank_code &&
            x.dept_code_lv2 === dept_code_lv2 &&
            x.dept_code_lv3 === dept_code_lv3 &&
            x.dept_code_lv4 === dept_code_lv4
          );
        });
      } else if (department_level_code === 5) {
        tmp = orgData.filter((x) => {
          return (
            x.pref_code === pref_code &&
            x.bank_code === bank_code &&
            x.dept_code_lv2 === dept_code_lv2 &&
            x.dept_code_lv3 === dept_code_lv3 &&
            x.dept_code_lv4 === dept_code_lv4 &&
            x.dept_code_lv5 === dept_code_lv5
          );
        });
      }
    }

    setDispData(
      tmp.filter((x) => {
        return (
          x.fiscal_year === keyYear &&
          x.pref_code === currPref &&
          x.bank_code === currBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          (department_level_code < 4 ? !x.dept_code_lv4 : x.dept_code_lv4) &&
          (department_level_code < 5 ? !x.dept_code_lv5 : x.dept_code_lv5)
        );
      })
    );

    setSubCategOption(NO_OPT);

    const key = `${keyYear}:${currPref}:${currBank}`;

    setInspectionMonth(mapInspectionMonth.get(key));
    setSelectedInspectionMonth(mapInspectionMonth.get(key)[0].val);

    setOffset(0);
  };

  /*
   * 「都道府県」選択onChange
   */
  const handleChangePref = (e) => {
    const keyPref = e.target.value;
    setSelectedPref(keyPref);

    getBankOpt(orgData, selectedYear, keyPref);
    getSkipLv2(orgData, selectedYear, keyPref, currBank);

    getDeptLv2Opt(orgData, selectedYear, keyPref, currBank);
    getDeptLv3Opt(orgData, selectedYear, keyPref, currBank);
    getDeptLv4Opt(orgData, selectedYear, keyPref, currBank);
    getDeptLv5Opt(orgData, selectedYear, keyPref, currBank);

    getCatgOpt(orgData, selectedYear, keyPref, currBank, 1);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === keyPref &&
          x.bank_code === currBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          !x.dept_code_lv4 &&
          !x.dept_code_lv5
        );
      })
    );

    setSubCategOption(NO_OPT);

    const key = `${selectedYear}:${keyPref}:${currBank}`;

    setInspectionMonth(mapInspectionMonth.get(key));
    setSelectedInspectionMonth(mapInspectionMonth.get(key)[0].val);

    setOffset(0);
  };

  /*
   * 「JA」選択onChange
   */
  const handleChangeBank = (e) => {
    const keyBank = e.target.value;
    setSelectedBank(keyBank);

    getSkipLv2(orgData, selectedYear, selectedPref, keyBank);

    getDeptLv2Opt(orgData, selectedYear, selectedPref, keyBank);
    getDeptLv3Opt(orgData, selectedYear, selectedPref, keyBank);
    getDeptLv4Opt(orgData, selectedYear, selectedPref, keyBank);
    getDeptLv5Opt(orgData, selectedYear, selectedPref, keyBank);

    getCatgOpt(orgData, selectedYear, selectedPref, keyBank, 1);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === selectedPref &&
          x.bank_code === keyBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          !x.dept_code_lv4 &&
          !x.dept_code_lv5
        );
      })
    );

    setSubCategOption(NO_OPT);

    const key = `${selectedYear}:${selectedPref}:${keyBank}`;

    setInspectionMonth(mapInspectionMonth.get(key));
    setSelectedInspectionMonth(mapInspectionMonth.get(key)[0].val);

    setOffset(0);
  };

  /*
   * 階層レベルⅡの所属部署の制御メソッド
   */
  const handleChangeDeptLv2 = (e) => {
    currDeptLv2 = Number(e.target.value);
    setSelectedDeptLv2(currDeptLv2);

    getDeptLv3Opt(orgData, selectedYear, selectedPref, selectedBank);
    getDeptLv4Opt(orgData, selectedYear, selectedPref, selectedBank);
    getDeptLv5Opt(orgData, selectedYear, selectedPref, selectedBank);
    setSelectedDeptLv5(0);

    getCatgOpt(orgData, selectedYear, selectedPref, selectedBank, 1);

    setSelectedCateg(0);
    setSelectedSubCateg(0);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === selectedPref &&
          x.bank_code === selectedBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          !x.dept_code_lv4 &&
          !x.dept_code_lv5
        );
      })
    );

    setSubCategOption(NO_OPT);

    setSelectedInspectionMonth(1);

    setOffset(0);
  };

  /*
   * 階層レベルⅢの所属部署の制御メソッド
   */
  const handleChangeDeptLv3 = (e) => {
    currDeptLv3 = Number(e.target.value);
    setSelectedDeptLv3(currDeptLv3);

    getDeptLv4Opt(orgData, selectedYear, selectedPref, selectedBank);
    getDeptLv5Opt(orgData, selectedYear, selectedPref, selectedBank);
    setSelectedDeptLv5(0);

    getCatgOpt(orgData, selectedYear, selectedPref, selectedBank, 1);

    setSelectedCateg(0);
    setSelectedSubCateg(0);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === selectedPref &&
          x.bank_code === selectedBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === currDeptLv2 &&
          x.dept_code_lv3 === currDeptLv3 &&
          !x.dept_code_lv4 &&
          !x.dept_code_lv5
        );
      })
    );

    setSubCategOption(NO_OPT);

    setSelectedInspectionMonth(1);

    setOffset(0);
  };

  /*
   * 階層レベルⅣの所属部署の制御メソッド
   */
  const handleChangeDeptLv4 = (e) => {
    currDeptLv4 = Number(e.target.value);
    setSelectedDeptLv4(currDeptLv4);

    getDeptLv5Opt(orgData, selectedYear, selectedPref, selectedBank);

    getCatgOpt(orgData, selectedYear, selectedPref, selectedBank, 1);

    setSelectedCateg(0);
    setSelectedSubCateg(0);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === selectedPref &&
          x.bank_code === selectedBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === selectedDeptLv2 &&
          x.dept_code_lv3 === selectedDeptLv3 &&
          (currDeptLv4 ? x.dept_code_lv4 === currDeptLv4 : !x.dept_code_lv4) &&
          !x.dept_code_lv5
        );
      })
    );

    setSubCategOption(NO_OPT);

    setSelectedInspectionMonth(1);

    setOffset(0);
  };

  /*
   * 階層レベルⅤの所属部署の制御メソッド
   */
  const handleChangeDeptLv5 = (e) => {
    currDeptLv5 = Number(e.target.value);
    setSelectedDeptLv5(currDeptLv5);

    getCatgOpt(orgData, selectedYear, selectedPref, selectedBank, 1);

    setSelectedCateg(0);
    setSelectedSubCateg(0);

    setDispData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedYear &&
          x.pref_code === selectedPref &&
          x.bank_code === selectedBank &&
          x.inspection_month === 1 &&
          x.dept_code_lv2 === selectedDeptLv2 &&
          x.dept_code_lv3 === selectedDeptLv3 &&
          x.dept_code_lv4 === selectedDeptLv4 &&
          (currDeptLv5 ? x.dept_code_lv5 === currDeptLv5 : !x.dept_code_lv5)
        );
      })
    );

    setSubCategOption(NO_OPT);

    setSelectedInspectionMonth(1);

    setOffset(0);
  };

  /*
   * 「チェックリスト区分」選択onChange
   */
  const handleChangeCateg = (e) => {
    const keyCateg = Number(e.target.value);
    setSelectedCateg(keyCateg);

    const tmp = orgData.filter((x) => {
      return (
        x.fiscal_year === selectedYear &&
        x.pref_code === selectedPref &&
        x.bank_code === selectedBank &&
        x.inspection_month ===
          (Number(selectedInspectionMonth)
            ? Number(selectedInspectionMonth)
            : 1) &&
        x.dept_code_lv2 === selectedDeptLv2 &&
        x.dept_code_lv3 === selectedDeptLv3 &&
        (selectedDeptLv4
          ? x.dept_code_lv4 === selectedDeptLv4
          : !x.dept_code_lv4) &&
        (selectedDeptLv5
          ? x.dept_code_lv5 === selectedDeptLv5
          : !x.dept_code_lv5) &&
        (keyCateg ? x.category_code === keyCateg : x.category_code)
      );
    });

    setDispData(tmp);

    const categMap = new Map();

    tmp.map((x) => {
      categMap.set(x.subcategory_code, {
        key: x.subcategory_code,
        val: x.subcategory_name.substring(2),
      });
    });

    if (keyCateg === 0) {
      setSubCategOption(NO_OPT);
    } else {
      setSubCategOption([
        ...CHECKLIST_NAME_OPT,
        ...Array.from(categMap.values()),
      ]);
    }

    setSelectedSubCateg(0);

    setOffset(0);
  };

  /*
   * 「チェックリスト」選択onChange
   */
  const handleChangeSubCateg = (e) => {
    const keySubCateg = Number(e.target.value);
    setSelectedSubCateg(keySubCateg);

    const tmp = orgData.filter((x) => {
      return (
        x.fiscal_year === selectedYear &&
        x.pref_code === selectedPref &&
        x.bank_code === selectedBank &&
        x.inspection_month ===
          (Number(selectedInspectionMonth)
            ? Number(selectedInspectionMonth)
            : 1) &&
        x.dept_code_lv2 === selectedDeptLv2 &&
        x.dept_code_lv3 === selectedDeptLv3 &&
        (selectedDeptLv4
          ? x.dept_code_lv4 === selectedDeptLv4
          : !x.dept_code_lv4) &&
        (selectedDeptLv5
          ? x.dept_code_lv5 === selectedDeptLv5
          : !x.dept_code_lv5) &&
        (selectedCateg ? x.category_code === selectedCateg : x.category_code) &&
        (keySubCateg ? x.subcategory_code === keySubCateg : x.subcategory_code)
      );
    });

    setDispData(tmp);

    setOffset(0);
  };

  /*
   * 「検査対象月」選択onChange
   */
  const handleChangeInspectionMonth = (e) => {
    setSelectedInspectionMonth(e.target.value);

    getCatgOpt(
      orgData,
      selectedYear,
      selectedPref,
      selectedBank,
      parseInt(e.target.value)
    );

    setSelectedCateg(0);
    setSelectedSubCateg(0);

    const tmp = orgData.filter((x) => {
      return (
        x.fiscal_year === selectedYear &&
        x.pref_code === selectedPref &&
        x.bank_code === selectedBank &&
        x.inspection_month === parseInt(e.target.value) &&
        x.dept_code_lv2 === selectedDeptLv2 &&
        x.dept_code_lv3 === selectedDeptLv3 &&
        (selectedDeptLv4
          ? x.dept_code_lv4 === selectedDeptLv4
          : !x.dept_code_lv4) &&
        (selectedDeptLv5
          ? x.dept_code_lv5 === selectedDeptLv5
          : !x.dept_code_lv5)
      );
    });

    setSubCategOption(NO_OPT);

    setDispData(tmp);

    setOffset(0);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {'検査結果の確認'}
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <div style={{ marginBottom: 20 }}>
              <SelectControl
                label='検査年度を選択'
                style={
                  bank_code
                    ? { width: '320px', marginRight: 8 }
                    : { width: '250px', marginRight: 8 }
                }
                value={selectedYear}
                options={yearOption}
                onChange={handleChangeFiscalYear}
              />
              <SelectControl
                label='都道府県を選択'
                style={
                  bank_code
                    ? { width: '320px', marginRight: 8 }
                    : { width: '250px', marginRight: 8 }
                }
                value={selectedPref}
                options={prefOption}
                onChange={handleChangePref}
              />
              <SelectControl
                label='JAを選択'
                style={bank_code ? { width: '320px' } : { width: '250px' }}
                value={selectedBank}
                options={bankOption}
                onChange={handleChangeBank}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              {!skipDeptLv2 && (
                <SelectControl
                  label='階層レベルⅡの部署を選択'
                  style={{ width: '240px', marginRight: 8 }}
                  value={selectedDeptLv2}
                  options={deptLv2Option}
                  onChange={handleChangeDeptLv2}
                />
              )}
              <SelectControl
                label='階層レベルⅢの部署を選択'
                style={{ width: '240px', marginRight: 8 }}
                value={selectedDeptLv3}
                options={deptLv3Option}
                onChange={handleChangeDeptLv3}
              />
              <SelectControl
                label='階層レベルⅣの部署を選択'
                style={{ width: '240px', marginRight: 8 }}
                value={selectedDeptLv4}
                options={deptLv4Option}
                onChange={handleChangeDeptLv4}
              />
              <SelectControl
                label='階層レベルⅤの部署を選択'
                style={{ width: '240px' }}
                value={selectedDeptLv5}
                options={deptLv5Option}
                onChange={handleChangeDeptLv5}
              />
            </div>

            <div>
              <SelectControl
                label='検査対象月を選択'
                style={{ width: '240px', marginRight: 8 }}
                value={selectedInspectionMonth || 1}
                options={inspectionMonth}
                onChange={handleChangeInspectionMonth}
              />
              <SelectControl
                label='チェックリスト区分を選択'
                style={{ width: '240px', marginRight: 8 }}
                value={selectedCateg}
                options={categOption}
                onChange={handleChangeCateg}
              />
              <SelectControl
                label='チェックリストを選択'
                style={{ width: '240px', marginRight: 8 }}
                value={selectedSubCateg}
                options={subCategOption}
                onChange={handleChangeSubCateg}
              />
            </div>
          </div>

          <ChecklistConfirmContext.Provider
            value={{
              fiscal_year: selectedYear,
              yearOption: yearOption,
              pref_code: selectedPref,
              bank_code: selectedBank,
              data: dispData,
              dept_code_lv2: selectedDeptLv2,
              deptLv2Option: deptLv2Option,
              dept_code_lv3: selectedDeptLv3,
              deptLv3Option: deptLv3Option,
              dept_code_lv4: selectedDeptLv4,
              deptLv4Option: deptLv4Option,
              dept_code_lv5: selectedDeptLv5,
              deptLv5Option: deptLv5Option,
              user_type: user_type,
              user,
              dispData,
              inspectionMonth,
              selectedInspectionMonth,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={0}
            ></Box>

            <DataTable />
          </ChecklistConfirmContext.Provider>
        </main>
      }
    />
  );
};
