import React from 'react';
import { Box } from '@material-ui/core/';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import CreateDialog from 'views/components/dialogs/ChecklistTemp/CreateDialog';
import DeleteDialog from 'views/components/dialogs/ChecklistTemp/DeleteDialog';
import DataTable from 'views/components/tables/ChecklistTemp/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const ChecklistTempContext = React.createContext(
  {} as {
    pref_code: string;
    bank_code: string;
    email: string;
    checklistRef;
    setChecklist;
    defaultChecklist: string;
    setDefaultChecklist: React.Dispatch<React.SetStateAction<string>>;
    defaultComment: string;
    setDefaultComment: React.Dispatch<React.SetStateAction<string>>;
    errorChecklist: string;
    setErrorChecklist: React.Dispatch<React.SetStateAction<string>>;
    errorComment: string;
    setErrorComment: React.Dispatch<React.SetStateAction<string>>;
    disabledChecklist: boolean;
    setDisabledChecklist: React.Dispatch<React.SetStateAction<boolean>>;
    disabledComment: boolean;
    setDisabledComment: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

export const ChecklistTemp: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const { pref_code, bank_code, email } = user;

  const checklistRef = React.useRef<HTMLInputElement>(null);

  const [tempStoredChecklistLimit, setTempStoredChecklistLimit] =
    React.useState(-1);
  const [checklist, setChecklist] = React.useState([]);
  const [checkedInfo, setCheckedInfo] = React.useState({
    checklist_code: -1,
  });

  const [defaultChecklist, setDefaultChecklist] = React.useState('');
  const [defaultComment, setDefaultComment] = React.useState('');

  const [errorChecklist, setErrorChecklist] = React.useState();
  const [errorComment, setErrorComment] = React.useState('');

  const [disabledChecklist, setDisabledChecklist] = React.useState(false);
  const [disabledComment, setDisabledComment] = React.useState(false);

  const setOffset = useSetAtom(offsetAtom);

  React.useEffect(() => {
    Promise.all([
      axios.get('/api/system_setting'),
      axios.get('/api/checklist-temp', {
        params: {
          pref_code,
          bank_code,
          email,
        },
      }),
    ]).then((res) => {
      setTempStoredChecklistLimit(res[0].data.temp_stored_checklist_limit);
      setChecklist(res[1].data);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            チェックリスト一時保存
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <ChecklistTempContext.Provider
              value={{
                pref_code,
                bank_code,
                email,
                checklistRef,
                setChecklist,
                defaultChecklist,
                setDefaultChecklist,
                defaultComment,
                setDefaultComment,
                errorChecklist,
                setErrorChecklist,
                errorComment,
                setErrorComment,
                disabledChecklist,
                setDisabledChecklist,
                disabledComment,
                setDisabledComment,
              }}
            >
              <Box
                style={{ display: 'flex' }}
                alignItems='center'
                justifyContent='center'
                mt={0}
                mb={3}
              >
                <CreateDialog
                  setCheckedInfo={setCheckedInfo}
                  isDisabled={checklist.length === tempStoredChecklistLimit}
                />
                <DeleteDialog
                  checkedInfo={checkedInfo}
                  setCheckedInfo={setCheckedInfo}
                  isDisabled={checkedInfo.checklist_code === -1}
                />
              </Box>

              <DataTable
                checklist={checklist}
                checkedInfo={checkedInfo}
                setCheckedInfo={setCheckedInfo}
              />
            </ChecklistTempContext.Provider>
          </div>
        </main>
      }
    />
  );
};
