import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FOOTER_IMG from 'assets/images/footer.jpg';
import { COPYRIGHT } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: 'auto',
    paddingBottom: theme.spacing(3),
    backgroundColor: 'white',
    width: '100%',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div style={{ textAlign: 'center' }}>
        <img src={FOOTER_IMG} />
      </div>
      <Typography align='center' variant='body1'>
        {COPYRIGHT}
      </Typography>
    </footer>
  );
};

export default Footer;
