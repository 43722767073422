import React, { createContext, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { UpdateDialog } from 'views/components/dialogs/FiscalYearMaster/UpdateDialog';
import axios from 'axios';
import { USER_TYPE } from 'utils/constants';

import { TplPage } from 'views/templates/TplPage';

export const FiscalYearMasterContext = createContext(
  {} as {
    pref_code: string;
    bank_code: string;
    fiscal_year: number;
    user_type: number;
    publish_phase: number;
  }
);

const is_null = (s) => {
  return s == undefined || s == null || s == '';
};

export const FiscalYearMaster: React.FC = () => {
  const [fiscal_year, setFiscalYear] = useState<number>(0);
  const [publish_phase, setPublishPhase] = useState<number>(0);

  async function SetFiscalYear(
    owner_user_type: number,
    __bank_code: string,
    __pref_code: string
  ) {
    await axios
      .get('/api/fiscal-year', {
        params: {
          owner_user_type,
          bank_code: __bank_code,
          pref_code: __pref_code,
        },
      })
      .then((res) => {
        setFiscalYear(res.data.fiscal_year);
        setPublishPhase(res.data.publish_phase);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const { pref_code, bank_code, user_type } = user;

  useEffect(() => {
    let owner_user_type: number;

    if (is_null(pref_code) && is_null(bank_code)) {
      owner_user_type = USER_TYPE.SYSTEM_ADMIN;
    } else if (is_null(bank_code)) {
      owner_user_type = USER_TYPE.PREF_ADMIN;
    } else {
      owner_user_type = USER_TYPE.JA_ADMIN;
    }

    const __bank_code = is_null(bank_code) ? '' : bank_code;
    const __pref_code = is_null(pref_code) ? '' : pref_code;
    SetFiscalYear(owner_user_type, __bank_code, __pref_code);
  }, [bank_code, pref_code]);
  // add 2022/4/28

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            検査年度マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '20px',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 25,
                paddingRight: 25,
                border: '1px solid gray',
                display: 'inline-block',
              }}
            >
              {fiscal_year && (
                <Typography variant='h5'>現年度：{fiscal_year} 年度</Typography>
              )}
            </div>

            <FiscalYearMasterContext.Provider
              value={{
                pref_code,
                bank_code,
                fiscal_year,
                user_type,
                publish_phase,
              }}
            >
              <div style={{ marginTop: 20 }}>
                <UpdateDialog />
              </div>
            </FiscalYearMasterContext.Provider>
          </div>
        </main>
      }
    />
  );
};
