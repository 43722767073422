import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PrefCentralAdminContext } from 'views/pages/PrefCentralAdmin';
import SelectBoxField from 'views/molecules/SelectBoxField';
import { UserInfoSubForm } from './subforms/UserInfoSubForm';
import DummyTextField from 'views/molecules/DummyTextField';
import { PROC_TYPE } from 'utils/constants';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
});

type Props = {
  isDisabled: {
    prefCentral: boolean;
    userName: boolean;
    userDeptName: boolean;
    userPost: boolean;
    telephoneNumber: boolean;
    email: boolean;
    password: boolean;
  };
  defaultValues?: {
    userName?: string;
    userDeptName?: string;
    userPost?: string;
    telephoneNumber?: string;
    email?: string;
    account_lock?: boolean;
  };
  isCheckboxHidden?: boolean;
  procType: number;
  setReleaseFlg?;
};

export const PrefCentralAdminForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    prefCentral,
    checkboxToggles,
    errorPrefCode,
    errorDummySideDept,
    errorDummySidePost,
    errorDummySideTel,
    errorDummySideEmail,
    errorDummySidePass,
    setErrorDummySidePass,
  } = React.useContext(PrefCentralAdminContext);

  React.useEffect(() => {
    setErrorDummySidePass('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxToggles]);

  const handleClickCheckbox = (e) => {
    props.setReleaseFlg(e.target.checked);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectBoxField
            id='pref-code'
            label='都道府県中央会を選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorPrefCode}
            options={prefCentral}
            isDisabled={props.isDisabled.prefCentral}
          />
          {props.procType === PROC_TYPE.UPDATE &&
            props.defaultValues.account_lock && (
              <>
                <DummyTextField
                  errorMsg={errorDummySideDept}
                  className={classes.inputField}
                />
                <DummyTextField
                  errorMsg={errorDummySidePost}
                  className={classes.inputField}
                />
                <DummyTextField
                  errorMsg={errorDummySideTel}
                  className={classes.inputField}
                />
                <DummyTextField
                  errorMsg={errorDummySideEmail}
                  className={classes.inputField}
                />
                {checkboxToggles && (
                  <DummyTextField
                    errorMsg={errorDummySidePass}
                    className={classes.inputField}
                  />
                )}
                <FormControlLabel
                  id='release-lock'
                  control={<Checkbox color='primary' />}
                  label='アカウントロックを解除する'
                  onClick={handleClickCheckbox}
                />
              </>
            )}
        </Grid>
        <Grid item xs={6}>
          <UserInfoSubForm
            defaultValues={props.defaultValues}
            isDisabled={props.isDisabled}
            procType={props.procType}
            context={PrefCentralAdminContext}
          />
        </Grid>
      </Grid>
    </>
  );
};
