import React from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { ChecklistConfirmContext } from 'views/pages/ChecklistConfirm';
import { convertJST, downloadExcel } from 'utils/functions';
import { PAR_PAGE, USER_TYPE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

export const DataTable: React.FC = () => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    data,
    dept_code_lv2,
    dept_code_lv3,
    dept_code_lv4,
    dept_code_lv5,
    user_type,
    inspectionMonth,
    selectedInspectionMonth,
  } = React.useContext(ChecklistConfirmContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleDownload = async (
    owner_user_type: string,
    pref_code: string,
    bank_code: string,
    fiscal_year: string,
    inspection_month: number,
    inspectionMonthVal: string,
    category_code: number,
    category_name: string,
    subcategory_code: number,
    subcategory_name: string,
    department_level_code: string,
    dept_code_lv2: string,
    dept_code_lv3: string,
    dept_code_lv4: string,
    dept_code_lv5: string
  ) => {
    const res = await axios.post(
      '/api/checklist-confirm/downloadChecklist',
      {
        owner_user_type,
        pref_code,
        bank_code,
        fiscal_year,
        inspection_month,
        inspectionMonthVal,
        category_code,
        category_name:
          parseInt(owner_user_type) === USER_TYPE.SYSTEM_ADMIN
            ? category_name.substring(2)
            : category_name,
        subcategory_code,
        subcategory_name: subcategory_name.substring(2),
        department_level_code,
        dept_code_lv2,
        dept_code_lv3,
        dept_code_lv4,
        dept_code_lv5,
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell style={{ width: '15%', paddingLeft: 50 }}>
                対象月
              </StyledTableCell>
              <StyledTableCell style={{ width: '35%' }}>
                チェックリスト名
              </StyledTableCell>
              <StyledTableCell align='center'>登録日時</StyledTableCell>
              {user_type === USER_TYPE.USER ? (
                <StyledTableCell align='center'></StyledTableCell>
              ) : (
                ''
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.slice(offset, offset + parPage).map((x, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell style={{ paddingLeft: 50 }}>
                  {inspectionMonth[selectedInspectionMonth - 1]?.val ||
                    selectedInspectionMonth}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        x.owner_user_type,
                        pref_code,
                        bank_code,
                        x.fiscal_year,
                        x.inspection_month,
                        inspectionMonth[selectedInspectionMonth - 1]?.val ||
                          selectedInspectionMonth,
                        x.category_code,
                        x.category_name,
                        x.subcategory_code,
                        x.subcategory_name,
                        x.department_level_code,
                        dept_code_lv2,
                        dept_code_lv3,
                        dept_code_lv4,
                        dept_code_lv5
                      );
                    }}
                  >
                    {`${
                      x.owner_user_type === USER_TYPE.SYSTEM_ADMIN
                        ? x.category_name.substring(2)
                        : x.category_name
                    }_${x.subcategory_name.substring(2)}.xlsx`}
                  </span>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {x.updated_at ? convertJST(x.updated_at) : '-'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > PAR_PAGE && (
        <JICSPagination
          length={data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};
