import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';

import { useDeptLvOption } from 'hooks/useDeptLvOption';
import { useJaMaster } from 'hooks/useJaMaster';
import CreateDialog from 'views/components/dialogs/InspectionDepartment/CreateDialog';
import UpdateDialog from 'views/components/dialogs/InspectionDepartment/UpdateDialog';
import DeleteDialog from 'views/components/dialogs/InspectionDepartment/DeleteDialog';
import DataTable from 'views/components/tables/InspectionDepartment/DataTable';
import { SelectControl } from 'views/molecules/SelectControl';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';
import SettingDialog from 'views/components/dialogs/InspectionDepartment/SettingDialog';

export const InspectionDepartmentContext = createContext(
  {} as {
    pref_code: string;
    bank_code: string;
    datas: {
      data;
      orgData;
    };
    errorMsgs: {
      inspectionDepartmentName: string;
    };
    setErrorMsgs: {
      inspectionDepartmentName: React.Dispatch<React.SetStateAction<string>>;
    };
    setData;
    setOrgData;
    deptLvOptions;
    checkedInfo;
    setCheckedInfo;
    is_dept_lv2_invalid: boolean;
    createButtonDisabled: boolean;
    currFiscalYear;
    selectedFiscalYear;
    setSelectedFiscalYear;
    selectedDeptLv;
  }
);

const InspectionDepartment: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  const { pref_code, bank_code, owner_user_type } = user;

  const tmp = useDeptLvOption(pref_code, bank_code);
  const deptLvOptions = tmp.length === 0 ? [{ key: '0', val: '' }] : tmp;

  const ja = useJaMaster(pref_code, bank_code);
  const is_dept_lv2_invalid = ja?.is_dept_lv2_invalid || false;

  const [checkedInfo, setCheckedInfo] = useState({ id: -1, name: null });

  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const setOffset = useSetAtom(offsetAtom);

  const [errorInspectionDepartmentName, setErrorInspectionDepartmentName] =
    useState('');

  const [currFiscalYear, setCurrFiscalYear] = useState(0);
  const [fiscalYearOption, setFiscalYearOption] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);
  const [selectedDeptLv, setSelectedDeptLv] = useState(0);

  const handleChangeFiscalYear = (e) => {
    const targetYear = parseInt(e.target.value);
    setSelectedFiscalYear(targetYear);

    const deptLvMap = new Map();

    orgData
      .filter((x) => {
        return x.fiscal_year === targetYear;
      })
      .map((x) => {
        deptLvMap.set(x.department_level_code, {
          key: x.department_level_code,
          val: x.department_level_name,
        });
      });

    const targetDepvLv = Array.from(deptLvMap.keys()).sort()[0];

    setData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === targetYear &&
          x.department_level_code === parseInt(targetDepvLv)
        );
      })
    );

    setCheckedInfo({ id: -1, name: null });

    setSelectedDeptLv(targetDepvLv);

    setOffset(0);
  };

  const handleChangeDeptLv = (e) => {
    const target = parseInt(e.target.value);

    const thres = is_dept_lv2_invalid ? 4 : 3;

    if (target < thres) {
      setCreateButtonDisabled(false);
    } else {
      const count = orgData.filter((x) => {
        return x.department_level_code === target - 1;
      }).length;

      if (count) {
        setCreateButtonDisabled(false);
      } else {
        setCreateButtonDisabled(true);
      }
    }

    setData(
      orgData.filter((x) => {
        return (
          x.fiscal_year === selectedFiscalYear &&
          x.department_level_code === target
        );
      })
    );

    setCheckedInfo({ id: -1, name: null });

    setSelectedDeptLv(target);

    setOffset(0);
  };

  useEffect(() => {
    Promise.all([
      axios.get('/api/department-levels'),
      axios.get('/api/inspection-departments', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/fiscal-year-option-temp', {
        params: {
          pref_code,
          bank_code,
          owner_user_type,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),
    ]).then((res) => {
      const fySmMap = new Map();

      Object.entries(res[3].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const fiscalYearAry = res[2].data.map((x) => {
        const key = `${x.fiscal_year}:${bank_code}`;
        return {
          key: x.fiscal_year,
          val: `${x.fiscal_year} 年度${fySmMap.get(key) || ''}`,
        };
      });

      const curr_year = fiscalYearAry[0].key;

      setFiscalYearOption(fiscalYearAry);
      setSelectedFiscalYear(curr_year);

      setCurrFiscalYear(curr_year);

      const deptLvMap = new Map();

      res[1].data
        .filter((x) => {
          return x.fiscal_year === curr_year;
        })
        .map((x) => {
          deptLvMap.set(x.department_level_code, {
            key: x.department_level_code,
            val: x.department_level_name,
          });
        });

      const targetDepvLv = Array.from(deptLvMap.keys()).sort()[0];

      setData(
        res[1].data.filter((x) => {
          return (
            x.fiscal_year === curr_year &&
            x.department_level_code == targetDepvLv
          );
        })
      );

      setSelectedDeptLv(targetDepvLv);

      setOrgData(res[1].data);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            部署マスタ
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={{ width: '320px', marginRight: 8 }}
              value={selectedFiscalYear}
              options={fiscalYearOption}
              onChange={handleChangeFiscalYear}
            />
            <FormControl variant='outlined' style={{ width: '320px' }}>
              <InputLabel>階層レベルを選択</InputLabel>
              <Select
                id='dept-lv'
                native
                value={selectedDeptLv}
                onChange={handleChangeDeptLv}
                label='階層レベルを選択'
                style={{
                  width: '320px',
                }}
              >
                {deptLvOptions &&
                  deptLvOptions.map((elm) => (
                    <option key={elm.key} value={elm.key}>
                      {elm.val}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>

          <InspectionDepartmentContext.Provider
            value={{
              pref_code,
              bank_code,
              datas: {
                data,
                orgData,
              },
              errorMsgs: {
                inspectionDepartmentName: errorInspectionDepartmentName,
              },
              setErrorMsgs: {
                inspectionDepartmentName: setErrorInspectionDepartmentName,
              },
              setData,
              setOrgData,
              deptLvOptions,
              checkedInfo,
              setCheckedInfo,
              is_dept_lv2_invalid,
              createButtonDisabled,
              currFiscalYear,
              selectedFiscalYear,
              setSelectedFiscalYear,
              selectedDeptLv,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog />
              <UpdateDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
              <DeleteDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
              />
              <SettingDialog
                checkedInfo={checkedInfo}
                isDisabled={checkedInfo.id === -1}
                data={orgData}
              />
            </Box>

            <DataTable
              deptDatas={data}
              checkedInfo={checkedInfo}
              setCheckedInfo={setCheckedInfo}
            />
          </InspectionDepartmentContext.Provider>
        </main>
      }
    />
  );
};

export default InspectionDepartment;
