import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { JaAdminContext } from 'views/pages/JaAdmin';
import { JaAdminForm } from 'views/components/forms/JaAdminForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';
import { getHSEById } from 'utils/functions';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const { setDatas, setDatasWk, setErrorMsgs, eventHandlers } =
    useContext(JaAdminContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [release_flg, setReleaseFlg] = useState(false);

  const [orgEmail, setOrgEmail] = useState('');

  const handleOpen = () => {
    eventHandlers.setPrefCode([
      { key: props.checkedInfo.pref_code, val: props.checkedInfo.pref_name },
    ]);
    eventHandlers.setBankCode([
      { key: props.checkedInfo.bank_code, val: props.checkedInfo.bank_code },
    ]);
    eventHandlers.setJaName(props.checkedInfo.ja_name);
    eventHandlers.setPassword('');

    setOrgEmail(props.checkedInfo.email);

    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setErrorMsgs.clear();
    setIsDialogOpen(false);
  };

  const handleUpdate = () => {
    const searchPref = getHSEById('#searchPref').value;
    const pref_code =
      document.querySelector<HTMLInputElement>('#pref-code').value;
    const bank_code =
      document.querySelector<HTMLInputElement>('#bank-code').value;
    const ja_name = document.querySelector<HTMLInputElement>('#ja-name').value;
    const user_name =
      document.querySelector<HTMLInputElement>('#user-name').value;
    const user_dept_name =
      document.querySelector<HTMLInputElement>('#user-dept-name').value;
    const user_post =
      document.querySelector<HTMLInputElement>('#user-post').value;
    const telephone_number =
      document.querySelector<HTMLInputElement>('#telephone-number').value;
    const email = document.querySelector<HTMLInputElement>('#email').value;
    const password_reset =
      document.querySelector<HTMLInputElement>('#password-reset')?.value;
    const password =
      document.querySelector<HTMLInputElement>('#password')?.value;
    const user_type = USER_TYPE.JA_ADMIN;

    axios
      .post('/api/ja-admin-master/update', {
        pref_code,
        bank_code,
        ja_name,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        orgEmail,
        password_reset,
        password,
        user_type,
        release_flg,
      })
      .then((res) => {
        setDatas(res.data);

        if (searchPref === '00') {
          setDatasWk(res.data);
        } else {
          setDatasWk(
            res.data.filter((x) => {
              return x.pref_code == searchPref;
            })
          );
        }

        props.checkedInfo.user_name = user_name;
        props.checkedInfo.user_dept_name = user_dept_name;
        props.checkedInfo.user_post = user_post;
        props.checkedInfo.telephone_number = telephone_number;
        props.checkedInfo.email = email;

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.pref_code
          ? setErrorMsgs.prefCode(errors.pref_code.errorMsg)
          : setErrorMsgs.prefCode('');
        errors.bank_code
          ? setErrorMsgs.bankCode(errors.bank_code.errorMsg)
          : setErrorMsgs.bankCode('');
        errors.ja_name
          ? setErrorMsgs.jaName(errors.ja_name.errorMsg)
          : setErrorMsgs.jaName('');
        errors.user_name
          ? setErrorMsgs.userName(errors.user_name.errorMsg)
          : setErrorMsgs.userName('');
        errors.user_dept_name
          ? setErrorMsgs.userDeptName(errors.user_dept_name.errorMsg)
          : setErrorMsgs.userDeptName('');
        errors.user_post
          ? setErrorMsgs.userPost(errors.user_post.errorMsg)
          : setErrorMsgs.userPost('');
        errors.telephone_number
          ? setErrorMsgs.telephoneNumber(errors.telephone_number.errorMsg)
          : setErrorMsgs.telephoneNumber('');
        errors.email
          ? setErrorMsgs.email(errors.email.errorMsg)
          : setErrorMsgs.email('');
        errors.password
          ? setErrorMsgs.password(errors.password.errorMsg)
          : setErrorMsgs.password('');

        if (errors.pref_code && !errors.user_name) {
          setErrorMsgs.userName('　');
        } else if (!errors.pref_code && errors.user_name) {
          setErrorMsgs.prefCode('　');
        }
        if (errors.bank_code && !errors.user_dept_name) {
          setErrorMsgs.userDeptName('　');
        } else if (!errors.bank_code && errors.user_dept_name) {
          setErrorMsgs.bankCode('　');
        }
        if (errors.ja_name && !errors.user_post) {
          setErrorMsgs.userPost('　');
        } else if (!errors.ja_name && errors.user_post) {
          setErrorMsgs.jaName('　');
        }
        if (errors.telephone_number) {
          setErrorMsgs.dummySideTel('　');
        } else {
          setErrorMsgs.dummySideTel('');
        }
        if (errors.email) {
          setErrorMsgs.dummySideEmail('　');
        } else {
          setErrorMsgs.dummySideEmail('');
        }
        if (errors.password) {
          setErrorMsgs.dummySidePass('　');
        } else {
          setErrorMsgs.dummySidePass('');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='JA&thinsp;管理者更新'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='JA&thinsp;管理者更新'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <JaAdminForm
            isDisabled={{
              prefCode: true,
              bankCode: true,
              userName: false,
              userDeptName: false,
              userPost: false,
              telephoneNumber: false,
              email: false,
              password: false,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userDeptName: props.checkedInfo.user_dept_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
              account_lock: props.checkedInfo.account_lock,
            }}
            procType={PROC_TYPE.UPDATE}
            setReleaseFlg={setReleaseFlg}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='JA&thinsp;管理者を更新する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
