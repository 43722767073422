import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import axios from 'axios';
import { useSetAtom } from 'jotai';

import DataTable from 'views/components/tables/ManualDownload/DataTable';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const ManualDownloadContext = React.createContext(
  {} as {
    data;
    setData;
    orgData;
    categoryOpt;
    selectedCategory;
    setSelectedCategory;
  }
);

export const ManualDownload: React.FC = () => {
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [categoryOpt, setCategoryOpt] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const setOffset = useSetAtom(offsetAtom);

  useEffect(() => {
    Promise.all([
      axios.get('/api/manual_category'),
      axios.get('/api/manual'),
    ]).then((res) => {
      const opt = res[0].data.map((x) => {
        return {
          key: x.category_id,
          val: x.category_name,
        };
      });
      setCategoryOpt(opt);

      const categMap = new Map();

      res[1].data.map((x) => {
        categMap.set(x.category_id, {
          key: x.category_id,
          val: x.category_name,
        });
      });

      const initialCategoryId = Array.from(categMap.values())[0].key;

      setSelectedCategory(initialCategoryId);

      setData(
        res[1].data.filter((x) => {
          return x.category_id === initialCategoryId;
        })
      );
      setOrgData(res[1].data);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            マニュアル・資料
          </Box>

          <ManualDownloadContext.Provider
            value={{
              data,
              setData,
              categoryOpt,
              selectedCategory,
              setSelectedCategory,
              orgData,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={0}
              mb={3}
            ></Box>

            <DataTable />
          </ManualDownloadContext.Provider>
        </main>
      }
    />
  );
};
