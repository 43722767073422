import React from 'react';
import { Button, DialogActions } from '@material-ui/core';

type Props = {
  buttonLabel: string;
  onClickActionHandler: () => void;
  disabled?: boolean;
};

export const DialogActionsCreate: React.FC<Props> = (props) => {
  const { buttonLabel, onClickActionHandler, disabled = false } = props;

  const style = disabled
    ? {
        color: '#BDBDBD',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: '1px solid #BDBDBD',
      }
    : {
        color: '#006633',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: '1px solid #006633',
      };

  return (
    <DialogActions style={{ margin: 16, justifyContent: 'center' }}>
      <Button
        onClick={onClickActionHandler}
        variant='outlined'
        style={style}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>
    </DialogActions>
  );
};
