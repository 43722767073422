import React from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  Tab,
  Tabs,
  Box,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { convertJST } from 'utils/functions';
import ViewDialog from 'views/components/dialogs/InformationBox/ViewDialog';

import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  datas;
  datasWk;
  setDatasWk;
  value;
  setValue;
  category;
  searchWk;
  nothing;
  setNothing;
}> = (props) => {
  const classes = useStyles();

  const [offset, setOffset] = useAtom(offsetAtom);

  const parPage = PAR_PAGE;

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleChange = (e, cate) => {
    let wk = [];

    if (cate == 0) {
      props.setDatasWk(props.searchWk);
    } else {
      wk = props.datas.filter((x) => {
        return x.category_code === cate;
      });
      props.setDatasWk(wk);
    }
    props.setValue(cate);
    if (wk.length > 0) {
      props.setNothing(false);
    } else if (props.searchWk.length > 0) {
      props.setNothing(false);
    } else {
      props.setNothing(true);
    }
    setOffset(0);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Tabs
          value={props.value}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgba(0,0,0,0)',
              height: '10px',
            },
          }}
          TabScrollButtonProps={{
            style: {
              color: 'black',
            },
          }}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
          style={{
            color: 'white',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {props.category.map((label) => (
            <Tab value={label.key} key={label.key} label={label.val} />
          ))}
        </Tabs>
        <Table
          className={classes.table}
          aria-label='customized table'
          // size='small'
        >
          <TableBody>
            {props.datasWk.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.info_id}>
                <StyledTableCell style={{ width: '20%', textAlign: 'center' }}>
                  {convertJST(elm.updatedAt).substring(0, 10)}
                </StyledTableCell>
                {props.value === 0 ? (
                  <StyledTableCell style={{ width: '20%' }}>
                    {elm.news_category.category_name}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    style={{ display: 'none' }}
                  ></StyledTableCell>
                )}
                <StyledTableCell style={{ width: '60%' }}>
                  <span className={classes.downloadable}>
                    <ViewDialog
                      info_id={elm.info_id}
                      category_code={elm.category_code}
                      title={elm.title}
                      tmp_name1={elm.tmp_name1}
                      tmp_name2={elm.tmp_name2}
                      tmp_name3={elm.tmp_name3}
                      naiyou={elm.naiyou}
                      category={props.category}
                    />
                  </span>
                </StyledTableCell>
                {/* <StyledTableCell>
                <span
                  className={classes.downloadable}
                  onClick={() => {
                    handleDownload(elm.info_id);
                  }}
                >
                  {elm.tmp_name}
                </span>
              </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.nothing && (
        <Box
          style={{
            paddingTop: '40px',
            textAlign: 'center',
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          キーワードと一致する情報はありません
        </Box>
      )}

      {props.datasWk.length > PAR_PAGE && (
        <JICSPagination
          length={props.datasWk.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
